import { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { DataTable, DataTableData, DataTableHeader } from './data-table';
import { Icons } from '@density/dust';
import { Meters } from 'lib/units';

const spaceDataHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'id', label: 'ID' },
  { id: 'iwms_id', label: 'IWMS ID' },
  { id: 'capacity', label: 'Capacity' },
  { id: 'counting_mode', label: 'Counting Mode' },
  { id: 'size', label: 'Area(sqft)' },
  { id: 'function', label: 'Function' },
  { id: 'floorName', label: 'Floor' },
];

export const calculateArea = (space: SpaceBuildingManager): number => {
  if (space.shape === 'circle' && space.circle_radius_meters) {
    return Number(
      Meters.toSquareFeet(
        Math.PI * Math.pow(space.circle_radius_meters, 2)
      ).toFixed(2)
    );
  } else {
    // Gauss' shoelace area formula
    let area = 0;
    const vertices = space.polygon_verticies as Vertices[];
    if (!vertices) {
      return 0;
    }
    const n = vertices.length;

    for (let i = 0; i < n; i++) {
      const j = (i + 1) % n;
      area +=
        vertices[i].x_from_origin_meters * vertices[j].y_from_origin_meters;
      area -=
        vertices[j].x_from_origin_meters * vertices[i].y_from_origin_meters;
    }

    return Number(Meters.toSquareFeet(Math.abs(area / 2)).toFixed(2));
  }
};

type Vertices = {
  x_from_origin_meters: number;
  y_from_origin_meters: number;
};
export interface SpaceBuildingManager {
  id: string;
  function?: string;
  shape: string;
  polygon_verticies?: Vertices[];
  circle_radius_meters?: number;
  counting_mode: string;
  size?: number; // may not always be calculated
}
interface SpaceFunction {
  count: number;
  data: SpaceBuildingManager[];
}

interface SpaceData {
  show: boolean;
  data: SpaceBuildingManager[];
}

type SpacesInfoProps = {
  spaces: SpaceBuildingManager[];
  spaceFunctionList: { [key: string]: string };
};

export const SpacesInfo: React.FunctionComponent<SpacesInfoProps> = ({
  spaces,
  spaceFunctionList,
}) => {
  const [showSpaceFunction, setShowSpaceFunction] = useState(false);
  const [showSpaceMode, setShowSpaceMode] = useState(false);
  const [dataTable, setDataTable] = useState<SpaceData>({
    show: false,
    data: [],
  });

  const spaceFunctions = useMemo(() => {
    const functions: { [key: string]: SpaceFunction } = {};
    spaces.forEach((space) => {
      const func = space.function || 'none';
      space.size = calculateArea(space);
      if (functions[func]) {
        functions[func].count++;
        functions[func].data.push(space);
      } else {
        functions[func] = { count: 1, data: [space] };
      }
    });
    return functions;
  }, [spaces]);

  const oaSpaces = spaces.filter((space) => space.counting_mode === 'oa');
  const entrySpaces = spaces.filter((space) => space.counting_mode === 'entry');
  const waffleSpaces = spaces.filter(
    (space) => space.counting_mode === 'waffle'
  );
  const showTable = (data: SpaceBuildingManager[]) => {
    setDataTable({ show: true, data });
  };

  if (Object.keys(spaceFunctionList).length === 0) {
    return <>Loading space functions...</>;
  }

  return (
    <>
      <button
        className={styles.infoShowAllButton}
        onClick={() => showTable(spaces)}
      >
        Show All Spaces
      </button>
      <div className={styles.entityInfoContainer}>
        {dataTable.show && dataTable.data.length > 0 && (
          <DataTable
            data={
              dataTable.data.map((space: SpaceBuildingManager) => {
                return {
                  ...space,
                  function: space.function || ' ',
                };
              }) as DataTableData[]
            }
            type="space"
            headers={spaceDataHeaders as DataTableHeader[]}
            closeModal={() => setDataTable({ show: false, data: [] })}
          />
        )}
        <div className={styles.infoSectionCard}>
          <header
            className={styles.infoSectionTitle}
            onClick={() => setShowSpaceFunction(!showSpaceFunction)}
            data-cy="space-info-types"
          >
            Space Types{' '}
            {showSpaceFunction ? (
              <Icons.ArrowDown />
            ) : (
              <Icons.ArrowRightForward />
            )}
          </header>
          {showSpaceFunction && (
            <div className={styles.infoDataContainer}>
              {Object.entries(spaceFunctions)
                .sort((a, b) => b[1].count - a[1].count)
                .map(([key, { count, data }]) => (
                  <div key={key} onClick={() => showTable(data)}>
                    {spaceFunctionList[key] || key}: {count}
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className={styles.infoSectionCard}>
          <header
            className={styles.infoSectionTitle}
            onClick={() => setShowSpaceMode(!showSpaceMode)}
            data-cy="space-info-counting-modes"
          >
            Space Counting Modes{' '}
            {showSpaceMode ? <Icons.ArrowDown /> : <Icons.ArrowRightForward />}
          </header>
          {showSpaceMode && (
            <div className={styles.infoDataContainer}>
              <div onClick={() => showTable(oaSpaces)}>
                OA: {oaSpaces.length}
              </div>
              <div onClick={() => showTable(entrySpaces)}>
                Entry: {entrySpaces.length}
              </div>
              <div onClick={() => showTable(waffleSpaces)}>
                Waffle: {waffleSpaces.length}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

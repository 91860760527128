import { useState } from 'react';
import { DataTable, DataTableData } from './data-table';
import { Icons } from '@density/dust';
import styles from './styles.module.scss';
import { FloorplanV2Sensor } from 'lib/api';

const sensorDataHeaders = [
  { id: 'status', label: 'Status' },
  { id: 'sensor_function', label: 'Type' },
  { id: 'cad_id', label: 'CAD ID' },
  { id: 'id', label: 'Sensor ID' },
  { id: 'sensor_serial_number', label: 'Serial Number' },
  { id: 'mac', label: 'MAC' },
  { id: 'ip', label: 'IP' },
  { id: 'floorName', label: 'Floor' },
];

type SensorsInfoProps = {
  sensors: FloorplanV2Sensor[];
};

export const SensorsInfo: React.FunctionComponent<SensorsInfoProps> = ({
  sensors,
}) => {
  const [showSensorStatus, setShowSensorStatus] = useState(false);
  const [showSensorFunction, setShowSensorFunction] = useState(false);
  const [showSensorCategory, setShowSensorCategory] = useState(false);
  const [dataTable, setDataTable] = useState<{
    show: boolean;
    data: FloorplanV2Sensor[];
  }>({
    show: false,
    data: [],
  });

  const getStatusCounts = (statusType: string | null) =>
    sensors.filter((sensor) => sensor.status === statusType).length;

  const getFunctionCounts = (functionType: string[]) =>
    sensors.filter(
      (sensor) =>
        functionType.includes(sensor.sensor_function) ||
        (functionType.includes('w') &&
          (sensor.sensor_function as any) === 'waffle')
    ).length;

  const getTypeCounts = (sensorType: string) =>
    sensors.filter(
      (sensor) =>
        sensor.sensor_type === sensorType ||
        // Special case for waffle sensors that might not have the expected type
        (sensorType === 'waffle' &&
          (sensor.sensor_function === 'w' ||
            (sensor.sensor_function as any) === 'waffle'))
    ).length;

  const showTable = (data: FloorplanV2Sensor[]) => {
    setDataTable({ show: true, data });
  };

  const sensorStatus = [
    { label: 'Online', type: 'online' },
    { label: 'Planning', type: null },
    { label: 'Error', type: 'error' },
    { label: 'Low Power', type: 'low_power' },
    { label: 'Unconfigured', type: 'unconfigured' },
  ];

  const sensorFunctions = [
    { label: 'OALR', types: ['oalr'] },
    { label: 'OA', types: ['oa'] },
    { label: 'Entry', types: ['entry', 'oe'] },
    { label: 'Waffle', types: ['w', 'waffle'] },
  ];

  const sensorCategories = [
    { label: 'Open Area', type: 'oa' },
    { label: 'Entry', type: 'entry' },
    { label: 'Waffle', type: 'waffle' },
  ];

  return (
    <>
      <div>
        <button
          className={styles.infoShowAllButton}
          onClick={() => showTable(sensors)}
        >
          Show All Sensors
        </button>
      </div>
      <div className={styles.entityInfoContainer}>
        {dataTable.show && dataTable.data.length > 0 && (
          <DataTable
            data={
              dataTable.data.map((sensor: FloorplanV2Sensor) => {
                return { ...sensor, status: sensor.status || 'planning' };
              }) as DataTableData[]
            }
            type="sensor"
            headers={sensorDataHeaders as []}
            closeModal={() => setDataTable({ show: false, data: [] })}
          />
        )}

        <div className={styles.infoSectionCard}>
          <header
            className={styles.infoSectionTitle}
            onClick={() => setShowSensorCategory(!showSensorCategory)}
            data-cy="sensor-info-categories"
          >
            Sensor Categories{' '}
            {showSensorCategory ? (
              <Icons.ArrowDown />
            ) : (
              <Icons.ArrowRightForward />
            )}
          </header>
          {showSensorCategory && (
            <div className={styles.infoDataContainer}>
              {sensorCategories.map((category) => (
                <div
                  className={styles.infoData}
                  key={category.type}
                  onClick={() =>
                    showTable(
                      sensors.filter(
                        (s) =>
                          s.sensor_type === category.type ||
                          // Special case for waffle sensors
                          (category.type === 'waffle' &&
                            (s.sensor_function === 'w' ||
                              (s.sensor_function as any) === 'waffle'))
                      )
                    )
                  }
                >
                  {category.label}: {getTypeCounts(category.type)}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={styles.infoSectionCard}>
          <header
            className={styles.infoSectionTitle}
            onClick={() => setShowSensorStatus(!showSensorStatus)}
            data-cy="sensor-info-status"
          >
            Sensor Status{' '}
            {showSensorStatus ? (
              <Icons.ArrowDown />
            ) : (
              <Icons.ArrowRightForward />
            )}
          </header>
          {showSensorStatus && (
            <div className={styles.infoDataContainer}>
              {sensorStatus.map((status) => (
                <div
                  className={styles.infoData}
                  key={status.type}
                  onClick={() =>
                    showTable(sensors.filter((s) => s.status === status.type))
                  }
                >
                  {status.label}: {getStatusCounts(status.type)}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.infoSectionCard}>
          <header
            className={styles.infoSectionTitle}
            onClick={() => setShowSensorFunction(!showSensorFunction)}
            data-cy="sensor-info-types"
          >
            Sensor Types{' '}
            {showSensorFunction ? (
              <Icons.ArrowDown />
            ) : (
              <Icons.ArrowRightForward />
            )}
          </header>
          {showSensorFunction && (
            <div className={styles.infoDataContainer}>
              {sensorFunctions.map((functionType) => {
                return (
                  <div
                    key={functionType.label}
                    className={styles.infoData}
                    onClick={() =>
                      showTable(
                        sensors.filter(
                          (s) =>
                            functionType.types.includes(s.sensor_function) ||
                            // Special case for waffle sensors
                            (functionType.types.includes('w') &&
                              (s.sensor_function as any) === 'waffle')
                        )
                      )
                    }
                  >
                    {functionType.label}:{' '}
                    {getFunctionCounts(functionType.types)}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

import PlanSensor, { EntryFunction, OpenAreaFunction } from 'lib/sensor';
import AreaOfConcern from 'lib/area-of-concern';
import Space from 'lib/space';
import { LengthUnit } from 'lib/units';
import { CADCoordinates, computeCentroid } from 'lib/geometry';

// If "empty" is selected, this is the layer name that is used.
export const EMPTY_LAYER_NAME = '';

// This is a constant shared with the floorplan-processor - every unit in the cad file is this
// number of pixels. This is important to be able to translate between cad image coordinate space
// anc CADCoordinates.
export const DEFAULT_PIXELS_PER_CAD_UNIT = 2;

export type PlanDXFExport = {
  id: string;
  status:
    | 'created'
    | 'fetching'
    | 'processing'
    | 'uploading'
    | 'complete'
    | 'error';
  options: {
    include_coverage?: boolean;
    include_walls?: boolean;
    use_base_dxf?: boolean;
    new_dxf_length_units?: LengthUnit;
  };
  content_type: 'application/dxf';
  started_processing_at: string | null;
  completed_processing_at: string | null;
  created_at: string;
  updated_at: string;
  exported_object_key: string;
  exported_object_url: string;
};

// === SensorPlacement / PlanSensor ===
// ====================================
export type PlanDXFSensorPlacementDataTag = {
  height_meters: number;
  position: { x: number; y: number };
  cad_id: string;
  serial_number: string | null;
};

// Returned by floorplan-processor and later massaged into ParsedPlanDXFSensorPlacement
export type PlanDXFSensorPlacement = {
  data_tag: PlanDXFSensorPlacementDataTag;
  sensor_type: 'entry' | 'oa';
  sensor_function: EntryFunction | OpenAreaFunction;
  position: { x: number; y: number };
  rotation: number;
};

// This is essentially a not yet fully imported PlanSensor
export type ParsedPlanDXFSensorPlacement = Pick<
  PlanSensor,
  'serialNumber' | 'height' | 'rotation' | 'type' | 'cadId' | 'sensorFunction'
> & {
  position: CADCoordinates;
};

export function parsePlanDXFSensorPlacements(
  sensorPlacements: Array<PlanDXFSensorPlacement>
): Array<ParsedPlanDXFSensorPlacement> {
  return sensorPlacements.map((sensor) => ({
    serialNumber: sensor.data_tag.serial_number,
    height: sensor.data_tag.height_meters,
    rotation: sensor.rotation,
    position: CADCoordinates.create(sensor.position.x, sensor.position.y),
    type: sensor.sensor_type,
    sensorFunction: sensor.sensor_function,
    cadId: sensor.data_tag.cad_id,
  }));
}

// === Area of Concern ===
// =======================

// Returned by floorplan-processor and later massaged into ParsedPlanDXFAreaOfConcern
export type PlanDXFAreaOfConcern = {
  vertices: Array<{ x: number; y: number }>;
  position: { x: number; y: number };
  cad_handle: string;
};

// This is essentially a not yet fully imported AreaOfConcern
export type ParsedPlanDXFAreaOfConcern = Pick<
  AreaOfConcern,
  'name' | 'areaMeters'
> & {
  vertices: Array<CADCoordinates>;
  position: CADCoordinates;
  cadHandle: string;
};

export function parsePlanDXFAreasOfConcern(
  areasOfConcern: Array<PlanDXFAreaOfConcern>
): Array<ParsedPlanDXFAreaOfConcern> {
  // This is where we massage the floorplan-processor output into
  // a ParsedPlanDXFAreaOfConcern, which eventually turns into AreaOfConcern (if imported)

  if (!areasOfConcern || areasOfConcern.length === 0) {
    return [];
  }

  return areasOfConcern.map((aoc) => {
    const vertices = aoc.vertices.map((v) => {
      return CADCoordinates.create(v.x, v.y);
    });

    return {
      vertices: vertices,
      name: 'AOC-' + aoc.cad_handle,
      areaMeters: AreaOfConcern.area(vertices),
      position: computeCentroid(vertices),
      cadHandle: aoc.cad_handle,
    };
  });
}

// ======= Space =======
// =====================

// Returned by floorplan-processor and later massaged into ParsedPlanDXFSpace
export type PlanDXFSpace = {
  vertices: Array<{ x: number; y: number }>;
  position: { x: number; y: number };
  cad_handle: string;
  name: string;
  counting_mode: 'oa' | 'entry' | 'waffle';
};

// This is essentially a not yet fully imported Space
export type ParsedPlanDXFSpace = Pick<Space, 'name' | 'countingMode'> & {
  vertices: Array<CADCoordinates>;
  position: CADCoordinates;
};

export function parsePlanDXFSpaces(
  spaces: Array<PlanDXFSpace>
): Array<ParsedPlanDXFSpace> {
  // This is where we massage the floorplan-processor output into
  // a ParsedPlanDXFSpace, which eventually turns into Space (if imported)

  console.log('==== DXF SPACES DEBUG ====');
  console.log('Input spaces array:', spaces);
  console.log('Input spaces length:', spaces?.length || 0);

  if (!spaces || spaces.length === 0) {
    console.log('No spaces found in DXF file!');
    return [];
  }

  // Log details about each space
  spaces.forEach((space, index) => {
    console.log(`Space #${index} details:`, {
      cad_handle: space.cad_handle,
      name: space.name,
      counting_mode: space.counting_mode,
      position: space.position,
      verticesCount: space.vertices?.length || 0,
    });
  });

  const result = spaces.map((space) => {
    const vertices = space.vertices.map((v) => {
      return CADCoordinates.create(v.x, v.y);
    });

    return {
      name: space.name || 'Space-' + space.cad_handle,
      position: computeCentroid(vertices),
      countingMode: space.counting_mode,
      vertices: vertices,
    };
  });

  console.log('Parsed spaces result:', result);
  console.log('==== END DXF SPACES DEBUG ====');

  return result;
}

export type PlanDXFAsset = {
  id: string;
  name: string;
  content_type: string;
  object_key: string;
  object_url: string;
  layer_name: string | null;
  pixels_per_unit: number | null;
};

export type PlanDXF = {
  id: string;
  status:
    | 'created'
    | 'downloading'
    | 'parsing_dxf'
    | 'processing_dxfs'
    | 'processing_images'
    | 'complete'
    | 'timeout'
    | 'error';
  format: 'autocad' | 'vectorworks' | 'plain';
  options: ParseDXFOptions;
  default_oa_sensor_layer: string;
  default_entry_sensor_layer: string;
  default_oa_space_layer: string;
  default_entry_space_layer: string;
  default_oa_space_name_layer: string;
  default_waffle_space_layer: string;
  default_waffle_space_name_layer: string;
  default_entry_space_name_layer: string;
  default_area_of_coverage_layer: string;
  layer_names: Array<string>;
  frozen_layer_names: Array<string>;
  length_unit: LengthUnit;
  scale: number;
  extent_min_x: number;
  extent_min_y: number;
  extent_max_x: number;
  extent_max_y: number;
  dxf_version: string;
  dxf_header: { [key: string]: string };
  started_processing_at: string | null;
  completed_processing_at: string | null;
  created_at: string;
  updated_at: string;
  sensor_placements: Array<PlanDXFSensorPlacement>;
  assets: Array<PlanDXFAsset>;
  areas_of_coverage: Array<PlanDXFAreaOfConcern>;
  spaces: Array<PlanDXFSpace>;
  areas_of_coverage_merged: Array<PlanDXFAreaOfConcern>;
};

export type ParseDXFOptions = {
  oa?: {
    layer: string;
  };
  entry?: {
    layer: string;
  };
  aoc?: {
    layer: string;
  };
  space?: {
    oa?: {
      layer: string;
      name_layer: string;
    };
    waffle?: {
      layer: string;
      name_layer: string;
    };
    entry?: {
      layer: string;
      name_layer: string;
    };
  };
  layers_excluded_from_raster_image?: string[];
};

export type TargetLayers = {
  oaSensors: string;
  oaSpaces: string;
  oaSpaceNames: string;
  waffleSpaces: string;
  waffleSpaceNames: string;
  entrySensors: string;
  entrySpaces: string;
  entrySpaceNames: string;
  areasOfConcern: string;
  excludedFromRasterImage: string[];
};

export function parsePlanDXF(unprocessedPlanDXF: PlanDXF): ParsedPlanDXF {
  // Debug logging for DXF content
  console.log('==== PARSE PLAN DXF DEBUG ====');
  console.log('All available layers:', unprocessedPlanDXF.layer_names);
  console.log('Frozen layers:', unprocessedPlanDXF.frozen_layer_names);
  console.log('Raw spaces from DXF:', unprocessedPlanDXF.spaces);
  console.log('Layer selection options:', unprocessedPlanDXF.options);

  // Log specific space layers
  console.log(
    'Entry spaces layer:',
    unprocessedPlanDXF.options?.space?.entry?.layer
  );
  console.log('OA spaces layer:', unprocessedPlanDXF.options?.space?.oa?.layer);
  console.log(
    'Waffle spaces layer:',
    unprocessedPlanDXF.options?.space?.waffle?.layer
  );
  console.log('==== END PARSE PLAN DXF DEBUG ====');

  return {
    id: unprocessedPlanDXF.id,
    format: unprocessedPlanDXF.format,
    layersAll: unprocessedPlanDXF.layer_names,
    layersFrozen: unprocessedPlanDXF.frozen_layer_names,
    layersOfInterest: {
      oaSensors: unprocessedPlanDXF.options?.oa?.layer || EMPTY_LAYER_NAME,
      oaSpaces:
        unprocessedPlanDXF.options?.space?.oa?.layer || EMPTY_LAYER_NAME,
      oaSpaceNames:
        unprocessedPlanDXF.options?.space?.oa?.name_layer || EMPTY_LAYER_NAME,
      waffleSpaces:
        unprocessedPlanDXF.options?.space?.waffle?.layer || EMPTY_LAYER_NAME,
      waffleSpaceNames:
        unprocessedPlanDXF.options?.space?.waffle?.name_layer ||
        EMPTY_LAYER_NAME,
      entrySensors:
        unprocessedPlanDXF.options?.entry?.layer || EMPTY_LAYER_NAME,
      entrySpaces:
        unprocessedPlanDXF.options?.space?.entry?.layer || EMPTY_LAYER_NAME,
      entrySpaceNames:
        unprocessedPlanDXF.options?.space?.entry?.name_layer ||
        EMPTY_LAYER_NAME,
      areasOfConcern:
        unprocessedPlanDXF.options?.aoc?.layer || EMPTY_LAYER_NAME,
      excludedFromRasterImage:
        unprocessedPlanDXF.options?.layers_excluded_from_raster_image || [],
    },
    lengthUnit: unprocessedPlanDXF.length_unit,
    scale: unprocessedPlanDXF.scale || 1,
    extents: {
      extentMinX: unprocessedPlanDXF.extent_min_x,
      extentMinY: unprocessedPlanDXF.extent_min_y,
      extentMaxX: unprocessedPlanDXF.extent_max_x,
      extentMaxY: unprocessedPlanDXF.extent_max_y,
    },
    dxfVersion: unprocessedPlanDXF.dxf_version,
    dxfHeader: unprocessedPlanDXF.dxf_header,
    assets: unprocessedPlanDXF.assets,
    startedProcessingAt: unprocessedPlanDXF.started_processing_at,
    completedProcessingAt: unprocessedPlanDXF.completed_processing_at,
    createdAt: unprocessedPlanDXF.created_at,
    updatedAt: unprocessedPlanDXF.updated_at,
    spaces: parsePlanDXFSpaces(unprocessedPlanDXF.spaces),
    sensorPlacements: parsePlanDXFSensorPlacements(
      unprocessedPlanDXF.sensor_placements
    ),
    areasOfConcern: {
      merged: parsePlanDXFAreasOfConcern(unprocessedPlanDXF.areas_of_coverage),
      unmerged: parsePlanDXFAreasOfConcern(
        unprocessedPlanDXF.areas_of_coverage_merged
      ),
    },
  };
}

export type ParsedPlanDXF = {
  id: string;
  format: 'autocad' | 'vectorworks' | 'plain';
  layersAll: string[];
  layersFrozen: string[];
  layersOfInterest: TargetLayers;
  lengthUnit: LengthUnit;
  scale: number;
  extents: {
    extentMinX: number;
    extentMinY: number;
    extentMaxX: number;
    extentMaxY: number;
  };
  dxfVersion: string;
  dxfHeader: { [key: string]: string };
  assets: Array<PlanDXFAsset>;
  startedProcessingAt: string | null;
  completedProcessingAt: string | null;
  createdAt: string;
  updatedAt: string;
  sensorPlacements: Array<ParsedPlanDXFSensorPlacement>;
  spaces: Array<ParsedPlanDXFSpace>;
  areasOfConcern: {
    merged: Array<ParsedPlanDXFAreaOfConcern>;
    unmerged: Array<ParsedPlanDXFAreaOfConcern>;
  };
};

import { Fragment, useMemo, useState } from 'react';
import * as React from 'react';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import classnames from 'classnames';
import Switch from 'components/switch/switch';
import Select from 'react-select';

import styles from '../styles.module.scss';

import {
  ImportDetailsTableAreasOfConcern,
  ImportDetailsTableSensors,
  ImportDetailsTableSpaces,
} from './diff-tables';

import Button from 'components/button';
import FormLabel from 'components/form-label';
import HorizontalForm from 'components/horizontal-form';
import FloorplanCoordinatesField from 'components/floorplan-coordinates-field';
import ScaleField from 'components/scale-field';
import SelectField from 'components/select-field';
import TabBar from 'components/tab-bar';
import Panel, { PanelHeaderWell, PanelBodyNoScroll } from 'components/panel';

import { EMPTY_LAYER_NAME, TargetLayers } from 'lib/dxf';
import { LengthUnit } from 'lib/units';
import FloorplanType from 'lib/floorplan';
import { FloorplanCoordinates } from 'lib/geometry';
import {
  FloorplanSensorChange,
  FloorplanSpaceChange,
  FloorplanAreaOfConcernChange,
  computeDefaultCADOrigin,
} from 'lib/cad';

import { ReactComponent as CADImportLoaderSvg } from 'img/cad-import-loader.svg';

const CADImportLoader: React.FunctionComponent = () => (
  <div className={styles.cadImportLoader}>
    <CADImportLoaderSvg />
    <p>Loading your plan. Hang tight...</p>
  </div>
);

const CADImportOperationLabel: React.FunctionComponent<{
  selected: 'Areas of Coverage' | 'Sensors' | 'Spaces';
}> = ({ selected }) => (
  <Fragment>
    <div style={{ paddingRight: 4, height: 18 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        fill="none"
        viewBox="0 0 18 19"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12.75 8.75a3 3 0 110-6 3 3 0 010 6zm1.5-3a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"
          clipRule="evenodd"
          opacity={selected === 'Areas of Coverage' ? 0.3 : 1}
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.25 2.75h-6v13.5h13.5v-6h-7.5v-7.5zm-4.5 12V4.25h3v7.5h7.5v3H3.75z"
          clipRule="evenodd"
          opacity={selected === 'Sensors' ? 0.3 : 1}
        ></path>
      </svg>
    </div>
    {selected}
  </Fragment>
);

type Tabs = 'areasOfConcern' | 'sensors' | 'spaces';

export type PlanDXFImportOptions = {
  importFloorplan: boolean;
  importSensors: boolean;
  importSensorsKeepExisting: boolean;
  importAreasOfConcern: boolean;
  importSpaces: boolean;
};

// This component renders the side panel in the DXF import process
const ImportPanel: React.FunctionComponent<{
  mode: 'update' | 'create';
  floorplan: FloorplanType;
  floorplanSensorsChanges: Array<FloorplanSensorChange>;
  floorplanSpacesChanges: Array<FloorplanSpaceChange>;
  floorplanAreasOfConcernChanges: Array<FloorplanAreaOfConcernChange>;
  layerNames: Array<string>;
  frozenLayerNames: Array<string>;
  displayUnit: LengthUnit;
  loading: boolean;

  imports: PlanDXFImportOptions;
  onImportsChanged: (newImports: PlanDXFImportOptions) => void;

  layersOfInterest: TargetLayers;
  onLayersOfInterestChanged: (newLayersOfInterest: TargetLayers) => void;

  floorplanCADOrigin: FloorplanCoordinates;
  onChangeFloorplanCADOrigin: ((coords: FloorplanCoordinates) => void) | null;
  cadFileUnit: LengthUnit;
  onChangeCADFileUnit: (newUnit: LengthUnit) => void;
  cadFileScale: number;
  pixelsPerCADUnit: number;
  onChangeCADFileScale: (newScale: number) => void;
  userChangedLayerSelections: boolean;
  onReprocessDXF: (layersOfInterest: TargetLayers) => void;
  scaleByDrawActive: boolean;
  setScaleByDraw: (scaleByDrawActive: boolean) => void;
}> = ({
  mode,
  floorplan,
  floorplanSensorsChanges,
  floorplanSpacesChanges,
  floorplanAreasOfConcernChanges,
  layerNames,
  frozenLayerNames,
  displayUnit,
  loading,
  imports,
  onImportsChanged,
  layersOfInterest,
  onLayersOfInterestChanged,
  floorplanCADOrigin,
  onChangeFloorplanCADOrigin,
  cadFileUnit,
  onChangeCADFileUnit,
  cadFileScale,
  onChangeCADFileScale,
  userChangedLayerSelections,
  onReprocessDXF,
  scaleByDrawActive,
  setScaleByDraw,
}) => {
  const [currentTab, setCurrentTab] = useState<Tabs>('sensors');
  const [optionsMenuHidden, setOptionsMenuHidden] = useState<boolean>(false);
  const [layersExcludedFromRaster, setLayersExcludedFromRaster] = useState<
    string[]
  >(layersOfInterest.excludedFromRasterImage);

  const layerChoices = useMemo(
    () => [
      { id: EMPTY_LAYER_NAME, label: 'Empty' },
      ...layerNames.map((layerName) => ({
        id: layerName,
        label: layerName,
      })),
      ...frozenLayerNames.map((layerName) => ({
        id: layerName,
        label: layerName,
        disabled: true,
      })),
    ],
    [layerNames, frozenLayerNames]
  );

  return (
    <Panel position="top-left" width={700}>
      {scaleByDrawActive ? (
        <div style={{ padding: '25px' }}>
          <FormLabel
            label="Draw like a pro!"
            input={
              <div style={{ fontSize: '13pt', lineHeight: '1.5' }}>
                <span>
                  {'\u2022 '}Hold <strong>Shift</strong> to draw beautiful
                  straight lines.
                </span>
                <br />
                <span>{'\u2022 '}Zoom in (or out) to draw accurately.</span>
                <br />
                <span>
                  {'\u2022 '}Longer reference lines = more accurate scaling.
                </span>
                <br />
                <span>
                  {'\u2022 '}Thick walls? Draw from the center of the wall!
                </span>
              </div>
            }
          />
          <FormLabel
            label="It's as easy as 1-2-3!"
            input={
              <>
                <div style={{ fontSize: '13pt', lineHeight: '1.5' }}>
                  <span>
                    (1) Measure a simple, <strong>long</strong>, and
                    (preferably) straight wall.
                  </span>
                  <br />
                  <span>
                    (2) Measure the equivalent segment on the Existing Floorplan
                    image.
                  </span>
                  <br />
                  <span>(3) Align the two Floorplans.</span>
                </div>
              </>
            }
          />
          <br />
          <Button
            size="medium"
            type="outlined"
            trailingIcon={<Icons.CancelUnavailable size={18} />}
            onClick={() => setScaleByDraw(false)}
            danger
          >
            Cancel Scale by Drawing
          </Button>
        </div>
      ) : (
        <Fragment>
          <PanelHeaderWell>
            <div
              className={styles.cadImportControlListFlush}
              style={{ paddingTop: 0, marginTop: '-5px', marginBottom: '-3px' }}
            >
              <FormLabel
                label="Length Units"
                input={
                  <SelectField
                    size="medium"
                    width={100}
                    choices={[
                      { id: 'feet_and_inches', label: 'Feet' },
                      { id: 'inches', label: 'Inches' },
                      { id: 'meters', label: 'Meters' },
                      { id: 'centimeters', label: 'Centimeters' },
                      { id: 'millimeters', label: 'Millimeters' },
                    ]}
                    value={cadFileUnit}
                    onChange={(choice) => onChangeCADFileUnit(choice.id)}
                    disabled={loading}
                  />
                }
              />
              <FormLabel
                label="Scale"
                input={
                  <ScaleField
                    value={cadFileScale}
                    disabled={loading}
                    onChange={(newValue) => onChangeCADFileScale(newValue)}
                  />
                }
              />
              {mode === 'update' && (
                <FormLabel
                  label="Scale by Drawing"
                  input={
                    <Button
                      size="medium"
                      onClick={() => {
                        setScaleByDraw(true);
                      }}
                    >
                      {'Scale by Drawing'}
                    </Button>
                  }
                />
              )}
              {onChangeFloorplanCADOrigin ? (
                <FormLabel
                  label="CAD Origin"
                  input={
                    <FloorplanCoordinatesField
                      value={floorplanCADOrigin}
                      disabled={loading}
                      computeDefaultValue={() =>
                        computeDefaultCADOrigin(floorplan)
                      }
                      onChange={onChangeFloorplanCADOrigin}
                      data-cy="cad-import-floorplan-cad-origin"
                    />
                  }
                />
              ) : null}
            </div>

            <Button
              leadingIcon={
                userChangedLayerSelections ? (
                  <Icons.DangerWarningFill color={dust.Red300} />
                ) : null
              }
              type="link"
              size="medium"
              onClick={() => {
                if (!userChangedLayerSelections) {
                  return;
                }
                onReprocessDXF({
                  ...layersOfInterest,
                  excludedFromRasterImage: layersExcludedFromRaster,
                });
              }}
              disabled={!userChangedLayerSelections}
              data-cy="cad-reprocess"
            >
              {userChangedLayerSelections
                ? 'Click here to submit your new layer selection for reprocessing.'
                : 'All good! The rendering is up to date with your layer selections.'}
            </Button>
          </PanelHeaderWell>

          <PanelBodyNoScroll>
            <div>
              <div style={{ position: 'absolute', right: 10 }}>
                <Button
                  type="link"
                  size="medium"
                  onClick={() => {
                    setOptionsMenuHidden(!optionsMenuHidden);
                  }}
                >
                  {optionsMenuHidden ? (
                    <Icons.DoubleChevronDown size={35} color={dust.Blue300} />
                  ) : (
                    <Icons.DoubleChevronUp size={35} color={dust.Blue300} />
                  )}
                </Button>
              </div>

              {optionsMenuHidden ? (
                <>
                  <HorizontalForm size="large">
                    <Switch
                      onChange={(event) =>
                        onImportsChanged({
                          ...imports,
                          importFloorplan: event.target.checked,
                        })
                      }
                      isChecked={imports.importFloorplan}
                      isDisabled={loading}
                      data-cy="cad-import-floorplan-switch"
                    />
                    <label className={classnames(styles.inline, styles.large)}>
                      Floorplan
                    </label>
                    <Switch
                      onChange={(event) =>
                        onImportsChanged({
                          ...imports,
                          importSensors: event.target.checked,
                        })
                      }
                      isChecked={imports.importSensors}
                      isDisabled={loading}
                      data-cy="cad-import-sensors-switch"
                    />
                    <label className={classnames(styles.inline, styles.large)}>
                      Sensors
                    </label>
                    &nbsp; &nbsp;
                    <Switch
                      onChange={(event) =>
                        onImportsChanged({
                          ...imports,
                          importSpaces: event.target.checked,
                        })
                      }
                      isChecked={imports.importSpaces}
                      isDisabled={loading}
                      data-cy="cad-import-spaces-switch"
                    />
                    <label className={classnames(styles.inline, styles.large)}>
                      Spaces
                    </label>
                    &nbsp; &nbsp;
                    <Switch
                      onChange={(event) =>
                        onImportsChanged({
                          ...imports,
                          importAreasOfConcern: event.target.checked,
                        })
                      }
                      isChecked={imports.importAreasOfConcern}
                      isDisabled={loading}
                      data-cy="cad-import-aocs-switch"
                    />
                    <label className={classnames(styles.inline, styles.large)}>
                      Areas of Coverage
                    </label>
                  </HorizontalForm>
                </>
              ) : (
                <div
                  style={{
                    overflowY: 'scroll',
                    scrollbarWidth: 'none',
                    maxHeight: '50vh',
                  }}
                >
                  <HorizontalForm size="large">
                    <Switch
                      onChange={(event) =>
                        onImportsChanged({
                          ...imports,
                          importFloorplan: event.target.checked,
                        })
                      }
                      isChecked={imports.importFloorplan}
                      isDisabled={loading}
                      data-cy="cad-import-floorplan-switch"
                    />
                    <label className={classnames(styles.inline, styles.large)}>
                      {mode === 'create'
                        ? 'Import Floorplan Image'
                        : 'Swap Floorplan Image'}
                    </label>
                  </HorizontalForm>

                  {mode === 'update' && (
                    <label
                      className={
                        imports.importFloorplan ? styles.active : styles.muted
                      }
                      style={{ paddingLeft: '34px', paddingTop: '0px' }}
                    >
                      {imports.importFloorplan
                        ? 'Existing floorplan image will be substituted by the new CAD import (dark gray).'
                        : 'No changes will be made to the current Floorplan Image.'}
                    </label>
                  )}

                  {imports.importFloorplan && (
                    <div
                      style={{
                        paddingLeft: '34px',
                        paddingTop: '0px',
                        width: '90%',
                      }}
                    >
                      <FormLabel
                        label="Layers Excluded from Floorplan Image Render"
                        input={
                          <Select
                            closeMenuOnSelect={false}
                            isMulti
                            defaultValue={layersOfInterest.excludedFromRasterImage.map(
                              (l) => ({
                                label: l,
                                value: l,
                              })
                            )}
                            options={layerChoices.map((c) => ({
                              label: c.label,
                              value: c.id,
                            }))}
                            onChange={(evt, actionMeta) => {
                              if (actionMeta.action === 'remove-value') {
                                onLayersOfInterestChanged({
                                  ...layersOfInterest,
                                  excludedFromRasterImage:
                                    layersOfInterest.excludedFromRasterImage.filter(
                                      (v) => v !== actionMeta.removedValue.value
                                    ),
                                });
                              } else if (
                                actionMeta.action === 'select-option' &&
                                actionMeta?.option?.value !== undefined
                              ) {
                                onLayersOfInterestChanged({
                                  ...layersOfInterest,
                                  excludedFromRasterImage: [
                                    ...layersOfInterest.excludedFromRasterImage,
                                    actionMeta.option!.value,
                                  ],
                                });

                                setLayersExcludedFromRaster(
                                  (layersExcludedFromRaster) => [
                                    ...layersExcludedFromRaster,
                                    actionMeta.option!.value,
                                  ]
                                );
                              }
                            }}
                          />
                        }
                      />
                    </div>
                  )}

                  <div
                    style={{
                      paddingBottom: '6px',
                      borderTop: '1px solid #ccc',
                    }}
                  />

                  <HorizontalForm size="large">
                    <Switch
                      onChange={(event) =>
                        onImportsChanged({
                          ...imports,
                          importSensors: event.target.checked,
                        })
                      }
                      isChecked={imports.importSensors}
                      isDisabled={loading}
                      data-cy="cad-import-sensors-switch"
                    />
                    <label className={classnames(styles.inline, styles.large)}>
                      Import Sensors
                    </label>
                  </HorizontalForm>

                  {mode === 'update' && (
                    <label
                      className={
                        imports.importSensors ? styles.active : styles.muted
                      }
                      style={{ paddingLeft: '34px', paddingTop: '0px' }}
                    >
                      {imports.importSensors
                        ? null
                        : 'No changes will be made to the current Floorplan Sensors.'}
                    </label>
                  )}

                  {imports.importSensors && (
                    <>
                      <div className={styles.cadImportControlList}>
                        <FormLabel
                          label="OA Layer"
                          input={
                            <SelectField
                              value={layersOfInterest.oaSensors}
                              choices={layerChoices.filter(
                                (c) =>
                                  c.id !== layersOfInterest.entrySensors ||
                                  c.id === EMPTY_LAYER_NAME
                              )}
                              onChange={(layer: { id: string }) => {
                                onLayersOfInterestChanged({
                                  ...layersOfInterest,
                                  oaSensors: layer.id,
                                });
                              }}
                              width={250}
                              disabled={!imports.importSensors || loading}
                              size="medium"
                              data-cy="cad-import-oa-layer-name"
                            />
                          }
                        />
                        <FormLabel
                          label="Entry Layer"
                          input={
                            <SelectField
                              value={layersOfInterest.entrySensors}
                              choices={layerChoices.filter(
                                (c) =>
                                  c.id !== layersOfInterest.oaSensors ||
                                  c.id === EMPTY_LAYER_NAME
                              )}
                              onChange={(layer: { id: string }) =>
                                onLayersOfInterestChanged({
                                  ...layersOfInterest,
                                  entrySensors: layer.id,
                                })
                              }
                              width={250}
                              disabled={!imports.importSensors || loading}
                              size="medium"
                              data-cy="cad-import-entry-layer-name"
                            />
                          }
                        />
                      </div>
                      <div className={styles.cadImportControlList}>
                        {mode === 'update' && (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                marginBottom: '-5px',
                                marginTop: '-6px',
                              }}
                            >
                              <input
                                type="checkbox"
                                onChange={(event) =>
                                  onImportsChanged({
                                    ...imports,
                                    importSensorsKeepExisting:
                                      event.target.checked,
                                  })
                                }
                                checked={imports.importSensorsKeepExisting}
                                disabled={loading || !imports.importSensors}
                                data-cy="cad-import-keep-sensors-switch"
                              />
                              <label className={styles.inline}>
                                Keep Existing Sensors
                              </label>
                            </div>
                            <label
                              className={styles.muted}
                              style={{ paddingLeft: '18px', paddingTop: '0px' }}
                            >
                              {
                                'Existing PlanSensors will not be deleted, however, import will fail if there are repeating Serial Numbers within CAD and Floorplan. Similarly, if any CAD IDs repeat, Planner will classify these repeated IDs as modifications.'
                              }
                            </label>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  <div
                    style={{
                      paddingBottom: '6px',
                      borderTop: '1px solid #ccc',
                    }}
                  />
                  <HorizontalForm size="large">
                    <Switch
                      onChange={(event) =>
                        onImportsChanged({
                          ...imports,
                          importSpaces: event.target.checked,
                        })
                      }
                      isChecked={imports.importSpaces}
                      isDisabled={loading}
                      data-cy="cad-import-spaces-switch"
                    />
                    <label className={classnames(styles.inline, styles.large)}>
                      {mode === 'create'
                        ? 'Import Spaces'
                        : 'Replace All Existing Spaces'}
                    </label>
                  </HorizontalForm>

                  {mode === 'update' && (
                    <label
                      className={
                        imports.importSpaces ? styles.active : styles.muted
                      }
                      style={{ paddingLeft: '34px', paddingTop: '0px' }}
                    >
                      {imports.importSpaces
                        ? 'Existing Spaces (AOIs) will be substituted by the Spaces from the imported CAD.'
                        : 'No changes will be made to the current Floorplan Spaces.'}
                    </label>
                  )}

                  {imports.importSpaces && (
                    <Fragment>
                      <div className={styles.cadImportControlList}>
                        <Icons.DeviceEntryTopFront
                          size={55}
                          color={dust.Gray300}
                        />
                        <FormLabel
                          label="Entry Spaces Layer"
                          input={
                            <SelectField
                              value={layersOfInterest.entrySpaces}
                              choices={layerChoices.filter(
                                (c) =>
                                  c.id !== layersOfInterest.oaSpaces ||
                                  c.id === EMPTY_LAYER_NAME
                              )}
                              onChange={(layer: { id: string }) =>
                                onLayersOfInterestChanged({
                                  ...layersOfInterest,
                                  entrySpaces: layer.id,
                                })
                              }
                              width={250}
                              disabled={!imports.importSpaces || loading}
                              size="medium"
                              data-cy="cad-import-entry-spaces-layer-name"
                            />
                          }
                        />
                        <FormLabel
                          label="Entry Space Names Layer"
                          input={
                            <SelectField
                              value={layersOfInterest.entrySpaceNames}
                              choices={layerChoices}
                              onChange={(layer: { id: string }) =>
                                onLayersOfInterestChanged({
                                  ...layersOfInterest,
                                  entrySpaceNames: layer.id,
                                })
                              }
                              width={250}
                              disabled={!imports.importSpaces || loading}
                              size="medium"
                              data-cy="cad-import-entry-space-names-layer-name"
                            />
                          }
                        />
                      </div>
                      <div className={styles.cadImportControlList}>
                        <Icons.DeviceEntrySideIsometricAngle
                          size={55}
                          color={dust.Gray300}
                        />
                        <FormLabel
                          label="OA Spaces Layer"
                          input={
                            <SelectField
                              value={layersOfInterest.oaSpaces}
                              choices={layerChoices.filter(
                                (c) =>
                                  c.id !== layersOfInterest.entrySpaces ||
                                  c.id === EMPTY_LAYER_NAME
                              )}
                              onChange={(layer: { id: string }) =>
                                onLayersOfInterestChanged({
                                  ...layersOfInterest,
                                  oaSpaces: layer.id,
                                })
                              }
                              width={250}
                              disabled={!imports.importSpaces || loading}
                              size="medium"
                              data-cy="cad-import-oa-spaces-layer-name"
                            />
                          }
                        />
                        <FormLabel
                          label="OA Space Names Layer"
                          input={
                            <SelectField
                              value={layersOfInterest.oaSpaceNames}
                              choices={layerChoices}
                              onChange={(layer: { id: string }) =>
                                onLayersOfInterestChanged({
                                  ...layersOfInterest,
                                  oaSpaceNames: layer.id,
                                })
                              }
                              width={250}
                              disabled={!imports.importSpaces || loading}
                              size="medium"
                              data-cy="cad-import-oa-space-names-layer-name"
                            />
                          }
                        />
                      </div>
                      <div className={styles.cadImportControlList}>
                        <Icons.DeviceEntrySideIsometricAngle
                          size={55}
                          color={dust.Gray300}
                        />
                        <FormLabel
                          label="Waffle Spaces Layer"
                          input={
                            <SelectField
                              value={layersOfInterest.waffleSpaces}
                              choices={layerChoices.filter(
                                (c) =>
                                  (c.id !== layersOfInterest.entrySpaces &&
                                    c.id !== layersOfInterest.oaSpaces) ||
                                  c.id === EMPTY_LAYER_NAME
                              )}
                              onChange={(layer: { id: string }) =>
                                onLayersOfInterestChanged({
                                  ...layersOfInterest,
                                  waffleSpaces: layer.id,
                                })
                              }
                              width={250}
                              disabled={!imports.importSpaces || loading}
                              size="medium"
                              data-cy="cad-import-waffle-spaces-layer-name"
                            />
                          }
                        />
                        <FormLabel
                          label="Waffle Space Names Layer"
                          input={
                            <SelectField
                              value={layersOfInterest.waffleSpaceNames}
                              choices={layerChoices}
                              onChange={(layer: { id: string }) =>
                                onLayersOfInterestChanged({
                                  ...layersOfInterest,
                                  waffleSpaceNames: layer.id,
                                })
                              }
                              width={250}
                              disabled={!imports.importSpaces || loading}
                              size="medium"
                              data-cy="cad-import-waffle-space-names-layer-name"
                            />
                          }
                        />
                      </div>
                    </Fragment>
                  )}

                  <div
                    style={{
                      paddingBottom: '6px',
                      borderTop: '1px solid #ccc',
                    }}
                  />
                  <HorizontalForm size="large">
                    <Switch
                      onChange={(event) =>
                        onImportsChanged({
                          ...imports,
                          importAreasOfConcern: event.target.checked,
                        })
                      }
                      isChecked={imports.importAreasOfConcern}
                      isDisabled={loading}
                      data-cy="cad-import-aocs-switch"
                    />
                    <label className={classnames(styles.inline, styles.large)}>
                      {mode === 'create'
                        ? 'Import Areas of Coverage (AOCs)'
                        : 'Replace All Existing Areas Of Coverage'}
                    </label>
                  </HorizontalForm>

                  {mode === 'update' && (
                    <label
                      className={
                        imports.importAreasOfConcern
                          ? styles.active
                          : styles.muted
                      }
                      style={{ paddingLeft: '34px', paddingTop: '0px' }}
                    >
                      {imports.importAreasOfConcern
                        ? 'Existing Areas of Coverage will be substituted by the AOCs from the imported CAD.'
                        : 'No changes will be made to the current Floorplan Areas of Coverage.'}
                    </label>
                  )}

                  {imports.importAreasOfConcern && (
                    <div className={styles.cadImportControlList}>
                      <FormLabel
                        label="Areas of Coverage Layer"
                        input={
                          <SelectField
                            value={layersOfInterest.areasOfConcern}
                            choices={layerChoices}
                            onChange={(layer: { id: string }) =>
                              onLayersOfInterestChanged({
                                ...layersOfInterest,
                                areasOfConcern: layer.id,
                              })
                            }
                            width={250}
                            disabled={!imports.importAreasOfConcern || loading}
                            size="medium"
                            data-cy="cad-import-aoc-layer-name"
                          />
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </PanelBodyNoScroll>

          <HorizontalForm size="medium">
            <TabBar
              choices={[
                {
                  id: 'sensors',
                  label: <CADImportOperationLabel selected="Sensors" />,
                },
                {
                  id: 'spaces',
                  label: <CADImportOperationLabel selected="Spaces" />,
                },
                {
                  id: 'areasOfConcern',
                  label: (
                    <CADImportOperationLabel selected="Areas of Coverage" />
                  ),
                },
              ]}
              data-cy="cad-import-details-table"
              value={currentTab}
              onChange={(choiceId) => {
                setCurrentTab(choiceId as Tabs);
              }}
            />
          </HorizontalForm>

          <div style={{ overflowY: 'auto' }} data-cy="cad-import-diff-table">
            {currentTab === 'sensors' ? (
              floorplanSensorsChanges.length === 0 ? (
                <div className={styles.diffTableEmpty}>
                  <Icons.DeviceEntrySideIsometricAngle size={40} />
                  {imports.importSensors
                    ? 'No sensors found in DXF!'
                    : 'You chose not to import any Sensor objects.'}
                </div>
              ) : (
                <ImportDetailsTableSensors
                  floorplanSensorsChanges={floorplanSensorsChanges}
                  floorplan={floorplan}
                  floorplanCADOrigin={floorplanCADOrigin}
                  loading={loading}
                  cadFileUnit={cadFileUnit}
                  cadFileScale={cadFileScale}
                  displayUnit={displayUnit}
                />
              )
            ) : null}

            {currentTab === 'areasOfConcern' ? (
              <>
                {floorplanAreasOfConcernChanges.length === 0 ? (
                  <div className={styles.diffTableEmpty}>
                    <Icons.Control4 size={40} />
                    {imports.importAreasOfConcern
                      ? 'No Areas of Coverage found in DXF!'
                      : 'You chose not to import any Area of Coverage objects.'}
                  </div>
                ) : (
                  <ImportDetailsTableAreasOfConcern
                    floorplanAreasOfConcernChanges={
                      floorplanAreasOfConcernChanges
                    }
                    floorplan={floorplan}
                    floorplanCADOrigin={floorplanCADOrigin}
                    loading={loading}
                    cadFileUnit={cadFileUnit}
                    cadFileScale={cadFileScale}
                    displayUnit={displayUnit}
                  />
                )}
              </>
            ) : null}

            {currentTab === 'spaces' ? (
              <>
                {floorplanSpacesChanges.length === 0 ? (
                  <div className={styles.diffTableEmpty}>
                    <Icons.SpaceTypeSpace size={40} />
                    {imports.importSpaces
                      ? 'No Spaces found in DXF!'
                      : 'You chose not to import any Space objects.'}
                  </div>
                ) : (
                  <ImportDetailsTableSpaces
                    floorplanSpacesChanges={floorplanSpacesChanges}
                    floorplan={floorplan}
                    floorplanCADOrigin={floorplanCADOrigin}
                    loading={loading}
                    cadFileUnit={cadFileUnit}
                    cadFileScale={cadFileScale}
                    displayUnit={displayUnit}
                  />
                )}
              </>
            ) : null}

            {loading ? <CADImportLoader /> : null}
          </div>
        </Fragment>
      )}
    </Panel>
  );
};

export default ImportPanel;

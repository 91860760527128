import {
  Fragment,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import { AxiosInstance } from 'axios';
import * as React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Icons as DensityUIIcons, Dialogger } from '@densityco/ui';
import { Icons } from '@density/dust';
import debounce from 'lodash.debounce';
import FloorplanCollection from 'lib/floorplan-collection';
import { convertUTCtoLocalDateString } from 'lib/date-time';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';
import { css } from '@emotion/react';
import { ReactComponent as HelpQuestionMark } from 'img/help-question-mark.svg';

import PlanSensor, {
  SensorValidation,
  PlanSensorFunction,
  getSensorStatusColor,
  sensorStatusDisplayName,
} from 'lib/sensor';

import Threshold from 'lib/threshold';
import { FloorplanV2Plan, FloorplanAPI, CoreAPI } from 'lib/api';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import {
  displayEllipticalArea,
  displayLength,
  LengthUnit,
  Meters,
} from 'lib/units';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import Switch from 'components/switch/switch';
import Tooltip from 'components/tooltip';
import TextField from 'components/text-field';
import RotationField from 'components/rotation-field';
import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import NotesBox from 'components/notes-box';
import Panel, { PanelHeader, PanelBody, PanelActions } from 'components/panel';
import ValidationsList from 'components/validations-list';

const SensorRotationInput: React.FunctionComponent<{
  value: number;
  disabled: boolean;
  onChange: (rotation: number) => void;
  onRotateRight90: () => void;
}> = ({ value, disabled, onChange, onRotateRight90 }) => {
  const [angle, setAngle] = useState(value);
  useEffect(() => setAngle(value), [value]);

  const blurredRotationValueRef = useRef<number | null>(null);

  // 4 times a second, propegate the potentially rapidly changing angle from the user dragging the
  // indicator from the rotation field to the editor state
  const debouncedOnChange = useMemo(() => {
    return debounce((rotation: number) => {
      // If this rotation has already been emitted upwards, then don't emit it again
      if (rotation === blurredRotationValueRef.current) {
        blurredRotationValueRef.current = null;
        return;
      }
      onChange(rotation);
    }, 250);
  }, [onChange]);

  return (
    <RotationField
      value={angle}
      disabled={disabled}
      onChange={(newAngle) => {
        setAngle(newAngle);
        debouncedOnChange(newAngle);
      }}
      onRotateRight90={onRotateRight90}
      rotationSVGGlyph={
        <Fragment>
          <path
            d="M18.1113 32.2227H21.8891V34.556H18.1113V32.2227Z"
            fill="transparent"
            stroke={dust.Blue400}
            strokeWidth="2"
          />
          <path
            d="M20 35.5557V39.889"
            fill="transparent"
            stroke={dust.Blue400}
            strokeWidth="2"
          />
          <path
            d="M26.8077 31.6375L26.8072 31.6375L13.2474 31.667L13.247 31.667C10.3731 31.6744 8.03712 29.349 8.02948 26.4742C8.02948 26.4741 8.02948 26.4739 8.02948 26.4738L8.00002 12.9144L8.00002 12.914C7.99261 10.0395 10.3156 7.70411 13.1899 7.69648C13.19 7.69647 13.1901 7.69647 13.1903 7.69647L26.7526 7.66701L26.753 7.66701C29.6269 7.6596 31.9629 9.98494 31.9705 12.8598C31.9705 12.8599 31.9705 12.8601 31.9705 12.8602L32 26.4196L32 26.42C32.0074 29.2974 29.6815 31.6326 26.8077 31.6375Z"
            stroke={dust.Blue400}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
          />
          <path
            d="M18.6875 14.1498C18.6875 13.8129 18.9572 13.5278 19.3045 13.5278H20.6958C21.0431 13.5278 21.3128 13.8128 21.3128 14.1498V16.7848C21.3128 18.5391 22.263 20.064 23.6634 20.8573L23.6686 20.8602L25.8331 22.1342C26.1281 22.3078 26.2241 22.6862 26.0578 22.9797L25.3621 24.208C25.1937 24.5055 24.8144 24.6128 24.5153 24.4368L22.3294 23.1502C21.6475 22.7356 20.8515 22.4973 20.0002 22.4973C19.1489 22.4973 18.353 22.7355 17.6713 23.15L17.6668 23.1527L15.4851 24.4368C15.1859 24.6128 14.8066 24.5054 14.6381 24.208L13.9425 22.9797C13.7763 22.6863 13.8722 22.3078 14.1672 22.1342L16.3366 20.8574C17.7371 20.0641 18.6875 18.5392 18.6875 16.7848V14.1498Z"
            fill={dust.Blue400}
            stroke="transparent"
          />
          <path
            d="M15.6665 4.50022L19.9998 1.61133L24.3332 4.50022"
            stroke={dust.Blue400}
            strokeWidth="2"
            fill="transparent"
          />
        </Fragment>
      }
      data-cy="sensors-focused-panel-rotation-text-field"
      onBlur={(newRotation: number) => {
        if (newRotation !== value) {
          blurredRotationValueRef.current = newRotation;
          onChange(newRotation);
        }
      }}
    />
  );
};

const reduceDecimalPlaces = (value: number): string => {
  const numDigits = value.toString().length;
  if (numDigits <= 5) {
    return value.toString();
  } else if (value > 10000) {
    return value.toFixed(0);
  } else if (value > 1000) {
    return value.toFixed(1);
  } else if (value > 100) {
    return value.toFixed(2);
  } else if (value > 10) {
    return value.toFixed(3);
  } else if (value > 1) {
    return value.toFixed(4);
  } else {
    return value.toFixed(5);
  }
};

const SensorHeightField: React.FunctionComponent<{
  width: number;
  min: number;
  max: number;
  value: number;
  disabled: boolean;
  displayUnits: LengthUnit; // only here so we can use it as a useEffect dependency
  handleSendToState: (height: number) => void;
}> = ({
  width,
  value,
  min,
  max,
  disabled,
  displayUnits,
  handleSendToState,
}) => {
  const [inputValue, setInputValue] = useState<string>(value.toString());
  const [focusing, setFocusing] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean>(true);

  useEffect(() => {
    const newValue = Number(inputValue);
    if (isNaN(newValue)) {
      setIsInputValid(false);
    } else if (newValue >= min && newValue <= max) {
      setIsInputValid(true);
    } else {
      setIsInputValid(false);
    }
  }, [inputValue, min, max]);

  useEffect(() => {
    // Reset the input value if the units change
    setInputValue(value.toString());
  }, [displayUnits]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Only update the input value to be the value from state if:
    // 1) the input is not currently being updated (not focused)
    // 2) it's a valid input (we want to show the invalid input to make it clear it needs to be updated)

    if (!focusing && isInputValid) {
      setInputValue(value.toString());
    }
  }, [value, inputValue, focusing, isInputValid]);

  return (
    <TextField
      type="number"
      width={width}
      size="medium"
      disabled={disabled}
      min={min}
      max={max}
      step={1}
      value={inputValue}
      onChange={(evt) => setInputValue(evt.currentTarget.value)}
      onBlur={(evt) => {
        setFocusing(false);
        const newValue = Number(evt.currentTarget.value);
        handleSendToState(newValue);
      }}
      onFocus={() => setFocusing(true)}
      error={!focusing && !isInputValid}
      data-cy="sensor-height-field"
    />
  );
};

const parseMeasurement = (m: string): number =>
  Math.round(window.parseFloat(m));

// Need a completely separate component for feet + inches to handle inches overflow
const SensorFeetInchesField: React.FunctionComponent<{
  valueMeters: number;
  sensorHeightBounds: { min: number; max: number };
  disabled: boolean;
  onChange: (height: number) => void;
}> = ({ valueMeters, sensorHeightBounds, disabled, onChange }) => {
  const [feet, inches] = Meters.toFeetAndInches(valueMeters);
  const [feetInput, setFeetInput] = useState<string>(`${feet}`);
  const [inchesInput, setInchesInput] = useState<string>(`${inches}`);
  const [isValueValid, setIsValueValid] = useState<boolean>(true);
  const [focusingFeet, setFocusingFeet] = useState<boolean>(false);
  const [focusingInches, setFocusingInches] = useState<boolean>(false);

  // When the value changes externally, update the feet box and inches box contents
  useEffect(() => {
    const [feet, inches] = Meters.toFeetAndInches(valueMeters);
    setFeetInput(`${feet}`);
    setInchesInput(`${inches}`);
  }, [valueMeters]);

  useEffect(() => {
    const newValue = Meters.fromFeetAndInches(
      parseMeasurement(feetInput),
      parseMeasurement(inchesInput)
    );
    if (isNaN(newValue)) {
      setIsValueValid(false);
    } else if (
      newValue >= sensorHeightBounds.min &&
      newValue <= sensorHeightBounds.max
    ) {
      setIsValueValid(true);
    } else {
      setIsValueValid(false);
    }
  }, [feetInput, inchesInput, sensorHeightBounds.max, sensorHeightBounds.min]);

  const handleSubmit = () => {
    const feet = parseMeasurement(feetInput);
    const inches = parseMeasurement(inchesInput);
    setFeetInput(`${feet}`);
    setInchesInput(`${inches}`);

    const newMeters = Meters.fromFeetAndInches(feet, inches);
    if (
      newMeters > sensorHeightBounds.max ||
      newMeters < sensorHeightBounds.min
    ) {
      setIsValueValid(false);
    } else {
      setIsValueValid(true);
    }
    onChange(newMeters);
  };

  return (
    <div className={styles.sensorHeightFormGroup}>
      <div className={styles.sensorHeightFormFields}>
        <div className={styles.inputInlineIcon}>
          <Icons.RulerVertical size={18} />
        </div>
        <TextField
          type="number"
          width={40}
          size="medium"
          disabled={disabled}
          min={Meters.toFeet(sensorHeightBounds.min)}
          max={Meters.toFeet(sensorHeightBounds.max)}
          step={1}
          value={feetInput}
          onChange={(evt) => {
            setFeetInput(evt.currentTarget.value);
          }}
          onBlur={() => {
            setFocusingFeet(false);
            handleSubmit();
          }}
          onFocus={() => setFocusingFeet(true)}
          error={!focusingFeet && !focusingInches && !isValueValid}
          data-cy="sensor-height-feet"
        />
        <label className={styles.sensorHeightInputLabel}>ft</label>
        <TextField
          type="number"
          width={40}
          size="medium"
          disabled={disabled}
          min={-1}
          max={12}
          step={1}
          value={inchesInput}
          onChange={(evt) => {
            const value = parseMeasurement(evt.currentTarget.value);
            if (value === -1) {
              const nextFeet = feet - 1;
              const nextInches = 11;
              setFeetInput(nextFeet.toString());
              setInchesInput(nextInches.toString());
            } else if (value === 12) {
              const nextFeet = feet + 1;
              const nextInches = 0;
              setFeetInput(nextFeet.toString());
              setInchesInput(nextInches.toString());
              onChange(Meters.fromFeetAndInches(nextFeet, nextInches));
            } else {
              setInchesInput(value.toString());
            }
          }}
          onBlur={() => {
            setFocusingInches(false);
            handleSubmit();
          }}
          onFocus={() => setFocusingInches(true)}
          error={!focusingFeet && !focusingInches && !isValueValid}
          data-cy="sensor-height-inches"
        />
        <label className={styles.sensorHeightInputLabel}>in</label>
      </div>
      {/* .sensorHeightInputFieldss */}
      <div className={styles.sensorHeightValue}>
        {feet}' {inches}"
      </div>
    </div>
  );
};

export const SensorHeightInput: React.FunctionComponent<{
  value: number;
  sensorHeightBounds: { min: number; max: number };
  disabled: boolean;
  displayUnits: LengthUnit;
  onChange: (height: number) => void;
}> = ({ value, sensorHeightBounds, disabled, displayUnits, onChange }) => {
  switch (displayUnits) {
    case 'inches': {
      const inches = Math.round(Meters.toInches(value));
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical size={18} />
            </div>
            <SensorHeightField
              width={80}
              min={Meters.toInches(sensorHeightBounds.min)}
              max={Meters.toInches(sensorHeightBounds.max)}
              value={inches}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(Meters.fromInches(Math.round(val)));
              }}
            />
            <label className={styles.sensorHeightInputLabel}>in</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(inches)}"
          </div>
        </div>
      );
    }
    case 'centimeters': {
      const centimeters = Meters.toCentimeters(value);
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical size={18} />
            </div>
            <SensorHeightField
              width={80}
              min={Meters.toCentimeters(sensorHeightBounds.min)}
              max={Meters.toCentimeters(sensorHeightBounds.max)}
              value={centimeters}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(Meters.fromCentimeters(val));
              }}
            />
            <label className={styles.sensorHeightInputLabel}>cm</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(centimeters)}cm
          </div>
        </div>
      );
    }
    case 'millimeters': {
      const millimeters = Meters.toMillimeters(value);
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical size={18} />
            </div>
            <SensorHeightField
              width={80}
              min={Meters.toMillimeters(sensorHeightBounds.min)}
              max={Meters.toMillimeters(sensorHeightBounds.max)}
              value={millimeters}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(Meters.fromMillimeters(val));
              }}
            />
            <label className={styles.sensorHeightInputLabel}>mm</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(millimeters)}mm
          </div>
        </div>
      );
    }
    case 'meters': {
      const meters = value;
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical size={18} />
            </div>
            <SensorHeightField
              width={80}
              min={sensorHeightBounds.min}
              max={sensorHeightBounds.max}
              value={meters}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(val);
              }}
            />
            <label className={styles.sensorHeightInputLabel}>m</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(meters)}m
          </div>
        </div>
      );
    }
    case 'feet_and_inches': {
      return (
        <SensorFeetInchesField
          valueMeters={value}
          onChange={onChange}
          disabled={disabled}
          sensorHeightBounds={sensorHeightBounds}
        />
      );
    }
  }
};

const FocusedSensorPanel: React.FunctionComponent<{
  state: State;
  sensor: PlanSensor;
  dispatch: React.Dispatch<Action>;
  client: AxiosInstance;
  planId: FloorplanV2Plan['id'];
  onChangeLinkedSensorSerialNumber: (
    serialNumber: PlanSensor['serialNumber']
  ) => void;
  onChangeCadId: (cadId: PlanSensor['cadId']) => void;
  onChangeSensorHeight: (sensorHeight: PlanSensor['height']) => void;
  onChangeRotation: (rotation: PlanSensor['rotation']) => void;
  onChangeSensorType: (sensorType: PlanSensor['sensorFunction']) => void;
  onChangeBoundingBoxFilter: (
    boundingBoxFilter: PlanSensor['boundingBoxFilter']
  ) => void;
  onChangeNotes: (notes: PlanSensor['notes']) => void;
  onChangeLocked: (locked: PlanSensor['locked']) => void;
  onDelete: () => void;
  onUnlinkFromThreshold: (thresholdId: Threshold['id']) => void;
}> = ({
  state,
  sensor,
  dispatch,
  client,
  planId,
  onChangeLinkedSensorSerialNumber,
  onChangeCadId,
  onChangeSensorHeight,
  onChangeRotation,
  onChangeSensorType,
  onChangeBoundingBoxFilter,
  onChangeNotes,
  onChangeLocked,
  onDelete,
  onUnlinkFromThreshold,
}) => {
  const isValidationEnabled = useTreatment(SPLITS.VALIDATION);
  const isHideIndividualSensorStreamingEnabled = useTreatment(
    SPLITS.HIDE_INDIVIDUAL_SENSOR_STREAMING
  );
  const isNoisePolygonsButtonEnabled = useTreatment(
    SPLITS.NOISE_POLYGONS_BUTTON
  );

  const relatedThresholds: Threshold[] = useMemo(() => {
    return FloorplanCollection.list(state.thresholds).filter((threshold) =>
      threshold.relatedPlanSensors.includes(sensor['id'])
    );
  }, [state.thresholds, sensor]);

  const isWaffleSensor = sensor.type === 'waffle';

  const [serialNumberText, setSerialNumberText] = useState<string>(
    sensor.serialNumber || ''
  );
  useEffect(() => {
    setSerialNumberText(sensor.serialNumber || '');
  }, [sensor.serialNumber]);

  const [workingCADId, setWorkingCADId] = useState(sensor.cadId);
  useEffect(() => {
    setWorkingCADId(sensor.cadId);
  }, [sensor.cadId]);

  const sensorConnection = state.sensorConnections.get(sensor.id) || null;
  const sensorStreamingStatus = sensorConnection
    ? sensorConnection.status
    : 'Off';
  const sensorIsLinked = sensor.serialNumber !== null;

  const [majorMeters, minorMeters] = PlanSensor.computeCoverageMajorMinorAxisOA(
    sensor.height,
    sensor.sensorFunction
  );
  const major = displayLength(majorMeters, state.displayUnit);
  const minor = displayLength(minorMeters, state.displayUnit);

  const coverageArea = displayEllipticalArea(
    majorMeters,
    minorMeters,
    state.displayUnit
  );

  const lastHeartbeat = sensor.last_heartbeat
    ? convertUTCtoLocalDateString(sensor.last_heartbeat)
    : '---';

  const [locateButtonDisabled, setLocateButtonDisabled] = useState(false);

  const unlinkSensor = async () => {
    const ok = await Dialogger.confirm({
      title: 'Unlink Sensor',
      confirmText: 'Confirm',
      prompt: (
        <Fragment>
          Are you sure you would like to unlink sensor:{' '}
          <strong>{serialNumberText}</strong>?
        </Fragment>
      ),
    });
    if (!ok) return;
    setSerialNumberText('');
    onChangeLinkedSensorSerialNumber(null);
  };

  const copySensorSerialToClipboard = () => {
    // Copy the serial number to the clipboard
    // throw an error if the browser doesn't support clipboard API
    try {
      navigator.clipboard.writeText(serialNumberText || '');
      toast.success('Copied Serial to Clipboard');
    } catch {
      toast.error('Failed to copy Serial Number to Clipboard');
    }
  };

  const sensorValidations = useMemo(() => {
    const validations = state.validations.get(sensor.id);
    if (!validations || validations === 'empty' || validations === 'loading') {
      return [];
    }
    return validations.filter(
      (v): v is SensorValidation => v.objectType === 'sensor'
    );
  }, [sensor, state.validations]);

  const onDuplicate = useCallback(() => {
    // Prevent duplication if read-only or waffle
    if (state.locked || isWaffleSensor) {
      return;
    }

    dispatch({
      type: 'menu.duplicateSensor',
      id: sensor['id'],
    });
  }, [dispatch, sensor, state.locked, isWaffleSensor]);

  return (
    <Panel position="top-left">
      <PanelHeader>
        <div
          className={classNames([
            styles.inputInline,
            styles.panelHeaderTextboxActionWrapper,
          ])}
        >
          <TextField
            size="medium"
            leadingIcon={
              sensor.type === 'oa' ? (
                <Icons.DeviceEntrySideIsometricAngle
                  size={18}
                  color={
                    sensor.sensorFunction === 'oaMidRange'
                      ? dust.Blue400
                      : dust.Teal400
                  }
                />
              ) : (
                <Icons.DeviceEntryTopFront size={18} color={dust.Purple400} />
              )
            }
            placeholder={sensor.type === 'oa' ? 'ex: B2EJK019' : sensor.name}
            type="text"
            value={serialNumberText}
            disabled={
              state.locked || sensor.locked || sensorIsLinked || isWaffleSensor
            }
            onChange={(evt) => {
              setSerialNumberText(evt.currentTarget.value.toUpperCase());
            }}
            width="100%"
            data-cy="sensors-focused-panel-serial-number"
          />

          <div
            className={`${styles.panelHeaderTextboxAction} ${styles.sensorSerialButtonGroup}`}
          >
            {sensorIsLinked ? (
              <Tooltip
                contents={'Unlink Serial Number'}
                placement="bottom"
                target={
                  <Button
                    size="medium"
                    type="outlined"
                    disabled={state.locked || sensor.locked || isWaffleSensor}
                    onClick={unlinkSensor}
                    trailingIcon={<Icons.LinkBroken size={18} />}
                    danger
                    data-cy="sensors-focused-panel-serial-number-unlink"
                  />
                }
              />
            ) : (
              <Tooltip
                contents={'Link Serial Number'}
                placement="bottom"
                target={
                  <Button
                    disabled={serialNumberText === ''}
                    size="medium"
                    onClick={() => {
                      onChangeLinkedSensorSerialNumber(serialNumberText);
                    }}
                    trailingIcon={<Icons.LinkLinked size={18} />}
                    data-cy="sensors-focused-panel-serial-number-link"
                  />
                }
              />
            )}
            <Button
              disabled={serialNumberText === ''}
              size="medium"
              onClick={copySensorSerialToClipboard}
              trailingIcon={<Icons.CopyDuplicate size={18} />}
              data-cy="sensors-focused-panel-serial-number-copy"
            />
          </div>
        </div>
      </PanelHeader>

      <PanelBody>
        <div className={classNames([styles.formGroup])}>
          <TextField
            type="text"
            size="medium"
            placeholder={
              sensor.type === 'oa'
                ? 'ex: CUST-LOC-FLR-OAS001'
                : 'ex: CUST-LOC-FLR-ES001'
            }
            width="100%"
            leadingIcon={
              <DensityUIIcons.Apps
                height={16}
                width={16}
                color="currentColor"
              />
            }
            value={workingCADId}
            disabled={state.locked || sensor.locked || isWaffleSensor}
            onChange={(evt) => setWorkingCADId(evt.currentTarget.value)}
            onBlur={() => {
              if (workingCADId !== sensor.cadId) {
                onChangeCadId(workingCADId);
              }
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.8em',
            gap: '0.3em',
            fontWeight: 400,
            paddingBottom: '6px',
            paddingTop: '2px',
          }}
        >
          <Icons.VisibilityShowEye size={18} color={dust.Blue400} />
          Hold Ctrl+Shift to Isolate Sensor
        </div>

        <div className={styles.formGroup}>
          {!isWaffleSensor && (
            <SensorHeightInput
              value={sensor.height}
              sensorHeightBounds={{
                min: PlanSensor.computeMinHeight(sensor.sensorFunction),
                max: PlanSensor.computeMaxHeight(sensor.sensorFunction),
              }}
              disabled={state.locked || sensor.locked || isWaffleSensor}
              displayUnits={state.displayUnit}
              onChange={(height: number) => onChangeSensorHeight(height)}
            />
          )}
        </div>
        {/* .sensorHeightInput */}

        {!isWaffleSensor && (
          <div
            className={classNames([
              styles.formGroup,
              styles.sensorRotationFormGroup,
            ])}
          >
            <label className={classNames([styles.large, styles.muted])}>
              Orientation:
            </label>
            <div className={styles.sensorHeightFormFields}>
              <SensorRotationInput
                value={sensor.rotation}
                disabled={state.locked || sensor.locked || isWaffleSensor}
                onChange={(rotation) => onChangeRotation(rotation)}
                onRotateRight90={() => onChangeRotation(sensor.rotation + 90)}
              />
            </div>
            {/* .inputInline */}
          </div>
        )}

        {/* .sensorType */}
        <div
          className={classNames([
            styles.formGroup,
            styles.sensorRotationFormGroup,
          ])}
        >
          <label className={classNames([styles.large, styles.muted])}>
            {sensor.type === 'oa' ? 'OA Type:' : 'Sensor Type:'}
          </label>

          {sensor.serialNumber ? (
            <Button size="medium" type="filled">
              {PlanSensorFunction.generateDisplayName(sensor.sensorFunction)}
            </Button>
          ) : sensor.type === 'waffle' ? (
            <Button size="medium" type="filled" disabled>
              Waffle
            </Button>
          ) : sensor.type === 'oa' ? (
            <HorizontalForm size="medium">
              <Tooltip
                contents={'Short Range'}
                placement="bottom"
                target={
                  <Button
                    size="medium"
                    type="hollow"
                    active={Boolean(sensor.sensorFunction === 'oaShortRange')}
                    disabled={state.locked || sensor.locked || isWaffleSensor}
                    onClick={() => {
                      onChangeSensorType('oaShortRange');
                    }}
                  >
                    SR
                  </Button>
                }
              />
              <Tooltip
                contents={'Regular OA1 Hardware'}
                placement="bottom"
                target={
                  <Button
                    size="medium"
                    type="hollow"
                    active={Boolean(sensor.sensorFunction === 'oaMidRange')}
                    disabled={state.locked || sensor.locked || isWaffleSensor}
                    onClick={() => {
                      onChangeSensorType('oaMidRange');
                    }}
                  >
                    MR
                  </Button>
                }
              />
              <Tooltip
                contents={'Long Range'}
                placement="bottom"
                target={
                  <Button
                    size="medium"
                    type="hollow"
                    active={Boolean(sensor.sensorFunction === 'oaLongRange')}
                    disabled={state.locked || sensor.locked || isWaffleSensor}
                    onClick={() => {
                      onChangeSensorType('oaLongRange');
                    }}
                  >
                    LR
                  </Button>
                }
              />
            </HorizontalForm>
          ) : (
            <HorizontalForm size="medium">
              <Tooltip
                contents={'Legacy Entry Sensor'}
                placement="bottom"
                target={
                  <Button
                    size="medium"
                    type="hollow"
                    active={Boolean(sensor.sensorFunction === 'tofEntry')}
                    disabled={state.locked || sensor.locked || isWaffleSensor}
                    onClick={() => {
                      onChangeSensorType('tofEntry');
                    }}
                  >
                    ToF
                  </Button>
                }
              />
              <Tooltip
                contents={'Open Entry Sensor'}
                placement="bottom"
                target={
                  <Button
                    size="medium"
                    type="hollow"
                    active={Boolean(sensor.sensorFunction === 'openEntry')}
                    disabled={state.locked || sensor.locked || isWaffleSensor}
                    onClick={() => {
                      onChangeSensorType('openEntry');
                    }}
                  >
                    Entry LR
                  </Button>
                }
              />
            </HorizontalForm>
          )}
        </div>

        {/* .threshold Drawing */}
        {sensor.type === 'entry' ? (
          <>
            <div
              className={classNames([
                styles.formGroup,
                styles.sensorRotationFormGroup,
              ])}
            >
              <label className={classNames([styles.large, styles.muted])}>
                Doorways:
              </label>
              <div className={styles.sensorHeightFormFields}>
                {relatedThresholds.length > 0 ? null : (
                  <>
                    <Tooltip
                      contents={'Link an Existing Doorway'}
                      placement="bottom"
                      target={
                        <Button
                          size="medium"
                          type="hollow"
                          active={Boolean(
                            state.selectionMode.active &&
                              state.selectionMode.expectedSelectionType ===
                                'threshold'
                          )}
                          disabled={
                            state.locked ||
                            !state.planning.showSensors ||
                            FloorplanCollection.list(state.thresholds)
                              .length === 0
                          }
                          onClick={() => {
                            dispatch({
                              type: 'selectionMode.begin',
                              expectedSelectionType: 'threshold',
                              hideAllLayersBut: ['thresholds', 'entrySensors'],
                              initialActionCreator: sensor['id'],
                              pendingAction: 'threshold.linkPlanSensor',
                            });
                          }}
                          data-cy="sensors-focused-panel-link-threshold"
                          trailingIcon={<Icons.LinkLinked size={18} />}
                        >
                          {'Link '}
                        </Button>
                      }
                    />

                    <Tooltip
                      contents={'Draw a Doorway'}
                      placement="bottom"
                      target={
                        <Button
                          size="medium"
                          type="hollow"
                          active={Boolean(
                            state.placementMode &&
                              state.placementMode.type === 'threshold'
                          )}
                          disabled={
                            state.locked ||
                            !state.planning.showSensors ||
                            isWaffleSensor
                          }
                          onClick={() => {
                            dispatch({ type: 'menu.addThreshold' });
                          }}
                          data-cy="sensors-focused-panel-draw-threshold"
                          trailingIcon={<Icons.PencilEditor size={18} />}
                        >
                          {'Draw '}
                        </Button>
                      }
                    />
                  </>
                )}
              </div>
            </div>

            {relatedThresholds.map((threshold) => (
              <div
                className={styles.objectsPanelActions}
                style={{
                  borderBottom: '0px',
                  paddingBottom: '12px',
                  paddingLeft: '5px',
                }}
              >
                <div className={styles.objectsPanelActionsTitle}>
                  <Button
                    size="small"
                    type="link"
                    onClick={() => {
                      dispatch({
                        type: 'item.menu.mousedown',
                        itemType: 'threshold',
                        itemId: threshold.id,
                      });
                    }}
                    data-cy="unlink-space-from-threshold"
                    leadingIcon={<Icons.DoorEntry size={18} />}
                    onMouseEnter={() => {
                      dispatch({
                        type: 'menu.focusObjectViaLink',
                        objectId: threshold.id,
                      });
                    }}
                    onMouseLeave={() => {
                      dispatch({
                        type: 'menu.unfocusLinkedObject',
                      });
                    }}
                  >
                    &nbsp;&nbsp;
                    {threshold.name}
                  </Button>
                </div>

                <HorizontalForm size="small">
                  <Tooltip
                    contents={'Link Space to Doorway'}
                    placement="bottom"
                    target={
                      <Button
                        size="small"
                        type="hollow"
                        active={state.selectionMode.active}
                        disabled={state.locked || isWaffleSensor}
                        onClick={() => {
                          dispatch({
                            type: 'selectionMode.begin',
                            expectedSelectionType: 'space',
                            hideAllLayersBut: ['thresholds', 'entrySpaces'],
                            initialActionCreator: threshold['id'],
                            pendingAction: 'threshold.linkSpace',
                          });
                        }}
                        data-cy="link-space-to-threshold"
                        trailingIcon={<Icons.Space size={18} />}
                      />
                    }
                  />

                  <Tooltip
                    contents={'Unlink from Doorway'}
                    placement="bottom"
                    target={
                      <Button
                        size="small"
                        type="hollow"
                        disabled={state.locked || isWaffleSensor}
                        onClick={() => {
                          onUnlinkFromThreshold(threshold['id']);
                        }}
                        data-cy="unlink-threshold-from-sensor"
                        trailingIcon={<Icons.LinkBroken size={18} />}
                      />
                    }
                  />
                </HorizontalForm>
              </div>
            ))}
          </>
        ) : null}

        {/* .sensorRotationFormGroup */}
        {sensor.type === 'oa' && (
          <div
            className={styles.boundingBoxRow}
            data-cy="sensors-focused-panel-bounding-box-filter"
          >
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelIcon}>
                <Icons.Filter size={16} />
              </div>
              <div>Bounding Box Filter</div>
              <Tooltip
                contents="Ignore data outside the bounds of the space this sensor is within."
                target={
                  <div
                    css={css`
                      margin-left: 5px;
                      padding-top: 4px;
                    `}
                  >
                    <HelpQuestionMark
                      height={12}
                      width={12}
                      color={dust.Gray400}
                    />
                  </div>
                }
              />
            </div>
            <div className={styles.settingsMenuRowBoundingBox}>
              <Switch
                onChange={(event) =>
                  onChangeBoundingBoxFilter(
                    event.target.checked ? 'cloud' : 'none'
                  )
                }
                isChecked={sensor.boundingBoxFilter === 'cloud'}
                isDisabled={state.locked || sensor.locked || isWaffleSensor}
              />
            </div>
          </div>
        )}

        {isValidationEnabled ? (
          <ValidationsList validations={sensorValidations} />
        ) : null}

        <NotesBox
          notes={sensor.notes}
          disabled={state.locked || sensor.locked || isWaffleSensor}
          onNotesEdited={(notes) => onChangeNotes(notes)}
        />

        <div className={styles.sensorConfigMeta}>
          <div
            className={styles.sensorConfigMetaStatus}
            style={{ color: getSensorStatusColor(sensor.status) }}
          >
            &bull;{' '}
            <span className={styles.sensorConfigMetaStatusText}>
              {sensor.serialNumber
                ? sensor.status
                  ? sensorStatusDisplayName(sensor.status)
                  : 'Unconfigured'
                : 'Unassigned'}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>
              <Icons.HealthBeatHeart size={12} />:
            </span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {lastHeartbeat}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>MAC:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {sensor.mac || '--'}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>IPv4:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {sensor.ipv4 || '--'}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>IPv6:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {sensor.ipv6 || '--'}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>OS:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {sensor.os || '--'}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>Height:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {displayLength(sensor.height, state.displayUnit)}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>ID:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {sensor.id}
            </span>
          </div>
          {sensor.type === 'oa' && (
            <Fragment>
              <div className={styles.sensorConfigMetaRow}>
                <span className={styles.metaLabel}>Major:</span>
                <span className={classNames(styles.muted, styles.metaDetail)}>
                  {major}
                </span>
              </div>
              <div className={styles.sensorConfigMetaRow}>
                <span className={styles.metaLabel}>Minor:</span>
                <span className={classNames(styles.muted, styles.metaDetail)}>
                  {minor}
                </span>
              </div>

              <div className={styles.sensorConfigMetaRow}>
                <span className={styles.metaLabel}>Area:</span>
                <span className={`${styles.muted} ${styles.metaDetail}`}>
                  {coverageArea}
                </span>
              </div>
            </Fragment>
          )}
        </div>
        {/* .sensorConfigMeta */}
        {sensor.sensorFunction === 'oaLongRange' &&
        sensor.clippedFOV.length > 0 ? (
          <span
            style={{
              fontSize: '0.8em',
            }}
          >
            {sensor.doorwayClippedFOV.length !== sensor.clippedFOV.length &&
            sensor.doorwayClippedFOV.length > 0
              ? 'FOV clipped by a doorway'
              : 'FOV not clipped by a doorway'}
          </span>
        ) : null}
        {sensor.type === 'oa' && (
          <div style={{ paddingBottom: sensorIsLinked ? '8px' : '0px' }}>
            <Tooltip
              contents={'Trim FOV to Neighoring Walls'}
              placement="bottom"
              width="100%"
              target={
                <button
                  className={classNames([styles.buttonSmall, styles.secondary])}
                  style={{ width: '100%' }}
                  disabled={sensor.locked || state.locked || isWaffleSensor}
                  onClick={() => {
                    FloorplanAPI.clipFieldOfView(client, planId, sensor['id'])
                      .then((response) => {
                        if (response.status === 200) {
                          toast.success(
                            `Successfully generated a clipped FOV for ${
                              sensor.serialNumber || sensor.cadId
                            }!`
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(
                          'Something went wrong while clipping the field of view.'
                        );
                        console.error(err);
                        toast.error(
                          `Clipping of FOV failed. Inspect console logs for details.`
                        );
                      });
                  }}
                >
                  <Icons.ScissorsCut size={18} />
                  &nbsp; Clip Field of View
                </button>
              }
            />
          </div>
        )}

        {['oaLongRange', 'oaShortRange', 'oaMidRange', 'openEntry'].includes(
          sensor.sensorFunction
        ) &&
          isNoisePolygonsButtonEnabled && (
            <div style={{ paddingBottom: sensorIsLinked ? '8px' : '0px' }}>
              <Tooltip
                contents={
                  sensor.serialNumber
                    ? 'Generate Noise Polygons'
                    : 'Generate Noise Polygons (link a serial number to unlock this action)'
                }
                placement="bottom"
                width="100%"
                target={
                  <button
                    className={classNames([
                      styles.buttonSmall,
                      styles.secondary,
                    ])}
                    style={{ width: '100%' }}
                    disabled={
                      sensor.locked || !sensor.serialNumber || isWaffleSensor
                    }
                    onClick={() => {
                      FloorplanAPI.generateNoiseFilter(
                        client,
                        sensor['serialNumber']
                      )
                        .then((response) => {
                          if (response.status === 200) {
                            toast.success(
                              `Successfully scheduled noise filter generation for ${sensor.serialNumber}. This job may take up to a couple minutes.`,
                              { autoClose: 6000 }
                            );
                          }
                        })
                        .catch((err) => {
                          console.error(
                            'Something went wrong while generating noise polygons.'
                          );
                          console.error(err);
                          toast.error(
                            `Generating noise filters failed. Inspect console logs for details.`
                          );
                        });
                    }}
                  >
                    <Icons.CleanMagicSparkle size={18} />
                    &nbsp; Filter Static Noise
                  </button>
                }
              />
            </div>
          )}

        {sensorIsLinked &&
        (sensor.type === 'oa' || sensor.sensorFunction === 'openEntry') ? (
          <Fragment>
            {!isHideIndividualSensorStreamingEnabled ? (
              <div
                className={styles.sensorStreamingControl}
                data-cy="sensors-focused-panel-streaming-controls"
              >
                <label className={classNames([styles.large])}>
                  Streaming:
                  <span
                    className={classNames([
                      styles.sensorStreamingStatus,
                      styles[sensorStreamingStatus],
                    ])}
                  >
                    {sensorStreamingStatus}
                  </span>
                </label>

                {sensorStreamingStatus !== 'connected' ? (
                  <button
                    className={classNames([
                      styles.buttonSmall,
                      styles.buttonIcon,
                      styles.link,
                      styles.dark,
                      styles.sensorConnectionButton,
                    ])}
                    onClick={() => {
                      dispatch({
                        type: 'sensor.menu.connect',
                        id: sensor.id,
                        serialNumber: serialNumberText,
                      });
                    }}
                    data-cy="sensors-focused-panel-streaming-connect"
                  >
                    <Icons.LightningOutline size={18} />
                  </button>
                ) : (
                  <button
                    className={classNames([
                      styles.buttonSmall,
                      styles.buttonIcon,
                      styles.link,
                      styles.sensorConnectionButton,
                    ])}
                    onClick={() => {
                      dispatch({
                        type: 'sensor.menu.disconnect',
                        id: sensor.id,
                      });
                    }}
                    data-cy="sensors-focused-panel-streaming-disconnect"
                  >
                    <Icons.LightningFill size={18} color={dust.Yellow400} />
                  </button>
                )}
              </div>
            ) : null}
            {PlanSensor.supportsLocate(sensor) ? (
              <Tooltip
                contents={"Blink Sensor's LED"}
                placement="bottom"
                width="100%"
                target={
                  <button
                    className={classNames([
                      styles.buttonSmall,
                      styles.secondary,
                    ])}
                    style={{ width: '100%' }}
                    disabled={locateButtonDisabled}
                    onClick={() => {
                      // This should never happen (this case is already guarded against above), it's just
                      // here to make typescript happy
                      if (!sensor.serialNumber) {
                        return;
                      }

                      // Disable the button so that someone can't click the button over and over rapidly
                      setLocateButtonDisabled(true);

                      CoreAPI.locateSensor(client, sensor.serialNumber)
                        .then(() => {
                          toast.success('Sensor LED blinking...');

                          // Re-enable the button after 3 seconds
                          setTimeout(() => {
                            setLocateButtonDisabled(false);
                          }, 3000);
                        })
                        .catch((err) => {
                          toast.error('Error locating sensor!');
                          // Re-enable the button instantly if an error occurs
                          setLocateButtonDisabled(false);
                        });
                    }}
                  >
                    <Icons.AccuracyTarget size={18} />
                    &nbsp; Locate
                  </button>
                }
              />
            ) : (
              <Tooltip
                width="100%"
                target={
                  <button
                    className={classNames([
                      styles.buttonSmall,
                      styles.disabled,
                      styles.secondary,
                    ])}
                    style={{ width: '100%' }}
                  >
                    <Icons.AccuracyTarget size={18} />
                    &nbsp; Locate
                  </button>
                }
                contents="This sensor isn't running a new enough software version."
              />
            )}
            {/* .sensorStreamingControl */}
          </Fragment>
        ) : null}
        {/* .sensorStreamingControl */}
      </PanelBody>

      <PanelActions
        left={
          <HorizontalForm size="medium">
            <Button
              type="cleared"
              size="medium"
              onClick={() => onDelete()}
              disabled={state.locked || sensor.locked || isWaffleSensor}
              trailingIcon={<Icons.Trash size={18} />}
              danger
              data-cy="sensors-focused-panel-delete"
            />
            <Button
              type="cleared"
              size="medium"
              onClick={() => onChangeLocked(!sensor.locked)}
              disabled={state.locked || isWaffleSensor}
              data-cy={
                sensor.locked
                  ? 'sensors-focused-panel-unlock'
                  : 'sensors-focused-panel-lock'
              }
              trailingIcon={
                sensor.locked ? (
                  <Icons.LockClosed
                    size={18}
                    color={!state.locked ? dust.Yellow400 : 'currentColor'}
                  />
                ) : (
                  <Icons.LockOpen size={18} />
                )
              }
            />
            <Tooltip
              contents={'Duplicate This Sensor'}
              placement="bottom"
              target={
                <Button
                  className={styles.destructiveButton}
                  size="medium"
                  type="hollow"
                  onClick={onDuplicate}
                  trailingIcon={<Icons.CopyPlusDuplicate size={18} />}
                  data-cy="sensors-focused-panel-duplicate"
                  disabled={state.locked || isWaffleSensor}
                />
              }
            />
          </HorizontalForm>
        }
        right={
          <div>
            <Button
              type="cleared"
              size="medium"
              onClick={() =>
                dispatch({ type: 'sensor.dismiss', id: sensor.id })
              }
              data-cy="sensors-dismiss"
            >
              Done
            </Button>
          </div>
        }
      />
    </Panel>
  );
};

export default FocusedSensorPanel;

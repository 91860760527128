import { AxiosInstance } from 'axios';
import { FloorplanV2Plan } from 'lib/api';
import Space from 'lib/space';
import { State } from './state';
import { Action } from './actions';

/**
 * Updates waffle sensors after a space is moved
 */
export const updateWaffleSensors = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  dispatch: (action: Action) => void,
  spaceId: Space['id']
) => {
  // Get the space to check if it's a waffle space
  const space = state.spaces.items.get(spaceId);
  if (!space || space.countingMode !== 'waffle') return;

  try {
    // Wait a small delay for backend to process the space update
    await new Promise((resolve) => setTimeout(resolve, 300));

    // Fetch updated waffle sensors
    const response = await client.get(`v2/floorplans/${planId}/waffle-sensors`);

    if (response.data && response.data.results) {
      // Find matching sensors in the state by serial number
      response.data.results.forEach((waffle: any) => {
        const serialNumber = waffle.serial_number;

        if (!serialNumber) return;

        // Look for a sensor with this serial number in the state
        const matchingSensor = Array.from(
          state.planSensors.items.values()
        ).find((sensor) => sensor.serialNumber === serialNumber);

        if (matchingSensor) {
          dispatch({
            type: 'item.graphic.dragmove',
            itemType: 'sensor',
            itemId: matchingSensor.id,
            itemPosition: {
              type: 'floorplan-coordinates',
              x: waffle.centroid_from_origin_x_meters,
              y: waffle.centroid_from_origin_y_meters,
            },
          });
        }
      });
    }
  } catch (error) {
    console.error('Failed to update waffle sensors:', error);
  }
};

import { Fragment, useEffect, useRef, useCallback, useMemo } from 'react';
import * as PIXI from 'pixi.js';
import {
  ViewportCoordinates,
  FloorplanCoordinates,
  snapToAngle,
} from 'lib/geometry';

import FloorplanCollection from 'lib/floorplan-collection';
import AreaOfConcern from 'lib/area-of-concern';
import PlanSensor from 'lib/sensor';
import Space from 'lib/space';
import Reference from 'lib/reference';
import Threshold from 'lib/threshold';
import WallSegment from 'lib/wall-segment';
import { LengthUnit } from 'lib/units';

import RenderScalingLayer, { ScalingState } from './render-scaling-layer';
import RenderMovingLayer, { MovingState } from './render-moving-layer';
import RenderSelectingLayer, { SelectingState } from './render-selecting-layer';

import { LineSegment } from 'components/walls-editor/line-segment-import';
import {
  Layer,
  MetricLabel,
  useFloorplanLayerContext,
  toRawHex,
} from 'components/floorplan';

import { Gray700 } from '@density/dust/dist/tokens/dust.tokens';

const PLACEMENT_TOOLTIP_OFFSET_X_PX = 16;
const SNAP_POINT_PROXIMITY = 0.5;

const computeText = (state: ActionState): string => {
  // The text near user's mouse needs to be dynamic, base don
  // how for into the process they are.

  let placementText: string = '';

  switch (state.type) {
    case 'scale': {
      if (!state.rulerA.vertexA) {
        placementText = 'Place 1st Vertex on Imported Entity';
      } else if (!state.rulerA.vertexB) {
        placementText = 'Finish the Line on Imported Entity';
      } else if (!state.rulerB.vertexA) {
        placementText = 'Place 1st Vertex on Existing Floorplan Image';
      } else {
        placementText = 'Finish the Floorplan Image Line';
      }
      break;
    }
  }
  return placementText;
};

export type ActionState = ScalingState | MovingState | SelectingState;

// TODO: Break up these props into distinct types?
const SupplementaryActionsLayer: React.FunctionComponent<{
  type: 'move' | 'select' | 'scale';
  displayUnit?: LengthUnit;
  sensors?: FloorplanCollection<PlanSensor>;
  thresholds?: FloorplanCollection<Threshold>;
  spaces?: FloorplanCollection<Space>;
  areasOfConcern?: FloorplanCollection<AreaOfConcern>;
  walls?: FloorplanCollection<WallSegment>;
  references?: FloorplanCollection<Reference>;
  wallLineSegments?: Pick<LineSegment, 'positionA' | 'positionB'>[];
  onMovingCompleted?: (positionShift: {
    translationX: number;
    translationY: number;
  }) => void;
  onScalingCompleted?: (scaleAndShift: {
    scale: number;
    rotation: number;
    translationX: number;
    translationY: number;
  }) => void;
}> = ({
  type,
  displayUnit,
  sensors,
  thresholds,
  spaces,
  areasOfConcern,
  walls,
  references,
  wallLineSegments,
  onMovingCompleted,
  onScalingCompleted,
}) => {
  const context = useFloorplanLayerContext();
  const mousePositionRef = useRef<ViewportCoordinates | null>(null);

  // Create a state locally so that state updates can be made quicker and not have
  // to go through the whole flux loop within the editor.
  const actionStateRef = useRef<ActionState | null>(null);

  // Globally add a way that cypress can interrogate the bulk layer placement type ref
  // This is used by the planning tab "page object" in the cypress tests...
  if (
    process.env.REACT_APP_ENABLE_EDITOR_GET_STATE &&
    process.env.REACT_APP_ENABLE_EDITOR_GET_STATE.toLowerCase() === 'true'
  ) {
    (window as any).editorGetPlacementModeRef = () => actionStateRef;
  }

  const onMouseDownCanvasRef = useRef<((evt: MouseEvent) => void) | null>(null);
  const onMouseMoveCanvasRef = useRef<((evt: MouseEvent) => void) | null>(null);
  const onMouseOutCanvasRef = useRef<((evt: MouseEvent) => void) | null>(null);

  // Aggregate all possible snap points when bulk moving objects -
  const potentialSnapPoints: FloorplanCoordinates[] = useMemo(() => {
    const points: FloorplanCoordinates[] = [];

    if (sensors) {
      sensors.items.forEach((sensor) => {
        points.push(sensor.position);
      });
    }

    if (thresholds) {
      thresholds.items.forEach((threshold) => {
        points.push(...[threshold.positionA, threshold.positionB]);
      });
    }

    if (references) {
      references.items.forEach((reference) => {
        if (reference.type === 'ruler') {
          points.push(...[reference.positionA, reference.positionB]);
        }
      });
    }

    if (walls) {
      walls.items.forEach((wall) => {
        points.push(...[wall.positionA, wall.positionB]);
      });
    }

    if (spaces) {
      spaces.items.forEach((space) => {
        points.push(space.position);
        if (space.shape.type === 'box') {
          const halfWidth = space.shape.width / 2;
          const halfHeight = space.shape.height / 2;
          points.push(
            ...[
              FloorplanCoordinates.create(
                space.position.x + halfWidth,
                space.position.y + halfHeight
              ),
              FloorplanCoordinates.create(
                space.position.x - halfWidth,
                space.position.y + halfHeight
              ),
              FloorplanCoordinates.create(
                space.position.x + halfWidth,
                space.position.y - halfHeight
              ),
              FloorplanCoordinates.create(
                space.position.x - halfWidth,
                space.position.y - halfHeight
              ),
            ]
          );
        } else if (space.shape.type === 'polygon') {
          points.push(...space.shape.vertices);
        }
      });
    }

    if (areasOfConcern) {
      areasOfConcern.items.forEach((areaOfConcern) => {
        points.push(...[...areaOfConcern.vertices]);
      });
    }

    return points;
  }, [sensors, thresholds, spaces, areasOfConcern, references, walls]);

  const findClosestSnapPoint = (
    potentialSnapPoints: FloorplanCoordinates[],
    mousePosition: FloorplanCoordinates
  ) => {
    // Given an array of FloorplanCoordinates, find the closest one
    // to the passed mousePosition.

    if (potentialSnapPoints.length === 0) {
      return [null, null] as const;
    }

    const closestSnapPoint: FloorplanCoordinates = potentialSnapPoints.reduce(
      (prev, curr) => {
        const distanceCurrSquared =
          Math.pow(mousePosition.y - curr.y, 2) +
          Math.pow(mousePosition.x - curr.x, 2);
        const distancePrevSquared =
          Math.pow(mousePosition.y - prev.y, 2) +
          Math.pow(mousePosition.x - prev.x, 2);
        return distanceCurrSquared < distancePrevSquared ? curr : prev;
      },
      potentialSnapPoints[0]
    );

    const distanceToSnapPoint = Math.hypot(
      Math.abs(mousePosition.y - closestSnapPoint.y),
      Math.abs(mousePosition.x - closestSnapPoint.x)
    );

    return [closestSnapPoint, distanceToSnapPoint] as const;
  };

  const onMouseMove = useCallback(
    (latestMousePosition: FloorplanCoordinates, evt: MouseEvent) => {
      if (!context.viewport.current) {
        return;
      } else if (!actionStateRef.current) {
        return;
      } else if (!latestMousePosition) {
        return;
      }

      switch (actionStateRef.current.type) {
        case 'move': {
          let nextPlacement = latestMousePosition; // by default
          if (actionStateRef.current.vertexA && evt.shiftKey) {
            // Placing second point, when `Shift` held, snap to angles.
            nextPlacement = snapToAngle(
              actionStateRef.current.vertexA,
              latestMousePosition
            );
          } else {
            // Placing first or second point, snap to nearby vertices & centers...
            const [closestSnapPoint, distToSnapPoint] = findClosestSnapPoint(
              potentialSnapPoints,
              latestMousePosition
            );

            // ...if we are close enough.
            if (
              closestSnapPoint &&
              distToSnapPoint !== null &&
              distToSnapPoint <= SNAP_POINT_PROXIMITY
            ) {
              nextPlacement = closestSnapPoint;
            }
          }

          // NOTE 1: Snapping is disabled when 'Shift' is held.
          // NOTE 2: When no 'Shift' is held and no suitable snap point found, mousePosition => nextPlacement.

          actionStateRef.current = {
            ...actionStateRef.current,
            nextPlacement: nextPlacement,
          };
          break;
        }
        case 'scale': {
          let nextPlacement = latestMousePosition; // Default placement

          if (
            evt.shiftKey &&
            ((actionStateRef.current.rulerA.vertexA &&
              !actionStateRef.current.rulerA.vertexB) ||
              (actionStateRef.current.rulerB.vertexA &&
                !actionStateRef.current.rulerB.vertexB))
          ) {
            // When placing the second point for either ruler and 'Shift' is held, snap to angles.
            const positionX =
              actionStateRef.current.rulerB.vertexA ||
              actionStateRef.current.rulerA.vertexA;
            nextPlacement = snapToAngle(positionX!, latestMousePosition);
          } else {
            // Placing the first or second point on either ruler, fing the nearby vertex/center.
            const [closestSnapPoint, distToSnapPoint] = findClosestSnapPoint(
              potentialSnapPoints,
              latestMousePosition
            );

            // If we are close enough to a snap point, update nextPlacement to snapPoint.
            if (
              closestSnapPoint &&
              distToSnapPoint !== null &&
              distToSnapPoint <= SNAP_POINT_PROXIMITY
            ) {
              nextPlacement = closestSnapPoint;
            }
          }

          // NOTE 1: Snapping is disabled when 'Shift' is held.
          // NOTE 2: When no 'Shift' is held and no suitable snap point found, mousePosition => nextPlacement.

          // Update the state.
          actionStateRef.current = {
            ...actionStateRef.current,
            nextPlacement: nextPlacement,
          };
          break;
        }
      }
    },
    [context.viewport, potentialSnapPoints]
  );

  const onMouseDown = useCallback(
    (latestMousePosition: FloorplanCoordinates, evt: MouseEvent) => {
      if (!actionStateRef.current?.nextPlacement) {
        // This shouldn't ever be the case as we default nextPlacement to mousePosition
        // if there is no snap or translation override.
        return;
      }

      switch (actionStateRef.current.type) {
        case 'move': {
          // Here we handle placing of first and second move reference points.
          if (actionStateRef.current.vertexA === null) {
            // If starting position is not set, set it on the first click -
            actionStateRef.current = {
              ...actionStateRef.current,
              vertexA: actionStateRef.current.nextPlacement,
            };
          } else if (actionStateRef.current.vertexB === null) {
            // Second move reference placed, this concludes the action,
            // we're going to translation all selected entities by these values.
            actionStateRef.current = {
              ...actionStateRef.current,
              vertexB: actionStateRef.current.nextPlacement,
            };
          }

          if (
            actionStateRef.current?.vertexA &&
            actionStateRef.current?.vertexB
          ) {
            // We have everything we need here, let's bail.
            const translationX =
              actionStateRef.current.vertexB.x -
              actionStateRef.current.vertexA.x;
            const translationY =
              actionStateRef.current.vertexB.y -
              actionStateRef.current.vertexA.y;

            if (onMovingCompleted) {
              onMovingCompleted({ translationX, translationY });
            }
          }
          break;
        }

        case 'scale': {
          // Determine which ruler and vertex are being set -
          const rulerKey = !actionStateRef.current.rulerA.vertexB
            ? 'rulerA'
            : 'rulerB';

          // Update the state based on the determined ruler and vertex -
          // rulerA.vertexA -> rulerA.vertexB -> rulerB.vertexA -> rulerB.vertexB
          actionStateRef.current = {
            ...actionStateRef.current,
            [rulerKey]: {
              ...actionStateRef.current[rulerKey],
              [actionStateRef.current[rulerKey].vertexA
                ? 'vertexB'
                : 'vertexA']:
                actionStateRef.current.nextPlacement || latestMousePosition,
            },
          };

          if (actionStateRef.current.rulerB.vertexB) {
            // We have all 4 vertices here.
            const rulerA = actionStateRef.current.rulerA;
            const rulerB = actionStateRef.current.rulerB;
            if (
              !rulerA.vertexA ||
              !rulerA.vertexB ||
              !rulerB.vertexA ||
              !rulerB.vertexB
            ) {
              return; // Just satisfying the TS OCD here...
            }

            // Scale calculation
            const lengthLineA = Math.hypot(
              rulerA.vertexA.x - rulerA.vertexB.x,
              rulerA.vertexA.y - rulerA.vertexB.y
            );
            const lengthLineB = Math.hypot(
              rulerB.vertexA.x - rulerB.vertexB.x,
              rulerB.vertexA.y - rulerB.vertexB.y
            );
            const scale = lengthLineA / lengthLineB;

            // Rotation calculation
            const dxA = rulerA.vertexB.x - rulerA.vertexA.x;
            const dyA = rulerA.vertexB.y - rulerA.vertexA.y;
            const dxB = rulerB.vertexB.x - rulerB.vertexA.x;
            const dyB = rulerB.vertexB.y - rulerB.vertexA.y;
            const rotationRadians = Math.atan2(dyB, dxB) - Math.atan2(dyA, dxA);
            const rotation = (rotationRadians * 180) / Math.PI;

            // Translation
            const translationX =
              rulerB.vertexA.x -
              ((rulerA.vertexA.x / scale) * Math.cos(rotationRadians) -
                (rulerA.vertexA.y / scale) * Math.sin(rotationRadians));
            const translationY =
              rulerB.vertexA.y -
              ((rulerA.vertexA.x / scale) * Math.sin(rotationRadians) +
                (rulerA.vertexA.y / scale) * Math.cos(rotationRadians));

            if (onScalingCompleted) {
              onScalingCompleted({
                scale,
                rotation,
                translationX,
                translationY,
              });
            }
          }
          break;
        }
      }
    },
    [onMovingCompleted, onScalingCompleted]
  );

  const onPlacementModeActivation = useCallback(() => {
    if (!actionStateRef.current || !context.viewport.current) {
      return;
    }

    const canvasElement = context.app.view;
    const canvasBBox = canvasElement.getBoundingClientRect();

    // Register mouse handlers -
    const onMouseDownCanvas = (evt: MouseEvent) => {
      if (!context.viewport.current) {
        return;
      }

      const viewportCoords = ViewportCoordinates.create(
        evt.clientX - canvasBBox.x,
        evt.clientY - canvasBBox.y
      );

      const latestMousePosition = ViewportCoordinates.toFloorplanCoordinates(
        viewportCoords,
        context.viewport.current,
        context.floorplan
      );

      onMouseDown(latestMousePosition, evt);
    };

    const onMouseMoveCanvas = (evt: MouseEvent) => {
      if (!context.viewport.current) {
        return;
      }

      mousePositionRef.current = ViewportCoordinates.create(
        evt.clientX - canvasBBox.x,
        evt.clientY - canvasBBox.y
      );

      const latestMousePosition = ViewportCoordinates.toFloorplanCoordinates(
        mousePositionRef.current,
        context.viewport.current,
        context.floorplan
      );

      onMouseMove(latestMousePosition, evt);
    };

    const onMouseOutCanvas = () => {
      mousePositionRef.current = null;
    };

    canvasElement.addEventListener('mousedown', onMouseDownCanvas);
    canvasElement.addEventListener('mousemove', onMouseMoveCanvas);
    canvasElement.addEventListener('mouseout', onMouseOutCanvas);
    onMouseDownCanvasRef.current = onMouseDownCanvas;
    onMouseMoveCanvasRef.current = onMouseMoveCanvas;
    onMouseOutCanvasRef.current = onMouseOutCanvas;

    // Hint that travels with user's mouse.
    const placementHintLabel = new MetricLabel('', {
      backgroundColor: toRawHex(Gray700),
      pinHorizontal: 'start',
      textStyle: new PIXI.TextStyle({
        fontFamily: 'Arial',
        fontSize: 14,
        fontWeight: 'bold',
        fill: '#ffffff',
      }),
      horizontalPaddingPixels: 16,
      verticalPaddingPixels: 9,
      radiusPixels: 32,
    });
    placementHintLabel.name = 'placement-hint-label';
    context.app.stage.addChild(placementHintLabel);

    // A transparent backdrop is rendered overtop of the whole stage
    // to ensure that we have control over the mouse position
    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.name = 'bulk-action-backdrop';
    backdrop.width = context.viewport.current.width;
    backdrop.height = context.viewport.current.height;
    backdrop.alpha = 0;
    backdrop.interactive = true;
    backdrop.cursor = 'copy';
    context.app.stage.addChild(backdrop);
  }, [
    onMouseDown,
    onMouseMove,
    context.app.stage,
    context.app.view,
    context.floorplan,
    context.viewport,
  ]);

  const onPlacementModeDeactivation = useCallback(() => {
    const backdrop = context.app.stage.getChildByName(
      'bulk-action-backdrop'
    ) as PIXI.Sprite | null;

    if (backdrop) {
      context.app.stage.removeChild(backdrop);
    }

    const placementHintLabel = context.app.stage.getChildByName(
      'placement-hint-label'
    ) as MetricLabel | null;

    if (placementHintLabel) {
      context.app.stage.removeChild(placementHintLabel);
    }

    mousePositionRef.current = null;

    const canvasElement = context.app.view;
    if (onMouseDownCanvasRef.current) {
      canvasElement.removeEventListener(
        'mousedown',
        onMouseDownCanvasRef.current
      );
    }
    if (onMouseMoveCanvasRef.current) {
      canvasElement.removeEventListener(
        'mousemove',
        onMouseMoveCanvasRef.current
      );
    }
    if (onMouseOutCanvasRef.current) {
      canvasElement.removeEventListener(
        'mouseout',
        onMouseOutCanvasRef.current
      );
    }
  }, [context.app.stage, context.app.view]);

  useEffect(() => {
    if (actionStateRef.current && actionStateRef.current.type === type) {
      // We're still in the midst of an action.
      // Bail, so not to reset the current state.
      return;
    } else if (!actionStateRef.current) {
      onPlacementModeActivation();
    }

    // If the action types differ, then update the state.
    actionStateRef.current = null;
    onPlacementModeDeactivation();

    switch (type) {
      case 'scale': {
        actionStateRef.current = {
          type: type,
          rulerA: {
            vertexA: null,
            vertexB: null,
          },
          rulerB: {
            vertexA: null,
            vertexB: null,
          },
          nextPlacement: null,
        };
        break;
      }

      case 'move': {
        actionStateRef.current = {
          type: type,
          vertexA: null,
          vertexB: null,
          nextPlacement: null,
        };
        break;
      }
      case 'select': {
        actionStateRef.current = {
          type: type,
          vertexA: null,
          vertexB: null,
          nextPlacement: null,
        };
        break;
      }
    }

    onPlacementModeActivation();
  }, [type, onPlacementModeActivation, onPlacementModeDeactivation]);

  useEffect(() => {
    return () => onPlacementModeDeactivation();
  }, [onPlacementModeDeactivation]);

  return (
    <Fragment>
      <Layer
        onAnimationFrame={() => {
          if (!actionStateRef.current?.type) {
            return;
          }
          // Add a tooltip here if desired.
          const placementHintLabel = context.app.stage.getChildByName(
            'placement-hint-label'
          ) as MetricLabel | undefined;

          if (!placementHintLabel) {
            return;
          }

          const placementText = computeText(actionStateRef.current);

          // If the mouse is no longer on the canvas, then hide the label
          if (!placementText || !mousePositionRef.current) {
            placementHintLabel.visible = false;
            return;
          }

          placementHintLabel.visible = true;
          placementHintLabel.x =
            mousePositionRef.current.x + PLACEMENT_TOOLTIP_OFFSET_X_PX;
          placementHintLabel.y = mousePositionRef.current.y;
          placementHintLabel.setText(placementText);
        }}
      />

      {type === 'move' && displayUnit ? (
        <RenderMovingLayer
          movingStateRef={actionStateRef as React.RefObject<MovingState>}
          displayUnit={displayUnit}
        />
      ) : null}
      {type === 'scale' ? (
        <RenderScalingLayer
          scalingStateRef={actionStateRef as React.RefObject<ScalingState>}
        />
      ) : null}
      {type === 'select' ? (
        <RenderSelectingLayer
          selectingStateRef={actionStateRef as React.RefObject<SelectingState>}
        />
      ) : null}
    </Fragment>
  );
};
export default SupplementaryActionsLayer;

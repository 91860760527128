import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { APP_ENV, NODE_ENV, SENTRY_DSN, VERSION } from 'config';

if (NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,

    integrations: [new BrowserTracing()],

    environment: APP_ENV,
    release: VERSION,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    maxValueLength: 5000,

    // https://docs.sentry.io/platforms/javascript/data-management/event-grouping/sdk-fingerprinting/
    beforeSend: (event) => {
      return event;
    },
  });
}

export class ErrorReporting {
  static identify(user: {
    id: string;
    email: string;
    name: string;
    organizationId: string;
  }) {
    if (NODE_ENV === 'development') {
      return;
    }

    Sentry.setUser(user);
  }

  static report(
    error: any,
    severity: 'info' | 'warning' | 'error' | 'fatal' = 'error',
    metadata: {
      [key: string]: any;
    } = {}
  ) {
    if (NODE_ENV === 'development') {
      if (severity === 'error' || severity === 'fatal') {
        console.error(error, metadata);
      } else if (severity === 'warning') {
        console.warn(error, metadata);
      } else {
        console.info(error, metadata);
      }
      return;
    }

    if (typeof error === 'string') {
      Sentry.captureMessage(error, {
        level: severity,
        extra: metadata,
      });
    } else {
      Sentry.captureException(error, {
        level: severity,
        extra: metadata,
      });
    }
  }
}

import {
  CoreSpace,
  CoreSpaceHierarchyNode,
  CoreSpaceTimeSegment,
  CoreSpaceType,
} from '@densityco/lib-api-types';
import { useParams } from 'react-router';
import styles from './styles.module.scss';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import FillCenter from 'components/fill-center';
import { useAppSelector } from 'redux/store';
import {
  CoreAPI,
  ENTITY_PAGE_SIZE,
  FloorplanAPI,
  FloorplanV2PlanSummary,
  FloorplanV2Sensor,
  WaffleSensor,
} from 'lib/api';
import { Icons } from '@density/dust';
import { Link } from 'react-router-dom';
import { AxiosInstance } from 'axios';
import { LoadingBar } from './loading-bar';
import { BuildingInfo } from './building-info';
import { FloorInfo } from './floor-info';
import BuildingSettingsModal from './building-settings-modal';
import { SpaceBuildingManager } from './spaces-info';
import { AOC } from './aoc-info';
import { BuildingContext, BuildingProvider } from './building-context';
import { SensorStatus } from 'lib/sensor';

export interface FloorData {
  name: string;
  status: string;
  image: string | null;
  id: string;
  updatedAt: string | null;
  createdAt: string;
  sensors: FloorplanV2Sensor[];
  spaces: SpaceBuildingManager[];
  aoc: AOC[];
}

export type BuildingEntityFloor = SpaceBuildingManager & {
  floorId: string;
  floorName: string;
};

export type BuildingEntitySensor = FloorplanV2Sensor & {
  floorId: string;
  floorName: string;
};

export type V2Building = {
  id: string;
  name: string;
  address: string | null;
  time_zone: string;
  has_purview: boolean;
  space_type: CoreSpaceType;
  daily_reset: string;
  inherits_time_segments: boolean;
  time_segments: Array<CoreSpaceTimeSegment>;
  capacity: number | null;
  children?: Array<CoreSpaceHierarchyNode>;
};

export type BuildingEntityData = {
  floors: FloorData[];
  sensors: BuildingEntitySensor[];
  spaces: BuildingEntityFloor[];
  aoc: AOC[];
};

const BuildingManager: React.FunctionComponent = () => {
  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const { buildingName, setBuildingName } = useContext(BuildingContext);

  const [floorData, setFloorData] = useState<{ status: string }>({
    status: 'pending',
  });
  const [totalEntities, setTotalEntities] = useState<number>(0);
  const [loadedSensors, setLoadedSensors] = useState<number>(0);
  const [loadedSpaces, setLoadedSpaces] = useState<number>(0);
  const [loadedAOC, setLoadedAOC] = useState<number>(0);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

  const [buildingData, setBuildingData] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | { status: 'error' }
    | {
        status: 'complete';
        data: CoreSpaceHierarchyNode;
      }
  >({ status: 'pending' });
  const { buildingId, organizationId } = useParams<{
    buildingId: CoreSpace['id'];
    organizationId: CoreSpace['id'];
  }>();
  const [floors, setFloors] = useState<FloorplanV2PlanSummary[] | undefined>();
  const [data, setData] = useState<BuildingEntityData | undefined>();

  // The first useEffect that runs when buildingId and densityAPIClient change
  useEffect(() => {
    if (!densityAPIClient || !buildingId) {
      return;
    }

    const abortController = new AbortController();

    // Set loading state
    setBuildingData({ status: 'loading', abortController });

    // First, fetch the space hierarchy data
    CoreAPI.spacesHierarchyWithinSpace(densityAPIClient, buildingId)
      .then((response) => {
        // Update building data
        setBuildingData({
          status: 'complete',
          data: response.data,
        });
        setBuildingName(response.data.name);

        // Then fetch floorplans
        return FloorplanAPI.listFloorplans(densityAPIClient);
      })
      .then((res) => {
        const floorplanData = res.data.results.filter(
          (floor) => floor.floor.parent_id === buildingId
        );
        setFloors(floorplanData);
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return;
        }
        setBuildingData({ status: 'error' });
      });

    return () => {
      abortController.abort();
      setBuildingData({ status: 'pending' });
    };
  }, [buildingId, densityAPIClient, setBuildingName]);

  // The second useEffect that runs only when floors becomes available
  useEffect(() => {
    if (
      !densityAPIClient ||
      !floors ||
      floors.length === 0 ||
      buildingData.status !== 'complete'
    ) {
      return;
    }

    // Define sensor fetching function
    async function fetchAllSensorsForFloor(
      client: AxiosInstance,
      floorId: string,
      floorName: string
    ): Promise<BuildingEntitySensor[]> {
      let page = 1;
      const sensors: BuildingEntitySensor[] = [];

      // Fetch regular sensors
      while (true) {
        try {
          const res = await FloorplanAPI.listSensors(client, floorId, page);
          const sensorData = res.data.results.map((sensor) => {
            return {
              ...sensor,
              mac: sensor.diagnostic_info?.mac,
              ip: sensor.diagnostic_info?.ipv4,
              floorId,
              floorName,
            };
          });
          setLoadedSensors(
            (prevLoaded) => prevLoaded + res.data.results.length
          );
          sensors.push(...sensorData);
          page++;
          if (!res.data.next) {
            setTotalEntities(
              (prevTotal) => prevTotal - ENTITY_PAGE_SIZE + res.data.total
            );
            break;
          }
        } catch (err) {
          break;
        }
      }

      // Fetch waffle sensors
      try {
        const waffleRes = await FloorplanAPI.listWaffleSensors(client, floorId);
        if (waffleRes.data.results && waffleRes.data.results.length > 0) {
          const waffleSensorData = waffleRes.data.results.map((waffle) => {
            // Convert WaffleSensor to FloorplanV2Sensor format
            const convertedSensor = {
              ...waffleToFloorplanSensor(waffle),
              floorId,
              floorName,
              // Safely access diagnostic_info properties
              mac:
                typeof waffle.diagnostic_info === 'object' &&
                waffle.diagnostic_info
                  ? ((waffle.diagnostic_info as Record<string, unknown>)
                      ?.mac as string)
                  : undefined,
              ip:
                typeof waffle.diagnostic_info === 'object' &&
                waffle.diagnostic_info
                  ? ((waffle.diagnostic_info as Record<string, unknown>)
                      ?.ipv4 as string)
                  : undefined,
            };
            return convertedSensor;
          });

          // Add waffle sensors to the total count
          setLoadedSensors(
            (prevLoaded) => prevLoaded + waffleSensorData.length
          );
          setTotalEntities((prevTotal) => prevTotal + waffleSensorData.length);

          // Add waffle sensors to the sensors array
          sensors.push(...waffleSensorData);
        }
      } catch (err) {
        console.error('Error fetching waffle sensors:', err);
      }

      return sensors;
    }

    // Helper function to convert WaffleSensor to FloorplanV2Sensor
    function waffleToFloorplanSensor(waffle: WaffleSensor): FloorplanV2Sensor {
      return {
        id: waffle.serial_number,
        sensor_serial_number: waffle.serial_number,
        sensor_type: 'waffle',
        sensor_function: 'w',
        locked: false,
        rotation: 0,
        centroid_from_origin_x_meters:
          waffle.centroid_from_origin_x_meters || 0,
        centroid_from_origin_y_meters:
          waffle.centroid_from_origin_y_meters || 0,
        height_meters: 0,
        last_heartbeat: null,
        status: waffle.status as SensorStatus,
        diagnostic_info: waffle.diagnostic_info || {},
        notes: '',
        plan_sensor_index: null,
        cad_id: '',
        bounding_box_filter: 'none',
      };
    }

    // Define spaces fetching function
    async function fetchAllSpacesForFloor(
      client: AxiosInstance,
      floorId: string,
      floorName: string
    ): Promise<BuildingEntityFloor[]> {
      let page = 1;
      const spaces: BuildingEntityFloor[] = [];
      while (true) {
        try {
          const res = await FloorplanAPI.listSpaces(client, floorId, page);
          const spacesData = res.data.results.map((space) => {
            return { ...space, floorId, floorName };
          }) as unknown as BuildingEntityFloor[];
          spaces.push(...spacesData);
          setLoadedSpaces((prevLoaded) => prevLoaded + res.data.results.length);
          if (!res.data.next) {
            setTotalEntities(
              (prevTotal) => prevTotal - ENTITY_PAGE_SIZE + res.data.total
            );
            break;
          }
          page++;
        } catch (err) {
          console.error(`Error fetching spaces for floor ${floorId}:`, err);
          break;
        }
      }
      return spaces;
    }

    // Define AOC fetching function
    async function fetchAllAOCForFloor(
      client: AxiosInstance,
      floorId: string,
      floorName: string,
      floorStatus: string
    ): Promise<AOC[] & BuildingEntityFloor[]> {
      let page = 1;
      const areasOfCoverage: AOC[] & BuildingEntityFloor[] = [];
      while (true) {
        try {
          const res = await FloorplanAPI.listAreasOfConcern(
            client,
            floorId,
            page
          );
          const aocData = res.data.results.map((aoc) => {
            return { ...aoc, floorId, floorName, floorStatus };
          }) as unknown as AOC[] & BuildingEntityFloor[];
          areasOfCoverage.push(...aocData);
          setLoadedAOC((prevLoaded) => prevLoaded + res.data.results.length);
          if (!res.data.next) {
            setTotalEntities(
              (prevTotal) => prevTotal - ENTITY_PAGE_SIZE + res.data.total
            );
            break;
          }
          page++;
        } catch (err) {
          console.error(`Error fetching AOC for floor ${floorId}:`, err);
          break;
        }
      }
      return areasOfCoverage;
    }

    const fetchData = async () => {
      // Reset counters
      setLoadedSpaces(0);
      setLoadedSensors(0);
      setLoadedAOC(0);

      const apiData: BuildingEntityData = {
        floors: [],
        sensors: [],
        spaces: [],
        aoc: [],
      };

      setTotalEntities(floors.length * ENTITY_PAGE_SIZE * 3); // 3 requests per floor

      // Process each floor
      for (const floor of floors) {
        const floorData: FloorData = {
          name: floor.floor.name,
          status: floor.floor.status,
          image: floor.image_url,
          id: floor.id,
          updatedAt: floor.updated_at,
          createdAt: floor.created_at,
          sensors: [],
          spaces: [],
          aoc: [],
        };

        // Fetch data in parallel for each floor
        const [sensorData, spaceData, aocData] = await Promise.all([
          fetchAllSensorsForFloor(densityAPIClient, floor.id, floor.floor.name),
          fetchAllSpacesForFloor(densityAPIClient, floor.id, floor.floor.name),
          fetchAllAOCForFloor(
            densityAPIClient,
            floor.id,
            floor.floor.name,
            floor.floor.status
          ),
        ]);

        // Process the fetched data
        floorData.sensors = floorData.sensors.concat(sensorData);
        apiData.sensors = apiData.sensors.concat(sensorData);

        floorData.spaces = floorData.spaces.concat(spaceData);
        apiData.spaces = apiData.spaces.concat(spaceData);

        apiData.aoc = apiData.aoc.concat(aocData);
        floorData.aoc = floorData.aoc.concat(aocData);

        apiData.floors.push(floorData);
      }

      // Finally set the data and mark as complete
      setData(apiData);
      setFloorData({ status: 'complete' });
    };

    // Fetch the data
    fetchData().catch((error) => {
      console.error('Error in fetchData:', error);
    });
  }, [floors, densityAPIClient, buildingData.status]);

  const updateSpace = useCallback(
    async (data) => {
      if (!densityAPIClient || !buildingId)
        return new Error('No client or building ID');
      if (data.address === '') data.address = null; // blank address must be null
      const res = await CoreAPI.updateSpace(densityAPIClient, buildingId, data);

      if (res.status !== 200) {
        return new Error('Error, bad request');
      } else {
        setBuildingName(res.data.name);
      }
    },
    [densityAPIClient, buildingId, setBuildingName]
  );

  if (buildingData.status === 'loading') {
    return (
      <Fragment>
        <div className={styles.header} />
        <div className={styles.wrapper}>
          <FillCenter>Loading building data...</FillCenter>
        </div>
      </Fragment>
    );
  }
  if (buildingData.status === 'error') return null;
  if (buildingData.status === 'pending') return null;
  const header = (
    <div className={styles.header}>
      <Link to={`/${organizationId}/buildings`}>
        <span style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Icons.ArrowLeftBack /> Back To Portfolio
        </span>
      </Link>

      <h1
        onClick={() => setShowSettingsModal(true)}
        className={styles.buildingName}
        data-cy="building-manager-building-name"
      >
        <Icons.WrenchTool /> <span>{buildingName} </span>
      </h1>

      <h3>Building Manager</h3>
    </div>
  );
  const loadPercentage =
    ((loadedSensors + loadedSpaces + loadedAOC) / totalEntities) * 100 || 0;
  return (
    <Fragment>
      {
        <BuildingSettingsModal
          visible={showSettingsModal}
          title={'Building metadata'}
          building={buildingData.data as V2Building}
          client={densityAPIClient}
          onDismiss={() => setShowSettingsModal(false)}
          updateSpace={updateSpace}
        />
      }
      {header}
      {floorData.status === 'pending' ? (
        <div className={styles.wrapper}>
          <div className={styles.loadingContainer}>
            <div>Loading sensor, space and area of coverage data</div>
            <LoadingBar percentage={loadPercentage} />
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <BuildingInfo data={data} />
          <FloorInfo
            data={data}
            client={densityAPIClient}
            orgId={organizationId}
          />
        </div>
      )}
    </Fragment>
  );
};

const BuildingManagerWithContext: React.FunctionComponent = () => {
  return (
    <BuildingProvider>
      <BuildingManager />
    </BuildingProvider>
  );
};

export default BuildingManagerWithContext;

import { FloorplanCoordinates } from 'lib/geometry';

export type PlacementMode =
  | {
      type: 'sensor';
      sensorType: 'oa' | 'entry' | 'waffle';
      mousePosition: FloorplanCoordinates | null;
    }
  | {
      type: 'areaofconcern';
      vertices: Array<FloorplanCoordinates>;
      mousePosition: FloorplanCoordinates | null;
      nextPointPosition: FloorplanCoordinates | null;
      mouseOverFinalPoint: boolean;
      nextPointSelfIntersection: FloorplanCoordinates | null;
    }
  | {
      type: 'areaofconcern-duplicate';
      mousePosition: FloorplanCoordinates | null;
    }
  | {
      type: 'reference';
      referenceType: 'height';
      mousePosition: FloorplanCoordinates | null;
    }
  | {
      type: 'reference';
      referenceType: 'ruler';
      positionA: FloorplanCoordinates | null;
      positionB: FloorplanCoordinates | null;
      mousePosition: FloorplanCoordinates | null;
    }
  | {
      type: 'threshold';
      positionA: FloorplanCoordinates | null;
      positionB: FloorplanCoordinates | null;
      mousePosition: FloorplanCoordinates | null;
    }
  | {
      type: 'space';
      shape: 'box' | 'circle' | 'polygon-duplicate';
      countingMode: 'oa' | 'entry' | 'waffle';
      mousePosition: FloorplanCoordinates | null;
    }
  | {
      type: 'space';
      shape: 'polygon';
      countingMode: 'oa' | 'entry' | 'waffle';
      vertices: Array<FloorplanCoordinates>;
      mousePosition: FloorplanCoordinates | null;
      nextPointPosition: FloorplanCoordinates | null;
      mouseOverFinalPoint: boolean;
      nextPointSelfIntersection: FloorplanCoordinates | null;
    }
  | {
      type: 'photogroup';
      mousePosition: FloorplanCoordinates | null;
    };

export namespace PlacementMode {
  export function computeText(placementMode: PlacementMode | null): string {
    if (!placementMode) {
      return '';
    }

    switch (placementMode.type) {
      case 'sensor': {
        const sensorTypeText =
          placementMode.sensorType === 'oa'
            ? 'Open Area'
            : placementMode.sensorType === 'entry'
            ? 'Entry'
            : 'Waffle';
        return `Add ${sensorTypeText} Sensor`;
      }
      case 'space':
        if (placementMode.shape === 'polygon') {
          if (placementMode.vertices.length === 0) {
            return 'Click to add your first point';
          } else if (placementMode.nextPointSelfIntersection) {
            return 'Cannot place a point here';
          } else if (placementMode.mouseOverFinalPoint) {
            return 'Click to complete the shape';
          } else {
            return 'Click to add your next point';
          }
        } else if (placementMode.shape === 'polygon-duplicate') {
          return `Click to add a polygon space`;
        } else {
          return `Click to add a ${placementMode.shape} ${placementMode.type}`;
        }
      case 'threshold':
        if (placementMode.positionA) {
          return `Click to place final doorway point`;
        }
        return `Click to place first doorway point`;
      case 'reference':
        if (
          placementMode.referenceType === 'ruler' &&
          placementMode.positionA
        ) {
          return `Click to place final ruler point`;
        } else if (placementMode.referenceType === 'ruler') {
          return `Click to place first ruler point`;
        } else {
          return `Click to place reference ${placementMode.referenceType}`;
        }
      case 'photogroup':
        return 'Click to add a photo group';
      case 'areaofconcern':
        if (placementMode.vertices.length === 0) {
          return 'Click to add your first point';
        } else if (placementMode.nextPointSelfIntersection) {
          return 'Cannot place a point here';
        } else if (placementMode.mouseOverFinalPoint) {
          return 'Click to complete the shape';
        } else {
          return 'Click to add your next point';
        }
      case 'areaofconcern-duplicate':
        return `Click to add an area of coverage`;
      default:
        return '';
    }
  }
}

import { Fragment, useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { AxiosInstance } from 'axios';
import Fuse from 'fuse.js';
import axios from 'axios';
import { Icons } from '@density/dust';
import { Icons as DensityUIIcons } from '@densityco/ui';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import { syncPlanSensorUpdateToAPI } from './editor';
import { Action } from './actions';
import { State, LayerId } from './state';
import styles from './styles.module.scss';
import { uploadDXFFile } from './cad-import/cad-import';

import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import HorizontalForm from 'components/horizontal-form';
import Switch from 'components/switch/switch';
import Tooltip from 'components/tooltip';
import Button from 'components/button';
import { CADFileUploader } from 'components/image-uploader';
import MiddleEllipsis from 'components/middle-ellipsis';

import { PlanDXF } from 'lib/dxf';
import { LengthUnit, displayArea } from 'lib/units';
import AreaOfConcern, {
  displayAreaOfConcernTotalArea,
} from 'lib/area-of-concern';
import { SPLITS } from 'lib/treatments';
import Space, { SpaceValidation } from 'lib/space';
import Threshold from 'lib/threshold';
import { FloorplanV2Plan, FloorplanAPI } from 'lib/api';
import { Analytics } from 'lib/analytics';
import FloorplanCollection from 'lib/floorplan-collection';
import PlanSensor, {
  PlanSensorFunction,
  OpenAreaFunction,
  EntryFunction,
  SensorValidation,
  SensorStatus,
} from 'lib/sensor';
import Reference from 'lib/reference';
import PhotoGroup from 'lib/photo-group';

import { useTreatment } from 'contexts/treatments';

const LAYER_TOGGLES_INDENT_1 = 0.6;
const LAYER_TOGGLES_INDENT_2 = 1.2;
const LAYER_TOGGLES_INDENT_3 = 1.8;

function applyFuzzySearch<T extends object>(
  collection: Array<T>,
  searchText: string,
  fns: Array<(item: T, index: number) => string>
): Array<[T, number]> {
  if (searchText.length === 0) {
    return collection.map((item, index) => [item, index]);
  }

  const collectionWithIndex = collection.map((item, index) => ({
    item,
    index,
  }));

  // NOTE: I'm really not satisfied with this fuse.js library. `fuzzy`, my preferred library, wasn't
  // working and I couldn't figure out why (was always just returning an empty array).
  const fuse = new Fuse(collectionWithIndex, {
    threshold: 0.1,
    includeScore: true,
    keys: ['bogus string needed here to trigger getFn below'],
    getFn: ({ item, index }) => fns.map((fn) => fn(item, index)),
  });

  return fuse.search(searchText).map((i) => [i.item.item, i.item.index]);
}

const ReferenceListItem: React.FunctionComponent<{
  reference: Reference;
  isVisible: boolean;
  isHighlighted: boolean;
  isHeightMapEnabled: boolean;
  isDisabled: boolean;
  dispatch: React.Dispatch<Action>;
  onDelete: () => void;
  onChangeVisible: (enabled: Reference['enabled']) => void;
}> = ({
  reference,
  isVisible,
  isHighlighted,
  isHeightMapEnabled,
  isDisabled,
  dispatch,
  onDelete,
  onChangeVisible: onChangeEnabled,
}) => {
  const [listItemHovered, setListitemHovered] = useState<boolean>(false);

  const onMouseEnter = useCallback(() => {
    setListitemHovered(true);
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'reference',
      itemId: reference.id,
    });
  }, [reference.id, dispatch]);
  const onMouseLeave = useCallback(() => {
    setListitemHovered(false);
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'reference',
      itemId: reference.id,
    });
  }, [reference.id, dispatch]);
  return (
    <li
      key={reference.id}
      className={classnames(styles.referenceListItem, {
        [styles.disabled]: isDisabled || !isVisible,
        [styles.highlighted]: isHighlighted,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-cy="reference-list-item"
    >
      <div className={styles.objectsPanelListItemIconWrapper}>
        {reference.type === 'height' ? (
          <Icons.RulerVertical size={18} />
        ) : (
          <Icons.Ruler size={18} />
        )}
      </div>
      <span className={styles.referenceListItemTitle}>{reference.id}</span>
      {listItemHovered ? (
        <button
          className={classnames([
            styles.buttonSmall,
            styles.buttonIcon,
            styles.danger,
          ])}
          disabled={
            isDisabled || (reference.type === 'height' && !isHeightMapEnabled)
          }
          onClick={() => onDelete()}
          data-cy="reference-delete"
        >
          <Icons.Trash size={18} color={dust.Red400} />
        </button>
      ) : null}
      <button
        className={classnames([styles.buttonSmall, styles.buttonIcon])}
        onClick={() => onChangeEnabled(!reference.enabled)}
        disabled={isDisabled}
      >
        {reference.enabled ? (
          <Icons.VisibilityShowEye size={18} />
        ) : (
          <Icons.VisibilityHideEye size={18} />
        )}
      </button>
    </li>
  );
};

const SensorListItem: React.FunctionComponent<{
  sensor: PlanSensor;
  sensorTitle: string;
  isHighlighted: boolean;
  isFocused: boolean;
  isDisabled: boolean;
  hasValidationWarning: boolean;
  hasValidationError: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({
  sensor,
  sensorTitle,
  isHighlighted,
  isFocused,
  isDisabled,
  hasValidationError,
  hasValidationWarning,
  dispatch,
}) => {
  const isValidationEnabled = useTreatment(SPLITS.VALIDATION);

  const onMouseEnter = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'sensor',
      itemId: sensor.id,
    });
  }, [sensor.id, isDisabled, dispatch]);

  const onMouseLeave = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'sensor',
      itemId: sensor.id,
    });
  }, [sensor.id, isDisabled, dispatch]);

  const onMouseDown = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mousedown',
      itemType: 'sensor',
      itemId: sensor.id,
    });
  }, [sensor.id, isDisabled, dispatch]);

  return (
    <li
      key={sensor.id}
      className={classnames(styles.sensorListItem, {
        [styles.highlighted]: isHighlighted,
        [styles.focused]: isFocused,
        [styles.disabled]: isDisabled,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      data-cy="sensor-list-item"
      data-cy-sensor-type={sensor.type}
      data-cy-disabled={isDisabled}
    >
      <div
        className={classnames([
          styles.objectsPanelListItemIconWrapper,
          {
            [styles.objectsPanelListItemIconWrapperEntry]:
              sensor.type === 'entry',
          },
          {
            [styles.objectsPanelListItemIconWrapperOALR]:
              sensor.sensorFunction === 'oaLongRange',
          },
        ])}
      >
        {sensor.type === 'oa' ? (
          <Icons.DeviceEntrySideIsometricAngle size={18} />
        ) : sensor.type === 'waffle' ? (
          <Icons.RadioInactiveCircle size={18} />
        ) : (
          <Icons.DeviceEntryTopFront size={18} />
        )}
      </div>
      {sensorTitle}

      <div style={{ flexGrow: 1 }} />

      {isValidationEnabled && hasValidationError ? (
        <Icons.DangerWarning size={14} color={dust.Red400} />
      ) : null}
      {isValidationEnabled && !hasValidationError && hasValidationWarning ? (
        <Icons.DangerWarning size={14} color={dust.Yellow400} />
      ) : null}
      {sensor.status === SensorStatus.LOW_POWER ? (
        <Icons.DotBullet size={14} color={dust.Yellow400} />
      ) : null}
      {sensor.status === SensorStatus.ERROR ? (
        <Icons.DotBullet size={14} color={dust.Red400} />
      ) : null}
      {sensor.status === SensorStatus.ONLINE ? (
        <Icons.DotBullet size={14} color={dust.Green400} />
      ) : null}
    </li>
  );
};

const ThresholdListItem: React.FunctionComponent<{
  threshold: Threshold;
  displayUnit: LengthUnit;
  isHighlighted: boolean;
  isFocused: boolean;
  isDisabled: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({
  threshold,
  displayUnit,
  isHighlighted,
  isFocused,
  isDisabled,
  dispatch,
}) => {
  const onMouseEnter = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'threshold',
      itemId: threshold.id,
    });
  }, [threshold.id, isDisabled, dispatch]);

  const onMouseLeave = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'threshold',
      itemId: threshold.id,
    });
  }, [threshold.id, isDisabled, dispatch]);

  const onMouseDown = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mousedown',
      itemType: 'threshold',
      itemId: threshold.id,
    });
  }, [threshold.id, isDisabled, dispatch]);

  return (
    <li
      key={threshold.id}
      className={classnames(styles.thresholdListItem, {
        [styles.highlighted]: isHighlighted,
        [styles.focused]: isFocused,
        [styles.disabled]: isDisabled,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      data-cy="threshold-list-item"
      data-cy-disabled={isDisabled}
    >
      <div
        className={classnames([
          styles.objectsPanelListItemIconWrapper,
          styles.objectsPanelListItemIconWrapperThreshold,
        ])}
      >
        <Icons.DoorEntry size={18} />
      </div>

      <MiddleEllipsis
        text={threshold.name ? threshold.name : threshold.id}
        numberOfTrailingCharacters={2}
        inFlexParent
      />

      <div className={styles.thresholdListItem}>
        {threshold.relatedSpaces.length}
        &nbsp;
        <Icons.SpaceTypeSpace size={17} />
      </div>
      <div className={styles.thresholdListItem}>
        {threshold.relatedPlanSensors.length}
        &nbsp;
        <Icons.DeviceEntryTopFront size={17} />
      </div>
    </li>
  );
};

const AreaOfConcernListItem: React.FunctionComponent<{
  areaOfConcern: AreaOfConcern;
  displayUnit: LengthUnit;
  isHighlighted: boolean;
  isFocused: boolean;
  isDisabled: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({
  areaOfConcern,
  displayUnit,
  isHighlighted,
  isFocused,
  isDisabled,
  dispatch,
}) => {
  const onMouseEnter = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'areaofconcern',
      itemId: areaOfConcern.id,
    });
  }, [areaOfConcern.id, isDisabled, dispatch]);

  const onMouseLeave = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'areaofconcern',
      itemId: areaOfConcern.id,
    });
  }, [areaOfConcern.id, isDisabled, dispatch]);

  const onMouseDown = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mousedown',
      itemType: 'areaofconcern',
      itemId: areaOfConcern.id,
    });
  }, [areaOfConcern.id, isDisabled, dispatch]);

  return (
    <li
      key={areaOfConcern.id}
      className={classnames(styles.areaOfConcernListItem, {
        [styles.highlighted]: isHighlighted,
        [styles.focused]: isFocused,
        [styles.disabled]: isDisabled,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      data-cy="aoc-list-item"
      data-cy-disabled={isDisabled}
    >
      <div
        className={classnames([
          styles.objectsPanelListItemIconWrapper,
          styles.objectsPanelListItemIconWrapperAreaOfConcern,
        ])}
      >
        <Icons.Control4 size={18} />
      </div>

      <MiddleEllipsis
        text={areaOfConcern.name}
        numberOfTrailingCharacters={4}
        inFlexParent
      />

      {areaOfConcern.sensorsEnabled ? (
        <div style={{ marginLeft: 4, marginRight: 2 }}>
          <Icons.IntegrationsAngleInclined size={14} color={dust.Green400} />
        </div>
      ) : null}

      <div className={styles.areaOfConcernListItem}>
        {displayArea(areaOfConcern.areaMeters, displayUnit)}
      </div>
    </li>
  );
};

const SpaceListItem: React.FunctionComponent<{
  space: Space;
  isHighlighted: boolean;
  isFocused: boolean;
  isDisabled: boolean;
  hasValidationWarning: boolean;
  hasValidationError: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({
  space,
  isHighlighted,
  isFocused,
  isDisabled,
  hasValidationError,
  hasValidationWarning,
  dispatch,
}) => {
  const onMouseEnter = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'space',
      itemId: space.id,
    });
  }, [space.id, isDisabled, dispatch]);

  const onMouseLeave = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'space',
      itemId: space.id,
    });
  }, [space.id, isDisabled, dispatch]);

  const onMouseDown = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mousedown',
      itemType: 'space',
      itemId: space.id,
    });
  }, [space.id, isDisabled, dispatch]);

  return (
    <li
      key={space.id}
      className={classnames(styles.spaceListItem, {
        [styles.highlighted]: isHighlighted,
        [styles.focused]: isFocused,
        [styles.disabled]: isDisabled,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      data-cy="space-list-item"
      data-cy-disabled={isDisabled}
    >
      <div
        className={classnames([
          styles.objectsPanelListItemIconWrapper,
          styles.objectsPanelListItemIconWrapperSpace,
        ])}
      >
        <Icons.SpaceTypeSpace size={18} />
      </div>

      <MiddleEllipsis
        text={space.name}
        numberOfTrailingCharacters={5}
        inFlexParent
      />

      {hasValidationError ? (
        <Icons.DangerWarning size={14} color={dust.Red400} />
      ) : null}
      {!hasValidationError && hasValidationWarning ? (
        <Icons.DangerWarning size={14} color={dust.Yellow400} />
      ) : null}
    </li>
  );
};

const PhotoGroupListItem: React.FunctionComponent<{
  photoGroup: PhotoGroup;
  title: string;
  isHighlighted: boolean;
  isFocused: boolean;
  isDisabled: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({
  photoGroup,
  title,
  isFocused,
  isHighlighted,
  isDisabled,
  dispatch,
}) => {
  const onMouseEnter = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'photogroup',
      itemId: photoGroup.id,
    });
  }, [photoGroup.id, isDisabled, dispatch]);

  const onMouseLeave = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'photogroup',
      itemId: photoGroup.id,
    });
  }, [photoGroup.id, isDisabled, dispatch]);

  return (
    <li
      key={photoGroup.id}
      data-cy={`photo-group-list-item-${photoGroup.id}`}
      data-cy-highlighted={isHighlighted}
      data-cy-disabled={isDisabled}
      className={classnames(styles.photoGroupListItem, {
        [styles.focused]: isFocused,
        [styles.highlighted]: isHighlighted,
        [styles.disabled]: isDisabled,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={() => {
        if (isDisabled) {
          return;
        }
        dispatch({
          type: 'item.menu.mousedown',
          itemType: 'photogroup',
          itemId: photoGroup.id,
        });
      }}
    >
      <div className={styles.photoGroupListItemIconWrapper}>
        <Icons.FolderImage size={18} />
      </div>
      <span className={styles.photoGroupListItemName}>{photoGroup.name}</span>
      {photoGroup.locked ? (
        <div className={styles.photoGroupListItemLockWrapper}>
          <Icons.LockClosed size={10} />
        </div>
      ) : null}
      <HorizontalForm size="small">
        <div className={styles.photoGroupListItemTitle}>{title}</div>
        <div
          className={classnames(styles.photoGroupListItemBubble, {
            [styles.filled]: photoGroup.photos.length === 0,
          })}
          data-cy="photo-group-bubble"
          data-cy-isfilled={photoGroup.photos.length === 0}
        />
      </HorizontalForm>
    </li>
  );
};

const LayerListItem: React.FunctionComponent<{
  icon: React.ReactNode;
  label: React.ReactNode;
  isCheckable?: boolean;
  isChecked?: boolean;
  onChange?: (value: boolean) => void;
  isDisabled?: boolean;
  indent?: number;
  actions?: React.ReactNode;
}> = ({
  icon,
  label,
  isCheckable = true,
  isChecked = false,
  onChange,
  isDisabled,
  indent = 0,
  actions,
}) => (
  <li
    className={styles.layerListItem}
    style={{ marginLeft: indent * 24 }}
    onClick={() => {
      if (isCheckable && onChange && !isDisabled) {
        onChange(!isChecked);
      }
    }}
  >
    <span className={styles.layerListItemIcon}>{icon}</span>
    <span className={styles.layerListItemLabel}>{label}</span>
    <HorizontalForm size="small">
      {actions}
      {isCheckable ? (
        <div onClick={(e) => e.stopPropagation()}>
          <Switch
            isChecked={isChecked}
            onChange={(event) => {
              if (onChange && !isDisabled) {
                onChange(event.target.checked);
              }
            }}
            isDisabled={isDisabled}
          />
        </div>
      ) : null}
    </HorizontalForm>
  </li>
);

const LayerListItemHeader: React.FunctionComponent = ({ children }) => (
  <li className={styles.layerListItemHeader}>{children}</li>
);

export const LatestDXFStatus: React.FunctionComponent<{
  state: State;
  client: AxiosInstance;
  plan: FloorplanV2Plan;
  dispatch: React.Dispatch<Action>;
}> = ({ state, client, plan, dispatch }) => {
  const onEditDXF = useCallback(
    async (dxfId: PlanDXF['id']) => {
      let dxfResponse;
      try {
        dxfResponse = await FloorplanAPI.getDXF(client, plan.id, dxfId);
      } catch (err) {
        toast.error(`Cannot get details for DXF ${dxfId}.`);
        return;
      }

      // Ensure that there is a png floorplan image available
      const fullImageAsset = dxfResponse.data.assets.find(
        (asset) =>
          asset.name === 'full_image' && asset.content_type === 'image/png'
      );
      if (!fullImageAsset) {
        toast.error(
          'Complete DXF does not contain a png-formatted floorplan image!'
        );
        return;
      }

      dispatch({
        type: 'latestDXF.edit',
        planDXF: dxfResponse.data,
        fullImageAsset,
      });
    },
    [client, plan.id, dispatch]
  );

  if (!state.latestDXF) {
    return null;
  }

  if (
    state.latestDXF.status !== 'uploading' &&
    state.latestDXF.status !== 'upload_error' &&
    state.latestDXF.id === state.activeDXFId
  ) {
    return null;
  }
  return (
    <div className={styles.latestDXFStatus}>
      <div className={styles.objectsPanelListItemIconWrapper}>
        <DensityUIIcons.Apps width={18} height={18} />
      </div>

      {state.latestDXF.status === 'uploading' ? (
        <div
          className={styles.middle}
          data-cy="latest-dxf-processing"
          data-cy-status="uploading"
        >
          <span className={styles.title}>Uploading DXF:</span>
          <Tooltip
            target={
              <div className={styles.latestDXFPercentWrapper}>
                <div
                  className={styles.latestDXFPercent}
                  style={{
                    width: `${state.latestDXF.fileUploadPercent || 0}%`,
                  }}
                />
              </div>
            }
            contents={`${Math.round(
              state.latestDXF.fileUploadPercent || 0
            )}% uploaded`}
            placement="bottom"
            width="100%"
          />
        </div>
      ) : null}
      {state.latestDXF.status === 'upload_error' ? (
        <div
          className={styles.middle}
          data-cy="latest-dxf-processing"
          data-cy-status="upload_error"
        >
          <span className={styles.title}>Uploading:</span>
          Upload Error!
        </div>
      ) : null}
      {state.latestDXF.status !== 'uploading' &&
      state.latestDXF.status !== 'upload_error' ? (
        <div
          className={styles.middle}
          data-cy="latest-dxf-processing"
          data-cy-status={state.latestDXF.status}
        >
          <span className={styles.title}>New DXF:</span>
          {{
            created: 'Queueing...',
            downloading: 'Downloading...',
            parsing_dxf: 'Reading Geometry...',
            processing_dxfs: 'Writing Geometry...',
            processing_images: 'Generating Images...',
            complete: 'Processed',
            error: 'Error',
            timeout: 'Timeout',
          }[state.latestDXF.status] || state.latestDXF.status}
        </div>
      ) : null}

      {state.latestDXF.status === 'complete' ? (
        <Button
          size="medium"
          type="outlined"
          trailingIcon={<Icons.ArrowRightForward size={18} />}
          onClick={() => {
            if (!state.latestDXF || state.latestDXF.status !== 'complete') {
              return;
            }
            onEditDXF(state.latestDXF.id);
          }}
          data-cy="latest-dxf-next"
        >
          Next
        </Button>
      ) : null}
      {state.latestDXF.status === 'error' ||
      state.latestDXF.status === 'timeout' ? (
        <Button
          size="medium"
          type="outlined"
          leadingIcon={<Icons.RefreshArrowSync size={18} />}
          onClick={async () => {
            if (
              !state.latestDXF ||
              (state.latestDXF.status !== 'error' &&
                state.latestDXF.status !== 'timeout')
            ) {
              return;
            }

            try {
              await FloorplanAPI.updateAndReparsePlanDXF(
                client,
                plan.id,
                state.latestDXF.id,
                {}
              );
            } catch (err) {
              toast.error('Error reprocessing dxf!');
              return;
            }

            toast.success('Reprocessing dxf...');
          }}
          data-cy="latest-dxf-retry"
        >
          Retry
        </Button>
      ) : null}
    </div>
  );
};

const FloorplanObjectsList: React.FunctionComponent<{
  state: State;
  client: AxiosInstance;
  plan: FloorplanV2Plan;
  dispatch: React.Dispatch<Action>;
  onDeleteReference: (reference: Reference) => void;
  onChangeReferenceEnabled: (
    reference: Reference,
    newEnabled: Reference['enabled']
  ) => void;
  onUploadSerialNumberCSV: (serialNumberCSVString: string) => void;
}> = ({
  state,
  client,
  plan,
  dispatch,
  onDeleteReference,
  onChangeReferenceEnabled,
  onUploadSerialNumberCSV,
}) => {
  const [isOASensorsListVisible, setIsOASensorsListVisible] =
    useState<boolean>(true);
  const [isEntrySensorsListVisible, setIsEntrySensorsListVisible] =
    useState<boolean>(true);
  const [isWaffleSensorsListVisible, setIsWaffleSensorsListVisible] =
    useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');

  const isHeightMapEnabled = useTreatment(SPLITS.HEIGHT_MAP);
  const isValidationEnabled = useTreatment(SPLITS.VALIDATION);
  const isHeatMapEnabled = useTreatment(SPLITS.HEATMAP);
  const isHeightSyncButtonEnabled = useTreatment(SPLITS.HEIGHT_SYNC_BUTTON);

  const [oaSensorTitles, entrySensorTitles, waffleSensorTitles] =
    useMemo(() => {
      const generateTitle = (sensor: PlanSensor) => {
        if (sensor.serialNumber) {
          return sensor.serialNumber;
        }
        if (sensor.cadId) {
          return sensor.cadId;
        }
        const genericTitlePrefix =
          sensor.type === 'oa' ? 'OA' : sensor.type === 'entry' ? 'E' : 'W';
        return `${genericTitlePrefix}${sensor.plan_sensor_index}`;
      };

      return [
        PlanSensor.filterByType('oa', state.planSensors).map(generateTitle),
        PlanSensor.filterByType('entry', state.planSensors).map(generateTitle),
        PlanSensor.filterByType('waffle', state.planSensors).map(generateTitle),
      ];
    }, [state.planSensors]);

  // To display sensors in the order of their plan index
  const sortSensorsByPlanIndex = (
    [sensorA, sensorAPreSortedIndex]: [PlanSensor, number],
    [sensorB, sensorBPreSortedIndex]: [PlanSensor, number]
  ): number => {
    if (!(sensorA && sensorB)) {
      return 0;
    }
    if (!sensorA.plan_sensor_index) {
      return 1;
    }
    if (!sensorB.plan_sensor_index) {
      return -1;
    }
    return sensorA.plan_sensor_index - sensorB.plan_sensor_index;
  };

  const photoGroupTitles = useMemo(() => {
    const generateTitle = (
      photoGroup: PhotoGroup,
      indexInCurrentList: number
    ) => {
      const numberInList = indexInCurrentList + 1;
      return `PG${numberInList}`;
    };

    return FloorplanCollection.list(state.photoGroups).map(generateTitle);
  }, [state.photoGroups]);

  const onUploadCADFile = useCallback(
    (file: File) => {
      Analytics.track('Floorplan Swap Click', {
        spaceId: plan.floor.id,
        type: 'image',
        fileType: file.type,
      });
      uploadDXFFile(
        plan.floor.id,
        plan.id,
        client,
        file,
        () => dispatch({ type: 'latestDXF.uploadBegin' }),
        (percent) =>
          dispatch({
            type: 'latestDXF.uploadProgress',
            fileUploadPercent: percent,
          }),
        () => dispatch({ type: 'latestDXF.uploadError' }),
        (planDXF) =>
          dispatch({
            type: 'latestDXF.uploadComplete',
            planDXF,
          })
      );
    },
    [plan, client, dispatch]
  );

  const onUploadImage = useCallback(
    async (image, dataUrl) => {
      Analytics.track('Swap Floorplan Click', {
        spaceId: plan.floor.id,
        type: 'cad',
        fileType: image.type,
      });

      dispatch({ type: 'scaleEdit.begin' });

      let signedUrlResponse;
      try {
        signedUrlResponse = await FloorplanAPI.imageUpload(client, {
          floor_id: plan.floor.id,
          ext: 'png',
          content_type: 'image/png',
        });
      } catch (err) {
        toast.error('Error creating image upload url!');
        dispatch({ type: 'scaleEdit.cancel' });
        return;
      }

      const [, imageData] = dataUrl.split(',');
      const {
        key: objectKey,
        signed_url: signedUrl,
        get_signed_url: getSignedUrl,
      } = signedUrlResponse.data;

      const imageBytesAsString = atob(imageData);
      const byteArray = new Uint8Array(imageBytesAsString.length);
      for (let i = 0; i < imageBytesAsString.length; i++) {
        byteArray[i] = imageBytesAsString.charCodeAt(i);
      }

      dispatch({ type: 'scaleEdit.beginUploading' });

      try {
        await axios.put(signedUrl, byteArray.buffer, {
          headers: {
            'Content-Type': 'image/png',
          },
          onUploadProgress: (event) => {
            if (typeof event.total === 'undefined') {
              return;
            }
            const percent = (event.loaded / event.total) * 100;
            dispatch({ type: 'scaleEdit.uploadProgress', percent });
          },
        });
      } catch (err) {
        toast.error('Error uploading image.');
        dispatch({ type: 'scaleEdit.cancel' });
        return;
      }

      // Instead of using the base64 data url version of the image, access it on s3 using the get
      // url that came back during the upload process
      const serverImage = new Image();
      serverImage.src = getSignedUrl;
      await new Promise((resolve) =>
        serverImage.addEventListener('load', resolve)
      );

      dispatch({
        type: 'scaleEdit.edit',
        image: serverImage,
        objectKey,
        openIntoMeasureMode: false,
      });
    },
    [plan, client, dispatch]
  );

  const areaOfConcernTotalArea = useMemo(() => {
    return displayAreaOfConcernTotalArea(
      state.areasOfConcern,
      state.displayUnit
    );
  }, [state.areasOfConcern, state.displayUnit]);

  const areaOfConcernTotalOASensorsEstimate = useMemo(() => {
    let total = 0;

    for (const areaOfConcern of FloorplanCollection.list(
      state.areasOfConcern
    )) {
      if (areaOfConcern.sensorPlacements.type !== 'done') {
        return '...';
      }
      total += areaOfConcern.sensorPlacements.data.length;
    }

    return total;
  }, [state.areasOfConcern]);

  const allValidations = useMemo(() => {
    return Array.from(state.validations).flatMap(([_k, v]) => {
      switch (v) {
        case 'empty':
        case 'loading':
          return [];
        default:
          return v;
      }
    });
  }, [state.validations]);

  const [spacesValidationsBadgeCount, spacesValidationsBadgeType] =
    useMemo(() => {
      const spacesValidations = allValidations.filter(
        (v) => v.objectType === 'space'
      );
      if (spacesValidations.length === 0) {
        return [null, null];
      }

      const validationErrors = spacesValidations.filter(
        (v) => v.severity === 'error'
      );
      if (validationErrors.length > 0) {
        return [validationErrors.length, 'error'];
      }

      const validationWarnings = spacesValidations.filter(
        (v) => v.severity === 'warning'
      );
      if (validationWarnings.length > 0) {
        return [validationWarnings.length, 'warning'];
      }

      return [null, null];
    }, [allValidations]);

  const [sensorsValidationsBadgeCount, sensorsValidationsBadgeType] =
    useMemo(() => {
      const sensorsValidations = allValidations.filter(
        (v) => v.objectType === 'sensor'
      );
      if (sensorsValidations.length === 0) {
        return [null, null];
      }

      const validationErrors = sensorsValidations.filter(
        (v) => v.severity === 'error'
      );
      if (validationErrors.length > 0) {
        return [validationErrors.length, 'error'];
      }

      const validationWarnings = sensorsValidations.filter(
        (v) => v.severity === 'warning'
      );
      if (validationWarnings.length > 0) {
        return [validationWarnings.length, 'warning'];
      }

      return [null, null];
    }, [allValidations]);

  return (
    <div className={styles.objectsPanel}>
      <div className={styles.objectsPanelHeader}>
        <div className={classnames(styles.navTabs, styles.objectsPanelTabs)}>
          <button
            className={classnames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'sensor',
            })}
            onClick={() => dispatch({ type: 'menu.showSensors' })}
            data-cy="sensors-tab"
          >
            <Icons.DeviceEntrySideIsometricAngle size={18} />
            <div className={styles.tooltip}>Sensors</div>
            {isValidationEnabled && sensorsValidationsBadgeCount ? (
              <div
                className={classnames(styles.tabBadge, {
                  [styles.error]: sensorsValidationsBadgeType === 'error',
                  [styles.warning]: sensorsValidationsBadgeType === 'warning',
                  [styles.small]: state.objectListType !== 'sensor',
                })}
              >
                {state.objectListType === 'sensor'
                  ? sensorsValidationsBadgeCount
                  : null}
              </div>
            ) : null}
          </button>
          <button
            className={classnames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'threshold',
            })}
            onClick={() => dispatch({ type: 'menu.showThresholds' })}
            data-cy="doorways-tab"
          >
            <Icons.DoorEntry size={18} />
            <div className={styles.tooltip}>Entry Doorways</div>
          </button>
          <button
            className={classnames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'space',
            })}
            onClick={() => dispatch({ type: 'menu.showSpaces' })}
            data-cy="spaces-tab"
          >
            <Icons.SpaceTypeSpace size={18} />
            <div className={styles.tooltip}>Spaces</div>
            {spacesValidationsBadgeCount ? (
              <div
                className={classnames(styles.tabBadge, {
                  [styles.error]: spacesValidationsBadgeType === 'error',
                  [styles.warning]: spacesValidationsBadgeType === 'warning',
                  [styles.small]: state.objectListType !== 'space',
                })}
              >
                {state.objectListType === 'space'
                  ? spacesValidationsBadgeCount
                  : null}
              </div>
            ) : null}
          </button>
          <button
            className={classnames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'areaofconcern',
            })}
            onClick={() => dispatch({ type: 'menu.showAreasOfConcern' })}
            data-cy="aoc-tab"
          >
            <Icons.Control4 size={18} />
            <div className={styles.tooltip}>Areas of Coverage</div>
          </button>
          <button
            className={classnames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'reference',
            })}
            onClick={() => dispatch({ type: 'menu.showReferences' })}
            data-cy="references-tab"
          >
            <Icons.Ruler size={18} />
            <div className={styles.tooltip}>References</div>
          </button>
          <button
            className={classnames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'photogroup',
            })}
            onClick={() => dispatch({ type: 'menu.showPhotoGroups' })}
            data-cy="photo-groups-tab"
          >
            <Icons.FolderImage size={18} />
            <div className={styles.tooltip}>Photos</div>
          </button>
          <button
            className={classnames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'layer',
            })}
            onClick={() => dispatch({ type: 'menu.showLayers' })}
            data-cy="layers-tab"
          >
            <Icons.SpaceTypeFloorLayers size={18} />
            <div className={styles.tooltip}>Layers</div>
          </button>
        </div>
      </div>

      {state.objectListType === 'sensor' ? (
        <Fragment>
          <div
            className={styles.objectsPanelActions}
            style={{ borderBottom: '0px', paddingBottom: '0px' }}
          >
            <h3 className={styles.objectsPanelActionsTitle}>OA</h3>
            <HorizontalForm size="medium">
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'sensor' &&
                    state.placementMode.sensorType === 'oa'
                )}
                disabled={state.locked}
                onClick={() => {
                  dispatch({ type: 'menu.addSensor', sensorType: 'oa' });
                }}
                data-cy="add-sensor-oa"
              >
                Add
              </Button>

              <SelectField
                size="small"
                value={state.defaultSensorType.openArea}
                width={105}
                disabled={state.locked}
                onChange={(choice) =>
                  dispatch({
                    type: 'defaultSensorType.set.openArea',
                    sensorType: choice.id as OpenAreaFunction,
                  })
                }
                choices={[
                  {
                    id: 'oaLongRange',
                    label:
                      PlanSensorFunction.generateDisplayName('oaLongRange'),
                  },
                  {
                    id: 'oaMidRange',
                    label: PlanSensorFunction.generateDisplayName('oaMidRange'),
                  },
                  {
                    id: 'oaShortRange',
                    label:
                      PlanSensorFunction.generateDisplayName('oaShortRange'),
                  },
                ]}
                data-cy="add-sensor-oa-selector"
              />
            </HorizontalForm>
          </div>

          <div className={styles.objectsPanelActions}>
            <h3 className={styles.objectsPanelActionsTitle}>Entry</h3>
            <HorizontalForm size="medium">
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'sensor' &&
                    state.placementMode.sensorType === 'entry'
                )}
                disabled={state.locked}
                onClick={() => {
                  dispatch({ type: 'menu.addSensor', sensorType: 'entry' });
                }}
                data-cy="add-sensor-entry"
              >
                Add
              </Button>

              <SelectField
                size="small"
                value={state.defaultSensorType.entry}
                width={105}
                disabled={state.locked}
                onChange={(choice) =>
                  dispatch({
                    type: 'defaultSensorType.set.entry',
                    sensorType: choice.id as EntryFunction,
                  })
                }
                choices={[
                  {
                    id: 'tofEntry',
                    label: PlanSensorFunction.generateDisplayName('tofEntry'),
                  },
                  {
                    id: 'openEntry',
                    label: PlanSensorFunction.generateDisplayName('openEntry'),
                  },
                ]}
                data-cy="add-sensor-entry-selector"
              />
            </HorizontalForm>
          </div>

          {isHeightSyncButtonEnabled ? (
            <div className={styles.objectsPanelActionsLastRow}>
              <button
                className={classnames([styles.buttonSmall, styles.link])}
                style={{ width: '90%' }}
                disabled={state.locked || !state.syncHeightsButtonEnabled}
                onClick={() => {
                  // Define an async function that wraps the code inside onClick
                  const handleOnClick = async () => {
                    // Use Promise.all to handle multiple async calls concurrently
                    let sensorsList: Array<PlanSensor> =
                      FloorplanCollection.list(state.planSensors);
                    if (sensorsList.length === 0) {
                      toast.warn('No Plan Sensors to sync.');
                      return;
                    }
                    sensorsList = sensorsList.filter(
                      (sensor) =>
                        sensor.serialNumber !== null &&
                        sensor.id.startsWith('psr_')
                    );
                    if (sensorsList.length === 0) {
                      toast.warn('No PlanSensors with serial numbers to sync.');
                      return;
                    }

                    toast.warn(
                      'Kicked off the sync. This may take a moment. Please use this button sparingly!'
                    );
                    dispatch({ type: 'menu.disableSyncHeightsButton' });

                    let completedSuccess = 0;
                    await Promise.all(
                      sensorsList.map(async (sensor) => {
                        // Use try-catch to handle errors in individual async calls
                        try {
                          await syncPlanSensorUpdateToAPI(
                            client,
                            plan.id,
                            state,
                            sensor.id,
                            ['height_meters']
                          );
                          completedSuccess += 1;
                        } catch (error) {
                          // Handle or log the error as needed
                          console.error('Error updating sensor:', error);
                          toast.error(`Error updating ${sensor.serialNumber}.`);
                        }
                      })
                    );
                    if (completedSuccess > 0) {
                      toast.success(
                        `Completed height sync of ${completedSuccess} Plan Sensor(s) with serial number(s).`
                      );
                    }
                    dispatch({ type: 'menu.enableSyncHeightsButton' });
                  };
                  handleOnClick();
                }}
                data-cy="sync-heights-button"
              >
                <Icons.CloudSuccess size={18} />
                &nbsp; Sync Sensor Heights
              </button>
            </div>
          ) : null}

          <div className={styles.objectsPanelSearch}>
            <TextField
              leadingIcon={<Icons.SearchMagnifier size={16} />}
              size="medium"
              placeholder="Search sensors..."
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          </div>
          {FloorplanCollection.isEmpty(state.planSensors) ? (
            <div className={styles.objectsPanelEmptyState}>
              <Icons.DeviceEntrySideIsometricAngle size={36} />
              No Sensors
            </div>
          ) : (
            <div className={styles.objectsPanelMainScrollArea}>
              <div
                className={styles.sensorListHeader}
                onClick={() => {
                  setIsOASensorsListVisible(!isOASensorsListVisible);
                }}
              >
                <h3 className={styles.sensorListTitle}>Open Area</h3>
                {isOASensorsListVisible ? (
                  <Icons.ChevronDown size={18} />
                ) : (
                  <Icons.ChevronUp size={18} />
                )}
              </div>
              {isOASensorsListVisible ? (
                <ul className={styles.sensorList}>
                  {applyFuzzySearch(
                    PlanSensor.filterByType('oa', state.planSensors),
                    searchText,
                    [
                      (planSensor) => planSensor.serialNumber || '', // Serial number
                      (planSensor) => planSensor.cadId, // CAD ID
                      (_s, index) => oaSensorTitles[index], // Floorplan identifier
                    ]
                  )
                    .sort(sortSensorsByPlanIndex)
                    .map(([planSensor, preSortedIndex]) => {
                      let hasErrors = false;
                      let hasWarnings = false;

                      const sensorValidations = state.validations.get(
                        planSensor.id
                      );
                      if (
                        sensorValidations &&
                        sensorValidations !== 'empty' &&
                        sensorValidations !== 'loading'
                      ) {
                        hasErrors = Boolean(
                          sensorValidations.find(
                            (v: SensorValidation | SpaceValidation) =>
                              v.severity === 'error'
                          )
                        );
                        hasWarnings = Boolean(
                          sensorValidations.find(
                            (v: SensorValidation | SpaceValidation) =>
                              v.severity === 'warning'
                          )
                        );
                      }

                      return (
                        <SensorListItem
                          key={planSensor.id}
                          sensor={planSensor}
                          sensorTitle={oaSensorTitles[preSortedIndex]}
                          isHighlighted={State.isSensorHighlighted(
                            state,
                            planSensor.id
                          )}
                          isFocused={State.isSensorFocused(
                            state,
                            planSensor.id
                          )}
                          isDisabled={!state.planning.showSensorsOpenArea}
                          hasValidationError={hasErrors}
                          hasValidationWarning={hasWarnings}
                          dispatch={dispatch}
                        />
                      );
                    })}
                </ul>
              ) : null}
              <div
                className={styles.sensorListHeader}
                onClick={() => {
                  setIsEntrySensorsListVisible(!isEntrySensorsListVisible);
                }}
              >
                <h3 className={styles.sensorListTitle}>ENTRY</h3>
                {isEntrySensorsListVisible ? (
                  <Icons.ChevronDown size={18} />
                ) : (
                  <Icons.ChevronUp size={18} />
                )}
              </div>
              {isEntrySensorsListVisible ? (
                <ul className={styles.sensorList}>
                  {applyFuzzySearch(
                    PlanSensor.filterByType('entry', state.planSensors),
                    searchText,
                    [
                      (planSensor) => planSensor.serialNumber || '', // Serial number
                      (_s, index) => entrySensorTitles[index], // Floorplan identifier
                    ]
                  )
                    .sort(sortSensorsByPlanIndex)
                    .map(([planSensor, preSortedIndex]) => {
                      let hasErrors = false;
                      let hasWarnings = false;

                      const sensorValidations = state.validations.get(
                        planSensor.id
                      );
                      if (
                        sensorValidations &&
                        sensorValidations !== 'empty' &&
                        sensorValidations !== 'loading'
                      ) {
                        hasErrors = Boolean(
                          sensorValidations.find(
                            (v: SensorValidation | SpaceValidation) =>
                              v.severity === 'error'
                          )
                        );
                        hasWarnings = Boolean(
                          sensorValidations.find(
                            (v: SensorValidation | SpaceValidation) =>
                              v.severity === 'warning'
                          )
                        );
                      }

                      return (
                        <SensorListItem
                          key={planSensor.id}
                          sensor={planSensor}
                          sensorTitle={entrySensorTitles[preSortedIndex]}
                          isHighlighted={State.isSensorHighlighted(
                            state,
                            planSensor.id
                          )}
                          isFocused={State.isSensorFocused(
                            state,
                            planSensor.id
                          )}
                          isDisabled={!state.planning.showSensorsEntry}
                          hasValidationError={hasErrors}
                          hasValidationWarning={hasWarnings}
                          dispatch={dispatch}
                        />
                      );
                    })}
                </ul>
              ) : null}
              <div
                className={styles.sensorListHeader}
                onClick={() => {
                  setIsWaffleSensorsListVisible(!isWaffleSensorsListVisible);
                }}
              >
                <h3 className={styles.sensorListTitle}>WAFFLE</h3>
                {isWaffleSensorsListVisible ? (
                  <Icons.ChevronDown size={18} />
                ) : (
                  <Icons.ChevronUp size={18} />
                )}
              </div>
              {isWaffleSensorsListVisible ? (
                <ul className={styles.sensorList}>
                  {applyFuzzySearch(
                    PlanSensor.filterByType('waffle', state.planSensors),
                    searchText,
                    [
                      (planSensor) => planSensor.serialNumber || '', // Serial number
                      (planSensor) => planSensor.cadId, // CAD ID
                      (_s, index) => waffleSensorTitles[index], // Floorplan identifier
                    ]
                  )
                    .sort(sortSensorsByPlanIndex)
                    .map(([planSensor, preSortedIndex]) => {
                      let hasErrors = false;
                      let hasWarnings = false;

                      const sensorValidations = state.validations.get(
                        planSensor.id
                      );
                      if (
                        sensorValidations &&
                        sensorValidations !== 'empty' &&
                        sensorValidations !== 'loading'
                      ) {
                        hasErrors = Boolean(
                          sensorValidations.find(
                            (v: SensorValidation | SpaceValidation) =>
                              v.severity === 'error'
                          )
                        );
                        hasWarnings = Boolean(
                          sensorValidations.find(
                            (v: SensorValidation | SpaceValidation) =>
                              v.severity === 'warning'
                          )
                        );
                      }

                      return (
                        <SensorListItem
                          key={planSensor.id}
                          sensor={planSensor}
                          sensorTitle={waffleSensorTitles[preSortedIndex]}
                          isHighlighted={State.isSensorHighlighted(
                            state,
                            planSensor.id
                          )}
                          isFocused={State.isSensorFocused(
                            state,
                            planSensor.id
                          )}
                          isDisabled={!state.planning.showSensorsWaffle}
                          hasValidationError={hasErrors}
                          hasValidationWarning={hasWarnings}
                          dispatch={dispatch}
                        />
                      );
                    })}
                </ul>
              ) : null}
            </div>
          )}
          <div className={styles.objectsPanelFooter}>
            {PlanSensor.filterByType('entry', state.planSensors).length > 0 ? (
              <div
                className={styles.sensorCount}
                style={{ paddingBottom: '0px' }}
              >
                <div className={styles.sensorCountLabel}>
                  <Icons.DeviceEntryTopFront size={18} color={dust.Gray600} />
                  &nbsp; Entry Total
                </div>
                <div className={styles.sensorCountNumber}>
                  {PlanSensor.filterByType('entry', state.planSensors).length}
                </div>
              </div>
            ) : null}

            {PlanSensor.filterByFunction('openEntry', state.planSensors)
              .length > 0 ? (
              <div
                className={styles.sensorCount}
                style={{ paddingLeft: '41px' }}
              >
                <div className={styles.sensorFunctionCountLabel}>
                  {`${PlanSensorFunction.generateDisplayName('openEntry')}`}
                </div>
                <div className={styles.sensorFunctionCountNumber}>
                  {
                    PlanSensor.filterByFunction('openEntry', state.planSensors)
                      .length
                  }
                </div>
              </div>
            ) : null}

            {PlanSensor.filterByFunction('tofEntry', state.planSensors).length >
            0 ? (
              <div
                className={styles.sensorCount}
                style={{ paddingLeft: '41px' }}
              >
                <div className={styles.sensorFunctionCountLabel}>
                  {` ${PlanSensorFunction.generateDisplayName('tofEntry')}`}
                </div>
                <div className={styles.sensorFunctionCountNumber}>
                  {
                    PlanSensor.filterByFunction('tofEntry', state.planSensors)
                      .length
                  }
                </div>
              </div>
            ) : null}

            {PlanSensor.filterByType('oa', state.planSensors).length > 0 ? (
              <div
                className={styles.sensorCount}
                style={{ paddingBottom: '0px' }}
              >
                <div className={styles.sensorCountLabel}>
                  <Icons.DeviceEntrySideIsometricAngle
                    size={18}
                    color={dust.Gray600}
                  />
                  &nbsp; Open Area Total
                </div>
                <div className={styles.sensorCountNumber}>
                  {PlanSensor.filterByType('oa', state.planSensors).length}
                </div>
              </div>
            ) : null}

            {PlanSensor.filterByFunction('oaLongRange', state.planSensors)
              .length > 0 ? (
              <div
                className={styles.sensorCount}
                style={{ paddingLeft: '41px' }}
              >
                <div className={styles.sensorFunctionCountLabel}>
                  {` ${PlanSensorFunction.generateDisplayName('oaLongRange')}`}
                </div>
                <div className={styles.sensorFunctionCountNumber}>
                  {
                    PlanSensor.filterByFunction(
                      'oaLongRange',
                      state.planSensors
                    ).length
                  }
                </div>
              </div>
            ) : null}
            {PlanSensor.filterByFunction('oaMidRange', state.planSensors)
              .length > 0 ? (
              <div
                className={styles.sensorCount}
                style={{ paddingLeft: '41px' }}
              >
                <div className={styles.sensorFunctionCountLabel}>
                  {` ${PlanSensorFunction.generateDisplayName('oaMidRange')}`}
                </div>
                <div className={styles.sensorFunctionCountNumber}>
                  {
                    PlanSensor.filterByFunction('oaMidRange', state.planSensors)
                      .length
                  }
                </div>
              </div>
            ) : null}
            {PlanSensor.filterByFunction('oaShortRange', state.planSensors)
              .length > 0 ? (
              <div
                className={styles.sensorCount}
                style={{ paddingLeft: '41px' }}
              >
                <div className={styles.sensorFunctionCountLabel}>
                  {` ${PlanSensorFunction.generateDisplayName('oaShortRange')}`}
                </div>
                <div className={styles.sensorFunctionCountNumber}>
                  {
                    PlanSensor.filterByFunction(
                      'oaShortRange',
                      state.planSensors
                    ).length
                  }
                </div>
              </div>
            ) : null}

            {PlanSensor.filterByType('waffle', state.planSensors).length > 0 ? (
              <div
                className={styles.sensorCount}
                style={{ paddingBottom: '0px' }}
              >
                <div className={styles.sensorCountLabel}>
                  <Icons.RadioInactiveCircle size={18} color={dust.Gray600} />
                  &nbsp; Waffle Total
                </div>
                <div className={styles.sensorCountNumber}>
                  {PlanSensor.filterByType('waffle', state.planSensors).length}
                </div>
              </div>
            ) : null}

            {PlanSensor.filterByFunction('waffle', state.planSensors).length >
            0 ? (
              <div
                className={styles.sensorCount}
                style={{ paddingLeft: '41px' }}
              >
                <div className={styles.sensorFunctionCountLabel}>
                  {` ${PlanSensorFunction.generateDisplayName('waffle')}`}
                </div>
                <div className={styles.sensorFunctionCountNumber}>
                  {
                    PlanSensor.filterByFunction('waffle', state.planSensors)
                      .length
                  }
                </div>
              </div>
            ) : null}
          </div>
        </Fragment>
      ) : null}

      {state.objectListType === 'threshold' ? (
        <Fragment>
          {FloorplanCollection.isEmpty(state.thresholds) ? (
            <div className={styles.objectsPanelEmptyState}>
              <Icons.DoorEntry size={40} />
              No Doorways
            </div>
          ) : (
            <>
              <div className={styles.objectsPanelActions}>
                <h3 className={styles.objectsPanelActionsTitle}>
                  Draw Doorway
                </h3>
                <Button
                  size="medium"
                  type="hollow"
                  active={Boolean(
                    state.placementMode &&
                      state.placementMode.type === 'threshold'
                  )}
                  disabled={state.locked || !state.planning.showThresholds}
                  onClick={() => {
                    Analytics.track('Draw Doorway', { spaceId: plan.floor.id });
                    dispatch({ type: 'menu.addThreshold' });
                  }}
                  data-cy="doorway-add"
                  trailingIcon={<Icons.PencilEditor size={18} />}
                />
              </div>
              <div
                className={styles.objectsPanelMainScrollArea}
                data-cy="threshold-object-list"
              >
                <ul className={styles.sensorList}>
                  {applyFuzzySearch(
                    FloorplanCollection.list(state.thresholds),
                    searchText,
                    [(a) => a.id || '']
                  ).map(([threshold]) => {
                    return (
                      <ThresholdListItem
                        key={threshold.id}
                        threshold={threshold}
                        displayUnit={state.displayUnit}
                        isHighlighted={State.isThresholdHighlighted(
                          state,
                          threshold.id
                        )}
                        isFocused={State.isThresholdFocused(
                          state,
                          threshold.id
                        )}
                        isDisabled={!state.planning.showThresholds}
                        dispatch={dispatch}
                      />
                    );
                  })}
                </ul>
              </div>
            </>
          )}
        </Fragment>
      ) : null}

      {state.objectListType === 'areaofconcern' ? (
        <Fragment>
          <div className={styles.objectsPanelActions}>
            <h3 className={styles.objectsPanelActionsTitle}>
              Draw Area of Coverage
            </h3>
            <HorizontalForm size="medium">
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'areaofconcern'
                )}
                disabled={state.locked || !state.planning.showAreasOfConcern}
                onClick={() => {
                  dispatch({ type: 'menu.addAreaOfConcern' });
                }}
                data-cy="add-aoc-polygon"
                trailingIcon={<Icons.DrawFreehand size={18} />}
              />
            </HorizontalForm>
          </div>

          {FloorplanCollection.isEmpty(state.areasOfConcern) ? (
            <div className={styles.objectsPanelEmptyState}>
              <Icons.Control4 size={36} />
              No Areas of Coverage
            </div>
          ) : (
            <div
              className={styles.objectsPanelMainScrollArea}
              data-cy="aoc-object-list"
            >
              <ul className={styles.sensorList}>
                {applyFuzzySearch(
                  FloorplanCollection.list(state.areasOfConcern),
                  searchText,
                  [(a) => a.id || '']
                ).map(([areaOfConcern]) => {
                  return (
                    <AreaOfConcernListItem
                      key={areaOfConcern.id}
                      areaOfConcern={areaOfConcern}
                      displayUnit={state.displayUnit}
                      isHighlighted={State.isAreaOfConcernHighlighted(
                        state,
                        areaOfConcern.id
                      )}
                      isFocused={State.isAreaOfConcernFocused(
                        state,
                        areaOfConcern.id
                      )}
                      isDisabled={
                        state.locked || !state.planning.showAreasOfConcern
                      }
                      dispatch={dispatch}
                    />
                  );
                })}
              </ul>
            </div>
          )}

          <div className={styles.objectsPanelFooter}>
            <div className={styles.sensorCount}>
              <div className={styles.sensorCountLabel}>
                <Icons.SpacesAreasFloorplanPlanner
                  size={18}
                  color={dust.Gray500}
                />
                Total Area:
              </div>
              <div className={styles.sensorCountNumber}>
                {areaOfConcernTotalArea}
              </div>
            </div>
            <div className={styles.sensorCount}>
              <div className={styles.sensorCountLabel}>
                <Icons.DeviceEntrySideIsometricAngle
                  size={18}
                  color={dust.Gray500}
                />
                Total OA (Estimated):
              </div>
              <div className={styles.sensorCountNumber}>
                {areaOfConcernTotalOASensorsEstimate}
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}

      {state.objectListType === 'space' ? (
        <Fragment>
          <div
            className={styles.objectsPanelActionsFirstRow}
            style={{ marginBottom: '0px', paddingBottom: '1px' }}
          >
            <h3 className={styles.objectsPanelActionsTitle}>Add OA Space</h3>
            <HorizontalForm size="medium">
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'space' &&
                    state.placementMode.shape === 'box' &&
                    state.placementMode.countingMode === 'oa'
                )}
                disabled={state.locked}
                onClick={() => {
                  dispatch({
                    type: 'menu.addSpace',
                    shape: 'box',
                    countingMode: 'oa',
                  });
                }}
                data-cy="add-space-box"
                trailingIcon={<Icons.EditorSquare size={18} />}
              />
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'space' &&
                    state.placementMode.shape === 'circle'
                )}
                disabled={state.locked}
                onClick={() => {
                  dispatch({
                    type: 'menu.addSpace',
                    shape: 'circle',
                    countingMode: 'oa',
                  });
                }}
                data-cy="add-space-circle"
                trailingIcon={<Icons.EditorCircle size={18} />}
              />
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'space' &&
                    state.placementMode.shape === 'polygon' &&
                    state.placementMode.countingMode === 'oa'
                )}
                disabled={state.locked}
                onClick={() => {
                  dispatch({
                    type: 'menu.addSpace',
                    shape: 'polygon',
                    countingMode: 'oa',
                  });
                }}
                data-cy="add-space-polygon"
                trailingIcon={<Icons.DrawFreehand size={18} />}
              />
            </HorizontalForm>
          </div>
          <div className={styles.objectsPanelActions}>
            <h3 className={styles.objectsPanelActionsTitle}>Add Entry Space</h3>
            <HorizontalForm size="medium">
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'space' &&
                    state.placementMode.shape === 'box' &&
                    state.placementMode.countingMode === 'entry'
                )}
                disabled={state.locked}
                onClick={() => {
                  dispatch({
                    type: 'menu.addSpace',
                    shape: 'box',
                    countingMode: 'entry',
                  });
                }}
                data-cy="add-space-box-entry"
                trailingIcon={<Icons.EditorSquare size={18} />}
              />
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'space' &&
                    state.placementMode.shape === 'polygon' &&
                    state.placementMode.countingMode === 'entry'
                )}
                disabled={state.locked}
                onClick={() => {
                  dispatch({
                    type: 'menu.addSpace',
                    shape: 'polygon',
                    countingMode: 'entry',
                  });
                }}
                data-cy="add-space-polygon-entry"
                trailingIcon={<Icons.DrawFreehand size={18} />}
              />
            </HorizontalForm>
          </div>
          <div className={styles.objectsPanelActions}>
            <h3 className={styles.objectsPanelActionsTitle}>
              Add Waffle Space
            </h3>
            <HorizontalForm size="medium">
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'space' &&
                    state.placementMode.shape === 'box' &&
                    state.placementMode.countingMode === 'waffle'
                )}
                disabled={state.locked}
                onClick={() => {
                  dispatch({
                    type: 'menu.addSpace',
                    shape: 'box',
                    countingMode: 'waffle',
                  });
                }}
                data-cy="add-space-box-waffle"
                trailingIcon={<Icons.EditorSquare size={18} />}
              />
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'space' &&
                    state.placementMode.shape === 'polygon' &&
                    state.placementMode.countingMode === 'waffle'
                )}
                disabled={state.locked}
                onClick={() => {
                  dispatch({
                    type: 'menu.addSpace',
                    shape: 'polygon',
                    countingMode: 'waffle',
                  });
                }}
                data-cy="add-space-polygon-waffle"
                trailingIcon={<Icons.DrawFreehand size={18} />}
              />
            </HorizontalForm>
          </div>
          {!FloorplanCollection.isEmpty(state.areasOfConcern) && (
            <div className={styles.objectsPanelActionsLastRow}>
              <Tooltip
                contents={'Highlight Spaces Outside AOCs'}
                placement="bottom"
                target={
                  <button
                    className={classnames([styles.buttonSmall, styles.link])}
                    style={{ width: '90%' }}
                    onClick={() => {
                      dispatch({ type: 'menu.validateSpaces' });
                    }}
                    data-cy="validate-spaces"
                  >
                    <Icons.CheckCircle size={18} />
                    &nbsp; Validate Spaces
                  </button>
                }
              />
            </div>
          )}
          <div className={styles.objectsPanelSearch}>
            <TextField
              leadingIcon={<Icons.SearchMagnifier size={16} />}
              size="medium"
              placeholder="Search spaces..."
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          </div>
          {FloorplanCollection.isEmpty(state.spaces) ? (
            <div className={styles.objectsPanelEmptyState}>
              <Icons.SpaceTypeSpace size={36} />
              No Spaces
            </div>
          ) : (
            <div
              className={styles.objectsPanelMainScrollArea}
              data-cy="space-object-list"
            >
              <ul className={styles.sensorList}>
                {applyFuzzySearch(
                  FloorplanCollection.list(state.spaces),
                  searchText,
                  [(s) => s.name || '']
                ).map(([space]) => {
                  let hasErrors = false;
                  let hasWarnings = false;

                  const spaceValidations = state.validations.get(space.id);
                  if (
                    spaceValidations &&
                    spaceValidations !== 'empty' &&
                    spaceValidations !== 'loading'
                  ) {
                    hasErrors = Boolean(
                      spaceValidations.find(
                        (v: SensorValidation | SpaceValidation) =>
                          v.severity === 'error'
                      )
                    );
                    hasWarnings = Boolean(
                      spaceValidations.find(
                        (v: SensorValidation | SpaceValidation) =>
                          v.severity === 'warning'
                      )
                    );
                  }

                  return (
                    <SpaceListItem
                      key={space.id}
                      space={space}
                      isHighlighted={State.isSpaceHighlighted(state, space.id)}
                      isFocused={State.isSpaceFocused(state, space.id)}
                      isDisabled={!state.planning.showSpaces}
                      hasValidationError={hasErrors}
                      hasValidationWarning={hasWarnings}
                      dispatch={dispatch}
                    />
                  );
                })}
              </ul>
            </div>
          )}
        </Fragment>
      ) : null}

      {state.objectListType === 'reference' ? (
        <Fragment>
          <div className={styles.objectsPanelActions}>
            <h3 className={styles.objectsPanelActionsTitle}>Add Ruler</h3>
            {/* <button
              className={classNames(styles.objectsPanelActionsButton, {
                [styles.active]:
                  state.placementMode &&
                  state.placementMode.type === 'reference' &&
                  state.placementMode.referenceType === 'height',
              })}
              onClick={() => {
                dispatch({ type: 'menu.addReference', referenceType: 'height' });
              }}
            >
              <img src={iconTarget} width={18} height={18} alt={'Point'} />
            </button> */}
            <HorizontalForm size="medium">
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'reference' &&
                    state.placementMode.referenceType === 'height'
                )}
                onClick={() => {
                  Analytics.track('Add Reference Height', {
                    spaceId: plan.floor.id,
                  });
                  dispatch({
                    type: 'menu.addReference',
                    referenceType: 'height',
                  });
                }}
                disabled={
                  state.locked ||
                  !state.heightMap.enabled ||
                  !state.planning.showHeights
                }
                data-cy="add-height"
                trailingIcon={<Icons.RulerVertical size={18} />}
              />
              <Button
                size="medium"
                type="hollow"
                active={Boolean(
                  state.placementMode &&
                    state.placementMode.type === 'reference' &&
                    state.placementMode.referenceType === 'ruler'
                )}
                disabled={state.locked || !state.planning.showRulers}
                onClick={() => {
                  dispatch({
                    type: 'menu.addReference',
                    referenceType: 'ruler',
                  });
                }}
                data-cy="add-ruler"
                trailingIcon={<Icons.Ruler size={18} />}
              />
            </HorizontalForm>
          </div>
          {FloorplanCollection.isEmpty(state.references) ? (
            <div className={styles.objectsPanelEmptyState}>
              <Icons.Ruler size={36} />
              No References
            </div>
          ) : (
            <div className={styles.objectsPanelMainScrollArea}>
              <ul className={styles.sensorList}>
                {FloorplanCollection.list(state.references).map((reference) => {
                  return (
                    <ReferenceListItem
                      key={reference.id}
                      reference={reference}
                      isVisible={State.isReferenceEnabled(state, reference.id)}
                      isHighlighted={State.isReferenceHighlighted(
                        state,
                        reference.id
                      )}
                      isHeightMapEnabled={state.heightMap.enabled}
                      dispatch={dispatch}
                      onDelete={() => onDeleteReference(reference)}
                      isDisabled={state.locked}
                      onChangeVisible={(enabled) =>
                        onChangeReferenceEnabled(reference, enabled)
                      }
                    />
                  );
                })}
              </ul>
            </div>
          )}
        </Fragment>
      ) : null}

      {state.objectListType === 'photogroup' ? (
        <Fragment>
          <div className={styles.objectsPanelActions}>
            <h3 className={styles.objectsPanelActionsTitle}>Add Photo Group</h3>
            <Button
              size="medium"
              type="hollow"
              active={Boolean(
                state.placementMode && state.placementMode.type === 'photogroup'
              )}
              disabled={state.locked || !state.planning.showPhotoGroups}
              onClick={() => {
                Analytics.track('Add Photo Group', { spaceId: plan.floor.id });
                dispatch({ type: 'menu.addPhotoGroup' });
              }}
              data-cy="photo-group-add"
              trailingIcon={<Icons.FolderImageAdd size={18} />}
            />
          </div>
          <div className={styles.objectsPanelSearch}>
            <TextField
              leadingIcon={<Icons.SearchMagnifier size={16} />}
              size="medium"
              placeholder="Search photo groups..."
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          </div>
          {FloorplanCollection.isEmpty(state.photoGroups) ? (
            <div className={styles.objectsPanelEmptyState}>
              <Icons.FolderImage size={36} />
              No Photo Groups
            </div>
          ) : (
            <div className={styles.objectsPanelMainScrollArea}>
              <ul className={styles.sensorList}>
                {applyFuzzySearch(
                  FloorplanCollection.list(state.photoGroups),
                  searchText,
                  [(photoGroup) => photoGroup.name]
                ).map(([photoGroup, preSortedIndex]) => {
                  return (
                    <PhotoGroupListItem
                      key={photoGroup.id}
                      title={photoGroupTitles[preSortedIndex]}
                      photoGroup={photoGroup}
                      isFocused={State.isPhotoGroupFocused(
                        state,
                        photoGroup.id
                      )}
                      isHighlighted={State.isPhotoGroupHighlighted(
                        state,
                        photoGroup.id
                      )}
                      isDisabled={!state.planning.showPhotoGroups}
                      dispatch={dispatch}
                    />
                  );
                })}
              </ul>
            </div>
          )}
        </Fragment>
      ) : null}

      {state.objectListType === 'layer' ? (
        <Fragment>
          <div className={styles.objectsPanelQuickActions}>
            <HorizontalForm size="small">
              <Tooltip
                contents={'Toggle All On'}
                placement="bottom"
                target={
                  <Button
                    size="small"
                    type="filled"
                    disabled={false}
                    onClick={() => {
                      dispatch({
                        type: 'menu.planning.toggleAllOn',
                      });
                    }}
                    data-cy="toggle-all-on"
                    trailingIcon={<Icons.CheckCircle size={20} />}
                  />
                }
              />

              <Tooltip
                contents={'Toggle All Off'}
                placement="bottom"
                target={
                  <Button
                    size="small"
                    type="filled"
                    disabled={false}
                    onClick={() => {
                      dispatch({
                        type: 'menu.planning.toggleAllOff',
                      });
                    }}
                    data-cy="toggle-all-off"
                    trailingIcon={<Icons.CloseCircle size={20} />}
                  />
                }
              />

              <Tooltip
                contents={'Show Spaces Only'}
                placement="bottom"
                target={
                  <Button
                    size="small"
                    type="filled"
                    disabled={false}
                    onClick={() => {
                      dispatch({
                        type: 'menu.planning.toggleOffAllButSpaces',
                      });
                    }}
                    data-cy="toggle-off-all-but-spaces"
                    trailingIcon={<Icons.Space size={20} />}
                  />
                }
              />

              <Tooltip
                contents={'Show OA Entities Only'}
                placement="bottom"
                target={
                  <Button
                    size="small"
                    type="filled"
                    disabled={false}
                    onClick={() => {
                      dispatch({
                        type: 'menu.planning.toggleOffAllButOpenArea',
                      });
                    }}
                    data-cy="toggle-all-but-oa"
                    trailingIcon={
                      <Icons.DeviceEntrySideIsometricAngle size={20} />
                    }
                  />
                }
              />

              <Tooltip
                contents={'Show Entry Entities Only'}
                placement="bottom"
                target={
                  <Button
                    size="small"
                    type="filled"
                    disabled={false}
                    onClick={() => {
                      dispatch({
                        type: 'menu.planning.toggleOffAllButEntry',
                      });
                    }}
                    data-cy="toggle-all-but-entry"
                    trailingIcon={<Icons.PlusOne size={20} />}
                  />
                }
              />

              <Tooltip
                contents={'Load Bookmarked Toggles'}
                placement="bottom"
                target={
                  <Button
                    size="small"
                    type="filled"
                    disabled={!localStorage.getItem('planningBookmark')}
                    onClick={() => {
                      dispatch({
                        type: 'menu.planning.loadFromLocalStorage',
                      });
                    }}
                    data-cy="toggle-load-from-localstorage"
                    trailingIcon={<Icons.Bookmark size={20} />}
                  ></Button>
                }
              />
            </HorizontalForm>
            <div className={styles.objectsPanelActionsLastRow}>
              <Tooltip
                contents={'Bookmark Current Toggles'}
                placement="bottom"
                target={
                  <button
                    className={classnames([styles.buttonSmall, styles.link])}
                    style={{ width: '100%' }}
                    disabled={false}
                    onClick={() => {
                      localStorage.setItem(
                        'planningBookmark',
                        JSON.stringify(state.planning)
                      );
                      toast.success('Layer toggles saved to local memory.');
                    }}
                  >
                    <Icons.BookmarkFill size={18} />
                    &nbsp; Bookmark Settings
                  </button>
                }
              />
            </div>
          </div>
          <div className={styles.objectsPanelMainScrollArea}>
            <ul className={styles.layerList}>
              <LayerListItem
                icon={<Icons.DeviceEntrySideIsometricAngle size={18} />}
                label="Sensors"
                isChecked={state.planning.showSensors}
                onChange={() =>
                  dispatch({ type: 'menu.planning.toggleSensors' })
                }
              />
              {state.planning.showSensors ? (
                <Fragment>
                  <LayerListItem
                    icon={<Icons.PlusOne size={18} />}
                    label="Entry"
                    isChecked={state.planning.showSensorsEntry}
                    onChange={() =>
                      dispatch({ type: 'menu.planning.toggleSensorsEntry' })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                  {state.planning.showSensorsEntry ? (
                    <Fragment>
                      <LayerListItem
                        icon={<Icons.DeviceEntrySideIsometricAngle size={18} />}
                        label="Entry LR"
                        isChecked={state.planning.showSensorsEntryLR}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleSensorTypeOpenEntry',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />

                      <LayerListItem
                        icon={<Icons.DeviceEntryTopFront size={18} />}
                        label="ToF Entry"
                        isChecked={state.planning.showSensorsEntryTOF}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleSensorTypeEntry',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                      <LayerListItem
                        icon={<Icons.DoorEntry size={18} />}
                        label="Doorways"
                        isChecked={state.planning.showThresholds}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleThresholds',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                      {state.planning.showThresholds ? (
                        <LayerListItem
                          icon={<Icons.BoxSelection size={18} />}
                          label="Trigger Regions"
                          isChecked={state.planning.showTriggerRegions}
                          onChange={() =>
                            dispatch({
                              type: 'menu.planning.toggleTriggerRegions',
                            })
                          }
                          indent={LAYER_TOGGLES_INDENT_3}
                        />
                      ) : null}
                      {state.planning.showThresholds ? (
                        <LayerListItem
                          icon={<Icons.BoxSelection size={18} />}
                          label="Ingress/Egress Regions"
                          isChecked={state.planning.showIngressEgressRegions}
                          onChange={() =>
                            dispatch({
                              type: 'menu.planning.toggleIngressEgressRegions',
                            })
                          }
                          indent={LAYER_TOGGLES_INDENT_3}
                        />
                      ) : null}

                      {state.planning.showThresholds ? (
                        <LayerListItem
                          icon={<Icons.AccuracyTarget size={18} />}
                          label="Sensor Tracks"
                          isChecked={state.planning.showSensorTracks}
                          onChange={() =>
                            dispatch({
                              type: 'menu.planning.toggleSensorTracks',
                            })
                          }
                          indent={LAYER_TOGGLES_INDENT_3}
                        />
                      ) : null}
                      {/*
                      Eh, IMHO this should be always on as default
                      <LayerListItem
                        icon={<Icons.Tag size={18} />}
                        label="Labels"
                        isChecked={state.planning.showEntryLabels}
                        onChange={() =>
                          dispatch({ type: 'menu.planning.toggleEntryLabels' })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                      */}
                      <LayerListItem
                        icon={<Icons.IntegrationsAngleInclined size={18} />}
                        label="Coverage"
                        isChecked={state.planning.showEntryCoverage}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleEntryCoverage',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                    </Fragment>
                  ) : null}
                  <LayerListItem
                    icon={<Icons.DeviceEntrySideIsometricAngle size={18} />}
                    label="Open Area"
                    isChecked={state.planning.showSensorsOpenArea}
                    onChange={() =>
                      dispatch({ type: 'menu.planning.toggleSensorsOpenArea' })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                  {state.planning.showSensorsOpenArea ? (
                    <Fragment>
                      <LayerListItem
                        icon={<Icons.Brightness7 size={18} />}
                        label="Long Range"
                        isChecked={state.planning.showSensorsOpenAreaLR}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleSensorTypeLongRange',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                      <LayerListItem
                        icon={<Icons.Brightness6 size={18} />}
                        label="Mid Range"
                        isChecked={state.planning.showSensorsOpenAreaMR}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleSensorTypeMidRange',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                      <LayerListItem
                        icon={<Icons.Brightness4 size={18} />}
                        label="Short Range"
                        isChecked={state.planning.showSensorsOpenAreaSR}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleSensorTypeShortRange',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                      {/*
                      Eh, IMHO this should be always on as default
                      <LayerListItem
                        icon={<Icons.Tag size={18} />}
                        label="Labels"
                        isChecked={state.planning.showOpenAreaLabels}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleOpenAreaLabels',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                      */}
                      <LayerListItem
                        icon={<Icons.IntegrationsAngleInclined size={18} />}
                        label="Coverage"
                        isChecked={state.planning.showOpenAreaCoverage}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleOpenAreaCoverage',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                      <LayerListItem
                        icon={<Icons.ScissorsCut size={18} />}
                        label="Doorway FOV"
                        isChecked={state.planning.showOpenAreaDoorFOV}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleOpenAreaDoorFOV',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                      <LayerListItem
                        icon={<Icons.Router size={18} />}
                        label="Extents"
                        isChecked={state.planning.showOpenAreaCoverageExtents}
                        isDisabled={
                          !state.heightMap.enabled &&
                          state.walls.items.size === 0
                        }
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleOpenAreaCoverageExtents',
                          })
                        }
                        indent={LAYER_TOGGLES_INDENT_2}
                      />
                    </Fragment>
                  ) : null}
                  <LayerListItem
                    icon={<Icons.RadioInactiveCircle size={18} />}
                    label="Waffle"
                    isChecked={state.planning.showSensorsWaffle}
                    onChange={() =>
                      dispatch({ type: 'menu.planning.toggleSensorsWaffle' })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                  <LayerListItem
                    icon={<Icons.ZoomToFit size={18} />}
                    label="Noise Polygons"
                    isChecked={state.planning.showSensorNoisePolygons}
                    onChange={() =>
                      dispatch({
                        type: 'menu.planning.toggleSensorNoisePolygons',
                      })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                </Fragment>
              ) : null}
              <LayerListItem
                icon={<Icons.Control4 size={18} />}
                label="Areas of Coverage"
                isChecked={state.planning.showAreasOfConcern}
                onChange={() =>
                  dispatch({ type: 'menu.planning.toggleAreasOfConcern' })
                }
              />
              <LayerListItem
                icon={<Icons.SpaceTypeSpace size={18} />}
                label="Spaces"
                isChecked={state.planning.showSpaces}
                onChange={() =>
                  dispatch({ type: 'menu.planning.toggleSpaces' })
                }
              />
              {state.planning.showSpaces ? (
                <Fragment>
                  <LayerListItem
                    icon={<Icons.PlusOne size={18} />}
                    label="Entry Spaces"
                    isChecked={state.planning.showSpacesEntry}
                    onChange={() =>
                      dispatch({ type: 'menu.planning.toggleSpacesEntry' })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                  <LayerListItem
                    icon={<Icons.DeviceEntrySideIsometricAngle size={18} />}
                    label="Open Area Spaces"
                    isChecked={state.planning.showSpacesOpenArea}
                    onChange={() =>
                      dispatch({ type: 'menu.planning.toggleSpacesOpenArea' })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                  <LayerListItem
                    icon={<Icons.RadioInactiveCircle size={18} />}
                    label="Waffle Spaces"
                    isChecked={state.planning.showSpacesWaffle}
                    onChange={() =>
                      dispatch({ type: 'menu.planning.toggleSpacesWaffle' })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                  <LayerListItem
                    icon={<Icons.Tag size={18} />}
                    label="Space Names"
                    isChecked={state.planning.showSpaceNames}
                    onChange={() =>
                      dispatch({ type: 'menu.planning.toggleSpaceNames' })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                </Fragment>
              ) : null}
              <LayerListItem
                icon={<Icons.Ruler size={18} />}
                label="Reference Rulers"
                isChecked={state.planning.showRulers}
                onChange={() =>
                  dispatch({ type: 'menu.planning.toggleRulers' })
                }
              />
              <LayerListItem
                icon={<Icons.RulerVertical size={18} />}
                label="Reference Heights"
                isChecked={state.planning.showHeights}
                onChange={() =>
                  dispatch({ type: 'menu.planning.toggleHeights' })
                }
              />
              <LayerListItem
                icon={<Icons.Speed05x size={18} />}
                label="Floorplan Scale"
                isChecked={state.planning.showScale}
                onChange={() => dispatch({ type: 'menu.planning.toggleScale' })}
              />
              <LayerListItem
                icon={<Icons.FolderImage size={18} />}
                label="Photo Groups"
                isChecked={state.planning.showPhotoGroups}
                onChange={() => {
                  Analytics.track('Select Photo Groups Tab', {
                    spaceId: plan.floor.id,
                  });
                  dispatch({ type: 'menu.planning.togglePhotoGroups' });
                }}
              />

              <LayerListItemHeader>Live Data Streams</LayerListItemHeader>
              <LayerListItem
                icon={<Icons.PlusOne size={18} />}
                label="Entry"
                isChecked={state.planning.showLiveStreamEntry}
                onChange={() =>
                  dispatch({
                    type: 'menu.planning.toggleEntryLiveStream',
                  })
                }
              />
              {state.planning.showLiveStreamEntry ? (
                <>
                  <LayerListItem
                    icon={<Icons.PoundSignHashtag size={18} />}
                    label="Event Counts"
                    isChecked={state.planning.showLiveStreamEntryCounts}
                    onChange={() =>
                      dispatch({
                        type: 'menu.planning.toggleEntryCounts',
                      })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                  <LayerListItem
                    icon={<Icons.Table size={18} />}
                    label="ELR Events"
                    isChecked={state.planning.showLiveStreamEntryDashboard}
                    onChange={() =>
                      dispatch({
                        type: 'menu.planning.toggleEntryDashboard',
                      })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                  <LayerListItem
                    icon={<Icons.CleanMagicSparkle size={18} />}
                    label="Points"
                    isChecked={state.planning.showLiveStreamEntryPoints}
                    onChange={() =>
                      dispatch({
                        type: 'menu.planning.toggleEntryPoints',
                      })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                  <LayerListItem
                    icon={<Icons.SnapGrid size={18} />}
                    label="Tracks"
                    isChecked={state.planning.showLiveStreamEntryTracks}
                    onChange={() =>
                      dispatch({
                        type: 'menu.planning.toggleEntryTracks',
                      })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                </>
              ) : null}
              <LayerListItem
                icon={<Icons.DeviceEntrySideIsometricAngle size={18} />}
                label="Open Area"
                isChecked={state.planning.showLiveStreamOpenArea}
                onChange={() =>
                  dispatch({
                    type: 'menu.planning.toggleOpenAreaLiveStream',
                  })
                }
              />
              {state.planning.showLiveStreamOpenArea ? (
                <>
                  <LayerListItem
                    icon={<Icons.CleanMagicSparkle size={18} />}
                    label="Points"
                    isChecked={state.planning.showLiveStreamOpenAreaPoints}
                    onChange={() =>
                      dispatch({
                        type: 'menu.planning.toggleOpenAreaPoints',
                      })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                  <LayerListItem
                    icon={<Icons.SnapGrid size={18} />}
                    label="Tracks"
                    isChecked={state.planning.showLiveStreamOpenAreaTracks}
                    onChange={() =>
                      dispatch({
                        type: 'menu.planning.toggleOpenAreaTracks',
                      })
                    }
                    indent={LAYER_TOGGLES_INDENT_1}
                  />
                </>
              ) : null}

              <LayerListItemHeader>Map Layers</LayerListItemHeader>

              {isHeightMapEnabled ? (
                <li
                  className={classnames(
                    styles.layerListItem,
                    styles.clickable,
                    {
                      [styles.focused]: State.isLayerFocused(
                        state,
                        LayerId.HEIGHTMAP
                      ),
                      [styles.highlighted]: State.isLayerHighlighted(
                        state,
                        LayerId.HEIGHTMAP
                      ),
                    }
                  )}
                  onClick={() => dispatch({ type: 'layers.heightMap.focus' })}
                  onMouseEnter={() =>
                    dispatch({ type: 'layers.heightMap.mouseenter' })
                  }
                  onMouseLeave={() =>
                    dispatch({ type: 'layers.heightMap.mouseleave' })
                  }
                  data-cy="height-map-layer-item"
                >
                  <span className={styles.layerListItemIcon}>
                    <Icons.FolderImage size={18} />
                  </span>
                  <span className={styles.layerListItemLabel}>Height Map</span>
                  <HorizontalForm size="small">
                    {/* NOTE: stop all click events from propegating upwards so that clicking on the switch doesn't trigger the onClick on the parent item div */}
                    <div onClick={(e) => e.stopPropagation()}>
                      <Switch
                        isChecked={state.planning.showCeilingHeightMap}
                        isDisabled={!state.heightMap.enabled}
                        onChange={(event) =>
                          dispatch({
                            type: 'menu.planning.toggleCeilingHeightMap',
                          })
                        }
                      />
                    </div>
                  </HorizontalForm>
                </li>
              ) : null}

              <li
                className={classnames(styles.layerListItem, styles.clickable, {
                  [styles.focused]: State.isLayerFocused(state, LayerId.WALLS),
                  [styles.highlighted]: State.isLayerHighlighted(
                    state,
                    LayerId.WALLS
                  ),
                })}
                onClick={() => dispatch({ type: 'layers.walls.focus' })}
                onMouseEnter={() =>
                  dispatch({ type: 'layers.walls.mouseenter' })
                }
                onMouseLeave={() =>
                  dispatch({ type: 'layers.walls.mouseleave' })
                }
                data-cy="walls-layer-item"
              >
                <span className={styles.layerListItemIcon}>
                  <Icons.SpacesAreasFloorplanPlanner size={18} />
                </span>
                <span className={styles.layerListItemLabel}>Walls</span>
                <HorizontalForm size="small">
                  {/* NOTE: stop all click events from propegating upwards so that clicking on the switch doesn't trigger the onClick on the parent item div */}
                  <div onClick={(e) => e.stopPropagation()}>
                    <Switch
                      isChecked={state.planning.showWalls}
                      isDisabled={FloorplanCollection.isEmpty(state.walls)}
                      onChange={(event) =>
                        dispatch({
                          type: 'menu.planning.toggleWalls',
                        })
                      }
                    />
                  </div>
                </HorizontalForm>
              </li>

              {isHeatMapEnabled ? (
                <li
                  className={classnames(
                    styles.layerListItem,
                    styles.clickable,
                    {
                      [styles.focused]: State.isLayerFocused(
                        state,
                        LayerId.HEATMAP
                      ),
                      [styles.highlighted]: State.isLayerHighlighted(
                        state,
                        LayerId.HEATMAP
                      ),
                    }
                  )}
                  onClick={() => dispatch({ type: 'layers.heatmap.focus' })}
                  onMouseEnter={() =>
                    dispatch({ type: 'layers.heatmap.mouseenter' })
                  }
                  onMouseLeave={() =>
                    dispatch({ type: 'layers.heatmap.mouseleave' })
                  }
                >
                  <span className={styles.layerListItemIcon}>
                    <Icons.Bullseye size={18} />
                  </span>
                  <span className={styles.layerListItemLabel}>Heatmap</span>
                  <HorizontalForm size="small">
                    {/* NOTE: stop all click events from propegating upwards so that clicking on the switch doesn't trigger the onClick on the parent item div */}
                    <div onClick={(e) => e.stopPropagation()}>
                      <Switch
                        isChecked={state.planning.showHeatMap}
                        onChange={(event) =>
                          dispatch({
                            type: 'menu.planning.toggleHeatMap',
                          })
                        }
                      />
                    </div>
                  </HorizontalForm>
                </li>
              ) : null}

              <LayerListItem
                icon={<Icons.ImageAlt size={18} />}
                label="Floorplan"
                onChange={(event) => {}}
                actions={
                  <div onClick={(e) => e.stopPropagation()}>
                    <HorizontalForm size="small">
                      <Tooltip
                        target={
                          <Button
                            onClick={() => {
                              Analytics.track('Space Click Change Scale', {
                                spaceId: plan.floor.id,
                              });
                              dispatch({
                                type: 'scaleEdit.edit',
                                openIntoMeasureMode: true,
                              });
                            }}
                            height={24}
                            width={24}
                            trailingIcon={<Icons.RulerVertical size={16} />}
                            size="small"
                            type="cleared"
                            disabled={state.locked}
                            data-cy="edit-scale-button"
                          />
                        }
                        contents="Edit Scale"
                      />
                      <Tooltip
                        target={
                          <CADFileUploader
                            onUploadImage={onUploadImage}
                            onUploadCADFile={onUploadCADFile}
                          >
                            {(trigger) => (
                              <Button
                                onClick={trigger}
                                height={24}
                                width={24}
                                trailingIcon={
                                  <Icons.SwapHorizontalArrow size={16} />
                                }
                                size="small"
                                type="outlined"
                                data-cy="swap-button"
                                disabled={state.locked}
                              />
                            )}
                          </CADFileUploader>
                        }
                        contents="Swap Floorplan"
                      />
                    </HorizontalForm>
                  </div>
                }
                isCheckable={false}
              />
              <LayerListItem
                icon={<Icons.VisibilityShowEye size={18} />}
                label="Floorplan Image"
                isChecked={state.planning.showFloorplanImage}
                onChange={() =>
                  dispatch({ type: 'menu.planning.toggleFloorplan' })
                }
              />

              <LatestDXFStatus
                state={state}
                client={client}
                plan={plan}
                dispatch={dispatch}
              />
              {state.scaleEdit.status === 'fetching_image_upload_url' ||
              state.scaleEdit.status === 'uploading' ? (
                <div className={styles.latestDXFStatus}>
                  <div className={styles.objectsPanelListItemIconWrapper}>
                    <Icons.ImageUpload size={18} />
                  </div>

                  {state.scaleEdit.status === 'fetching_image_upload_url' ? (
                    <div className={styles.middle}>
                      <span className={styles.title}>Uploading Image:</span>
                      Preparing to upload...
                    </div>
                  ) : null}
                  {state.scaleEdit.status === 'uploading' ? (
                    <div className={styles.middle}>
                      <span className={styles.title}>Uploading Image:</span>
                      <Tooltip
                        target={
                          <div className={styles.latestDXFPercentWrapper}>
                            <div
                              className={styles.latestDXFPercent}
                              style={{
                                width: `${
                                  state.scaleEdit.fileUploadPercent || 0
                                }%`,
                              }}
                            />
                          </div>
                        }
                        contents={`${Math.round(
                          state.scaleEdit.fileUploadPercent || 0
                        )}% uploaded`}
                        placement="bottom"
                        width="100%"
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </ul>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

export default FloorplanObjectsList;
